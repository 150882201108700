import {Component, Input, OnInit} from '@angular/core';

export interface FounderCardInput {
	description: string;
	imageSrc: string;
	quotation: string;
	about: string;
	photoPosition: 'left' | 'right';
	name: string;
	socialMedia?: {
		linkedin?: string;
		instagram?: string;
		youtube?: string;
	}
}

@Component({
  selector: 'edu-founder-card',
  templateUrl: './founder-card.component.html',
  styleUrls: ['./founder-card.component.scss']
})
export class FounderCardComponent implements OnInit {
	@Input() founder: FounderCardInput;

  constructor() { }

  ngOnInit(): void {
  }

}
