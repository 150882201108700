import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-educa-trail',
  templateUrl: './educa-trail.component.html',
  styleUrls: ['./educa-trail.component.scss']
})
export class EducaTrailComponent implements OnInit {

	public items = [
		{ image: "../../../assets/icons/new_icons/book-cover-green.svg", title: "Programa Socioemocional Educa por Rossandro Klinjey", subtitle: "Para Alunos", marker: "green-light-25", description: " Orientado para o desenvolvimento das competências humanas em um mundo conectado. Uma combinação de estudos e práticas de aprendizagem socioemocional, como CASEL, BNCC, Psicologia Positiva e Letramento Digital, preparando alunos para viver melhor dentro e fora da internet.", button: "Saiba mais", link:"https://educa21.com.br/blog/12", follow: "Educação Infantil, Ensino Fundamental" },
		{ image: "../../../assets/icons/new_icons/brain-green.svg", title: "Inteligência para a Vida por Rossandro Klinjey", subtitle: "Para Alunos", marker: "green-light-25", description: "O panorama atual nos mostra uma juventude múltipla e situada num mundo que se transforma a todo tempo. Nesse mosaico imenso de desafios, construir um projeto de vida, é desenhar um norte e elaborar caminhos rumo aos sonhos. Nosso Programa é desenvolvido para o jovem desenvolver todo seu potencial. ", button: "Saiba mais", link:"https://educa21.com.br/blog/13", follow: "Ensino Médio" },
		{ image: "../../../assets/icons/new_icons/notebook-green.svg", title: "Letramento Digital", subtitle: "Para Alunos", marker: "yellow-light-25", description: "O aluno aprenderá a usar a internet de uma forma mais segura e consciente. Jogos, sites e o contato com  pessoas no ambiente digital podem ser divertidos, em especial se usado com sabedoria. Com as aulas do Letramento Digital o aluno aproveitará o ambiente digital de forma crítica e positiva.", link:"https://educa21.com.br/blog/14", button: "Saiba mais" },
		{ image: "../../../assets/icons/new_icons/graduation_hat-green.svg", title: "Consultoria Pedagógica", subtitle: "Para Educadores", marker: "purple-25", description: "Consultores especializados disponíveis para encontros presenciais e digitais, apoiando o impulsionamento e desenvolvimento da equipe pedagógica da escola de forma eficaz e ágil.", button: "Saiba mais", link:"https://educa21.com.br/blog/15" },
		{ image: "../../../assets/icons/new_icons/smartphone-green.svg", title: "Comunidade Educa no WhatsApp", subtitle: "Para Famílias e Educadores do século 21", marker: "blue-25", description: "Faça parte da nossa comunidade e receba no seu WhatsApp as principais notícias sobre educação socioemocional e parentalidade, além de conteúdos práticos para a criação dos filhos, competências do século 21, educação midiática, letramento digital e muito mais.", button: "Saiba mais", link:"https://educa21.com.br/blog/16" },
		{ image: "../../../assets/icons/new_icons/family-green.svg", title: "Escola de Educação Parental e Educadores", subtitle: "Para Famílias", marker: "red-25", description: "O exercício da educação passou a ser considerado como o maior empreendimento da vida. Para ajudar a lidar com isso, a Educa apoiará as famílias e educadores, fornecendo cursos e palestras para promover o encorajamento infantojuvenil, usando gentileza e firmeza no processo educacional familiar.", button: "Saiba mais", link:"https://educa21.com.br/blog/17" }
	]

  constructor() { }

  ngOnInit(): void {
  }

}
