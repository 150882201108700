import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export type SocialLoginButtonProvider = 'google' | 'facebook' | 'apple'

@Component({
  selector: 'edu-social-login-button',
  templateUrl: './social-login-button.component.html',
  styleUrls: ['./social-login-button.component.scss']
})
export class SocialLoginButtonComponent implements OnInit {
	@Input() provider: SocialLoginButtonProvider;

	@Output() googleClicked: EventEmitter<any>;
	@Output() facebookClicked: EventEmitter<any>;
	@Output() appleClicked: EventEmitter<any>;

  constructor() {
		this.googleClicked = new EventEmitter<any>();
		this.facebookClicked = new EventEmitter<any>();
		this.appleClicked = new EventEmitter<any>();
	}

  ngOnInit(): void {
  	if (!this.provider) {
  		console.error('SOCIAL_LOGIN_BTN_IMPLEMENTATION_ERROR', 'provider prop is missing')
		}
  }

}
