import { AfterContentInit, AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
	@ViewChild("content", { static: false }) modalContentReference: any;

	isNewsLetterPopUpVisible: boolean;
	newsletterEmail: string;
	contactModalShowed: boolean;

	constructor(private modalService: NgbModal) {
		this.newsletterEmail = "";
		this.contactModalShowed = false;
		this.handleEmailSubmitted = this.handleEmailSubmitted.bind(this);
		if (!document?.location?.href.includes("#newsletter")) {
			this.isNewsLetterPopUpVisible = true;
		}
	}

	handleEmailSubmitted() {
		this.modalService.dismissAll();
	}

	handleNavigateToNewsLetter() {
		if (!document?.location?.href.includes("#newsletter")) {
			document.location.href = `${document.location.href}#newsletter`;
		}
		this.isNewsLetterPopUpVisible = false;
	}

	handleGoToNewsletterFormClick() {
		this.isNewsLetterPopUpVisible = false;
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		document.removeEventListener("click", this.handleGoToNewsletterFormClick);
	}

	openModal() {
		if (!this.contactModalShowed) {
			this.modalService.open(this.modalContentReference || "", { centered: true, size: "xl" }).shown.subscribe(() => (this.contactModalShowed = true));
		}
	}

	subscribeToNewsletter() {
		if (!this.newsletterEmail) {
			console.error("Missing email for subscription");
		}
	}
}
