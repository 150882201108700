<div class="w-full max-w-13xl mb-4 px-7 2xl:px-0  gray-waves" *ngIf="otherPosts?.length || showEmptyList">
    <h2 class="section-title text-edu-black flex items-center mb-8">
        {{title}}
        <div class="ml-0 md:ml-14 flex items-center">
            <button (click)="handleScroll('left')" class="focus:outline-none"><img src="./assets/icons/chevron-left-green.svg" class="w-6 h-10" /></button>
            <button (click)="handleScroll('right')" class="focus:outline-none"><img src="./assets/icons/chevron-left-green.svg" class="w-6 h-10 transform rotate-180 ml-16" /></button>
        </div>

    </h2>
    <p *ngIf="description" class="body w-full max-w-lg text-left lg:w-120 mt-2 mb-7">{{description}}</p>

    <div class="flex items-stretch overflow-x-auto px-5 py-7 blog-posts" *ngIf="otherPosts?.length">
        <edu-post-card class="mr-10" *ngFor="let otherPost of otherPosts" [fixedWidth]="true" [slidePostList]="true" [post]="otherPost"></edu-post-card>
    </div>
    <p class="body" *ngIf="!otherPosts?.length">Nenhuma publicação para exibir.</p>
</div>
