import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Location } from "@angular/common";
import {Subscription} from "rxjs";
import {RepositoryService} from "../../../services/repository.service";
import {Option} from "../../dropdown/dropdown.component";
import {Router} from "@angular/router";
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'edu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  mobileMenuOpen = false;

  authedSubscription: Subscription;
  authenticated: boolean = false;

	dropdownMenuOptions: Option[];

  constructor(public location: Location, public repository: RepositoryService, public authService: AuthService, public router: Router) {
  	this.authedSubscription = new Subscription();
  	this.dropdownMenuOptions = [
			{ label: 'Recursos', value: 'resources' },
			// { label: 'Minha conta', value: 'account' },
			{ label: 'Sair', value: 'logout', labelColor: 'red' },
		]
	}

  ngOnInit(): void {}

  ngOnDestroy() {
  	this.authedSubscription?.unsubscribe();
	}

	goToRoute(route) {
		this.router.navigate([route]);

		if (this.mobileMenuOpen) {
			this.toggleMobileMenu();
		}
	}

	getLoggedUser() {
		const isAuthenticated = this.authService.isUserLoggedIn();

		let user = null;
		let name = null;

		if (isAuthenticated) {
			user = JSON.parse(this.authService.getUserInfo());
			const profile = user.profilePictureUrl? user.profilePictureUrl : '../../../assets/images/profile.png';
			user.profilePictureUrl = profile;
			name = user.name.split(' ')[0];
		}

		return { user, isAuthenticated, name };
	}

	toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

	handleOptionSelected(selectedOption: Option) {
		console.log(selectedOption);

  	if (selectedOption?.value === 'logout') {
			this.goToRoute("home");
  		this.handleLogout();
		} else if (selectedOption?.value === 'resources') {
			this.goToRoute("produtos");
		}
	}

	// todo implementar
	async handleLogout() {
		const loggedUser = this.getLoggedUser().user;
		return this.authService.logout(loggedUser);
	}
}
