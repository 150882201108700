import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { EducaTwentyOneComponent } from "./pages/educa-twenty-one/educa-twenty-one.component";
import { SocioEmocionalComponent } from "./pages/socio-emocional/socio-emocional.component";
import { FaleConoscoComponent } from "./pages/fale-conosco/fale-conosco.component";
import { PressComponent } from "./pages/press/press.component";
import { ResourcesComponent } from "./pages/recursos-gratuitos/recursos-gratuitos.component";
import { BlogIndexComponent } from "./pages/blog-index/blog-index.component";
import { BlogPostComponent } from "./pages/blog-post/blog-post.component";
import { LoginComponent } from "./pages/login/login.component";
import { SignUpComponent } from "./pages/sign-up/sign-up.component";
import { EducaProductsComponent } from "./pages/educa-products/educa-products.component";
import { ElementaryEarlyComponent } from "./pages/educa-products/teachers-notebook/elementary-early/elementary-early.component";
import { TeachersNotebookComponent } from "./pages/educa-products/teachers-notebook/teachers-notebook.component";
import { AuthGuard } from "./services/auth/auth.guard";
import { ElementaryEndComponent } from "./pages/educa-products/teachers-notebook/elementary-end/elementary-end.component";
import { ComingSoonComponent } from "./pages/coming-soon/coming-soon.component";
import { EducatorsAcademyComponent } from "./pages/educa-products/educators-academy/educators-academy.component";
import { WatchClassComponent } from "./pages/educa-products/watch-class/watch-class.component";
import { EducaProductsHomeComponent } from "./pages/educa-products/educa-products-home/educa-products-home.component";
import { TeachersPanelComponent } from "./pages/educa-products/teachers-panel/teachers-panel.component";
import { ClassTrailComponent } from "./pages/educa-products/class-trail/class-trail.component";
import { ParentalEducatorsComponent } from "./pages/educa-products/parental-educators/parental-educators.component";
import { ElementaryUnitComponent } from "./pages/educa-products/teachers-notebook/elementary-unit/elementary-unit.component";
import { featurePermission } from "./services/permission/permission.service";
import { EducaTrailComponent } from "./pages/educa-products/educa-trail/educa-trail.component";
import { PrivacyCenterComponent } from "./pages/privacy-center/privacy-center.component";
import { DigitalLiteracyComponent } from "./pages/educa-products/digital-literacy/digital-literacy.component";
import { PedagogicalFoundationComponent } from "./pages/educa-products/pedagogical-foundation/pedagogical-foundation.component";
import { EarlyElementaryPlanComponent } from "./pages/educa-products/weekly-planning/early-elementary-plan/early-elementary-plan.component";
import { EndElementaryPlanComponent } from "./pages/educa-products/weekly-planning/end-elementary-plan/end-elementary-plan.component";
import { WeeklyPlanningComponent } from "./pages/educa-products/weekly-planning/weekly-planning.component";
import { HighSchoolComponent } from "./pages/educa-products/teachers-notebook/high-school/high-school.component";
import { HighSchoolUnitComponent } from "./pages/educa-products/teachers-notebook/high-school-unit/high-school-unit.component";
import { PreschoolUnitComponent } from "./pages/educa-products/teachers-notebook/preschool-unit/preschool-unit.component";
import { PreschoolPlanComponent } from "./pages/educa-products/weekly-planning/preschool-plan/preschool-plan.component";
import { HighSchoolPlanComplement } from "./pages/educa-products/weekly-planning/highschool-plan/highschool-plan.component";

const routes: Routes = [
	{ path: "home", component: HomeComponent, canActivate: ["analyticsTracker"] },
	{ path: "", redirectTo: "/home", pathMatch: "full" },
	{ path: "programa-socioemocional", component: SocioEmocionalComponent, canActivate: ["analyticsTracker"] },
	{ path: "educa", component: EducaTwentyOneComponent, canActivate: ["analyticsTracker"] },
	{ path: "fale-conosco", component: FaleConoscoComponent, canActivate: ["analyticsTracker"] },
	{ path: "imprensa", component: PressComponent, canActivate: ["analyticsTracker"] },
	{ path: "recursos-gratuitos", component: ResourcesComponent, canActivate: ["analyticsTracker"] },
	{ path: "login", component: LoginComponent, canActivate: ["analyticsTracker"] },
	{ path: "criar-conta", component: SignUpComponent, canActivate: ["analyticsTracker"] },
	{ path: "blog", component: BlogIndexComponent, canActivate: ["analyticsTracker"] },
	{ path: "blog/:id", component: BlogPostComponent, canActivate: ["analyticsTracker"] },
	{ path: "em-breve", component: ComingSoonComponent, canActivate: ["analyticsTracker"] },
	{ path: "central-de-privacidade", component: PrivacyCenterComponent, canActivate: ["analyticsTracker"] },

	// Área logada

	{ path: "produtos", component: EducaProductsComponent, canActivate: ["analyticsTracker", AuthGuard] },
	{ path: "produtos/home", component: EducaProductsHomeComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.home } },

	{ path: "painel-professor", component: TeachersPanelComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "academia-de-educadores", component: EducatorsAcademyComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.academy } },

	{ path: "jornada/:kind/:year", component: ClassTrailComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.trail } },
	{ path: "jornada/assistir-aula/:year/:id", component: WatchClassComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.trail } },

	{ path: "educacao-parental", component: ParentalEducatorsComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.parental } },

	{ path: "caderno-professor", component: TeachersNotebookComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "anos-iniciais", component: ElementaryEarlyComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "anos-finais", component: ElementaryEndComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "ensino-medio", component: HighSchoolComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },

	{ path: "infantil/unidades/:id", component: PreschoolUnitComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "fundamental/unidades/:id", component: ElementaryUnitComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "medio/unidades/:id", component: HighSchoolUnitComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },

	{ path: "letramento", component: DigitalLiteracyComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.digitalLiteracy } },

	{ path: "fundamentacao", component: PedagogicalFoundationComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },

	{ path: "planejamento", component: WeeklyPlanningComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "planejamento/pipas", component: PreschoolPlanComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "planejamento/anos-iniciais", component: EarlyElementaryPlanComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "planejamento/anos-finais", component: EndElementaryPlanComponent, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },
	{ path: "planejamento/ensino-medio", component: HighSchoolPlanComplement, canActivate: ["analyticsTracker", AuthGuard], data: { feature: featurePermission.teachersPanel } },

	{ path: "trilha-educa", component: EducaTrailComponent, canActivate: ["analyticsTracker", AuthGuard] },

	{ path: "**", redirectTo: "/home" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", scrollPositionRestoration: "enabled", anchorScrolling: "enabled" })],
	exports: [RouterModule],
	providers: [
		{
			provide: "analyticsTracker",
			useValue: () => {
				// @ts-ignore
				gtag("event", "conversion", { send_to: "AW-362307885/CTJCCK3gr6MCEK3C4awB" });
				return true;
			},
		},
	],
})
export class AppRoutingModule {}
