import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { brazilStates, schoolSectors } from "../../../_common/staticData";
import { MessageDto, Occupation, SchoolSector } from "../../../entities/message";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NewsletterService } from "../../services/newsletter/newsletter.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

interface FormErrors {
	name?: string;
	surname?: string;
	email?: string;
	school?: string;
	city?: string;
	state?: string;
	message?: string;
	occupation?: string;
	lead?: string;
	cellphone?: string;
	schoolSector?: SchoolSector | string;
}

@Component({
	selector: "edu-marketing-form",
	templateUrl: "./marketing-form.component.html",
	styleUrls: ["./marketing-form.component.scss"],
})
export class MarketingFormComponent implements OnInit {
	@ViewChild("successModal", { static: false }) modalContentReference: any;
	@Input() withMessageBox: boolean;
	@Input() successMessage: string;
	@Input() isNewsletterForm: boolean = false;
	@Input() handleSubmitted: () => void;

	messageMaxLength = 400;

	name = "";
	email = "";
	cellphone = "";
	surname = "";
	school = "";
	city = "";
	schoolSector: SchoolSector | string = "";
	personCategory: Occupation;
	message = "";

	stateOptions = [...brazilStates];
	schoolSectorOptions = [...schoolSectors];

	chosenState = "";
	chosenSchoolSector = "";

	occupationOptions = Occupation;

	isSubmitting: boolean;
	submitted: boolean;
	submissionError: boolean;
	submissionErrorMessage: string;
	errors: FormErrors;

	constructor(private http: HttpClient, private newsletterService: NewsletterService, private modalService: NgbModal) {
		this.withMessageBox = true;
		this.isSubmitting = false;
		this.successMessage = "Enviado!";
		this.submitted = false;

		this.handleSubmitted = () => null;

		this.errors = {
			email: "",
			occupation: "",
		};
	}

	ngOnInit(): void {}

	handleStateChosen(chosenOption: { label: string; value: string }) {
		this.chosenState = chosenOption?.value;
	}


	handleSchoolSectorChosen(chosenOption: { label: string; value: string }) {
		this.chosenSchoolSector = chosenOption?.value;
	}

	isFormValid(): boolean {
		return !!this.personCategory && !!this.city && !!this.email && !!this.chosenState && !!this.surname;
	}

	async handleSubmit(event?: Event) {
		event?.preventDefault();

		// garante que formulário não será submetido se não permitido
		if (this.isSubmitting) {
			return;
		}

		this.resetErrors();

		const message: MessageDto = {
			occupation: this.personCategory,
			name: this.name,
			email: this.email,
			schoolName: this.school || "",
			city: this.city,
			state: this.chosenState,
			content: this.message || "",
			cellphone: this.cellphone || undefined,
			schoolSector: this.chosenSchoolSector,
		};

		this.isSubmitting = true;
		try {
			await this.newsletterService.subscribeToNewsletter(message);
			this.submitted = true;
			this.isSubmitting = false;
			this.resetSubmittedState();
			this.handleSubmitted();
			this.submissionError = false;
			this.handleAnalyticsTrigger();
			this.openSucessModal();
		} catch (error) {
			this.submitted = false;
			this.isSubmitting = false;

			this.handleRequestErrors(error);

			setTimeout(() => {
				this.submissionErrorMessage = undefined;
			}, 6000);
		}
	}
	openSucessModal() {
		this.modalService.open(this.modalContentReference || "", { centered: true, size: "xl" });
	}
	closeSucessModal() {
		this.modalService.dismissAll();
	}

	handleAnalyticsTrigger() {
		// @ts-ignore
		gtag("event", "conversion", { send_to: "AW-362307885/NHMQCNmJ0qMCEK3C4awB" });
	}

	resetSubmittedState() {
		this.name = "";
		this.surname = "";
		this.email = "";
		this.school = "";
		this.city = "";
		this.chosenState = "";
		this.personCategory = undefined;
		this.message = "";
		this.cellphone = "";
		this.chosenSchoolSector = "";

		setTimeout(() => {
			this.submitted = false;
		}, 6000);
	}

	resetErrors() {
		this.errors = {
			email: "",
			occupation: "",
		};
	}

	handleRequestErrors(error: HttpErrorResponse) {
		if (!error) return;

		if (error?.status === 400) {
			return this.handleBadRequest();
		}

		return (this.submissionErrorMessage = "Um erro inesperado ocorreu. Por favor tente novamente em instantes.");
	}

	checkMissingFields() {
		this.errors = {
			email: !this.email ? "Campo obrigatório" : "",
			occupation: !this.personCategory ? "Escolha uma das opções acima" : "",
		};
	}

	handleBadRequest() {
		this.checkMissingFields();
		this.submissionErrorMessage = "Verifique os campos destacados";
	}

	getMessageFieldErrors(): string {
		if (!this.message) {
			return "Campo obrigatório";
		}

		if (this.message.length > this.messageMaxLength) {
			return `Mensagem não pode ter mais que ${this.messageMaxLength} caracteres.`;
		}

		return "";
	}
}
