import { Injectable } from "@angular/core";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { Lecture } from "src/app/entities/lecture.entity";
import { ApiService, PaginationResponse } from "../api.service";

@Injectable({
	providedIn: "root",
})
export class LectureService {
	BASE_ROUTE = "app/lecture";

	constructor(private apiService: ApiService) {}

	async getLecture(query?: string): Promise<PaginationResponse<Lecture>> {
		return this.apiService.get<PaginationResponse<Lecture>>(`app/lecture?${query || ""}`);
	}

	async getLectureById(id: number): Promise<Lecture> {
		if (!id) return;

		const qb = RequestQueryBuilder.create();
		const query = qb.setJoin({ field: "teachers" }).resetCache().query();

		return this.apiService.get<Lecture>(`app/lecture/${id}?${query}`);
	}
}
