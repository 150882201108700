<edu-helmet title="Imprensa"></edu-helmet>

<main class="w-full pt-10 px-6 md:px-20 lg:px-28">
	<div class="w-full max-w-13xl mx-auto">
		<h2 class="section-title w-full max-w-lg mb-10">FATOS RÁPIDOS DA EDUCA</h2>
	</div>

	<div class="educa-facts w-full max-w-13xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 auto-rows-max pb-36 px-6 sm:px-20">
		<div class="shadow-xl bg-edu-white flex flex-col justify-between py-14 pb-7 px-10 col-span-1">
			<p class="subtitle-sm pl-5 border-l border-edu-primary-green mb-7 font-bold">Projeto piloto testado com mais de mil alunos de todo o Brasil, com 97% de aprovação de líderes escolares e professores</p>
			<div class="flex items-center justify-center mt-auto">
				<!-- <a><edu-button>Saiba mais</edu-button></a> -->
			</div>
		</div>

		<div class="shadow-xl bg-edu-white flex flex-col py-14 pb-7 px-10 col-span-1">
			<p class="subtitle-sm pl-5 border-l border-edu-primary-green mb-7 font-bold">Colégio Ciências Aplicadas reduz em 75% o nível de indisciplina com o programa socioemocional Educa por Rossandro Klinjey</p>
			<div class="flex items-center justify-center mt-auto">
				<!-- <a><edu-button>Saiba mais</edu-button></a> -->
			</div>
		</div>

		<div class="shadow-xl bg-edu-white flex flex-col py-14 pb-7 px-10 col-span-1">
			<p class="subtitle-sm pl-5 border-l border-edu-primary-green mb-7 font-bold">Todas as escolas foram treinadas pelos autores do material didático em aulas online exclusivas</p>
			<div class="flex items-center justify-center mt-auto">
				<!-- <a><edu-button>Saiba mais</edu-button></a> -->
			</div>
		</div>

		<div class="shadow-xl bg-edu-white flex flex-col py-14 pb-7 px-10 col-span-1">
			<p class="subtitle-sm pl-5 border-l border-edu-primary-green mb-7 font-bold">Mais de 10 formações realizadas ao longo de 2021 para escolas parceiras da Educa</p>
			<div class="flex items-center justify-center mt-auto">
				<!-- <a><edu-button>Saiba mais</edu-button></a> -->
			</div>
		</div>
	</div>

	<div class="w-full max-w-13xl mx-auto grid grid-cols-2 sm:grid-cols-3 gap-x-3 gap-y-3 md:gap-x-10 auto-rows-max mb-32 px-6 sm:px-20">
		<a href="imprensa#educa-press">
			<div class="shadow-xl bg-edu-white flex flex-col items-center justify-between py-2 col-span-1">
				<img src="./assets/icons/loudspeacker-green.svg" alt="Alto falante" class="w-12" />
				<p class="body-sm text-center whitespace-nowrap px-2.5 font-semibold">Educa na mídia</p>
			</div>
		</a>

		<a href="imprensa#press-resources">
			<div class="shadow-xl bg-edu-white flex flex-col items-center py-2 col-span-1">
				<img src="./assets/icons/globe.svg" alt="Planeta terra" class="w-12" />
				<p class="body-sm text-center whitespace-nowrap px-2.5 font-semibold">Recursos da imprensa</p>
			</div>
		</a>

		<a href="imprensa#founders">
			<div class="shadow-xl bg-edu-white flex flex-col items-center py-2 col-span-1">
				<img src="./assets/icons/person.svg" alt="Pessoa" class="w-12" />
				<p class="body-sm text-center font-semibold">Fundadores</p>
			</div>
		</a>

		<!-- <a href="imprensa#staff">
			<div class="shadow-xl bg-edu-white flex flex-col items-center py-2 col-span-1">
				<img src="./assets/icons/group.svg" alt="Grupo de pessoas" class="w-12" />
				<p class="body-sm text-center font-semibold">Equipe</p>
			</div>
		</a> -->
	</div>

	<div class="flex items-center w-full max-w-19xl ml-auto">
		<h2 class="section-title w-full max-w-max" id="educa-press">EDUCA NA MÍDIA</h2>

		<div class="border-t-2 border-edu-primary-green w-full max-w-1/2 ml-10"></div>
	</div>

	<div class="press-section w-full max-w-13xl mx-auto grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5 px-2 sm:px-20 pb-32">
		<div class="shadow-xl bg-edu-white flex flex-col justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1">
			<div class="px-5 pt-5">
				<p class="subtitle-sm pl-5 border-l border-edu-primary-green mb-4 font-semibold">Educa pretende beneficiar mais de um milhão de alunos em 2023</p>
			</div>
			<p class="title-sm marker yellow pl-10 pr-5 w-full max-w-max">IG</p>
			<div class="flex items-center justify-center mt-5">
				<a><edu-button onclick="window.open('https://empreendaexito.ig.com.br/2023-01-19/educa-pretende-beneficiar-mais-de-um-milhao-de-alunos-em-2023.html')">Saiba mais</edu-button></a>
			</div>
		</div>

		<div class="shadow-xl bg-edu-white flex flex-col justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1">
			<div class="px-5 pt-5">
				<p class="subtitle-sm pl-5 border-l border-edu-primary-green mb-4 font-semibold">Escolas devem se preparar para o retorno às aulas de olho na saúde mental de estudantes e professores</p>
			</div>
			<p class="title-sm marker yellow pl-10 pr-5 w-full max-w-max">Olhar Digital</p>
			<div class="flex items-center justify-center mt-5">
				<a><edu-button onclick="window.open('https://olhardigital.com.br/2023/01/31/colunistas/escolas-devem-se-preparar-para-o-retorno-as-aulas-de-olho-na-saude-mental-de-estudantes-e-professores/')">Saiba mais</edu-button></a>
			</div>
		</div>

		<div class="shadow-xl bg-edu-white flex flex-col justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1">
			<div class="px-5 pt-5">
				<p class="subtitle-sm pl-5 border-l border-edu-primary-green mb-4 font-semibold">Educação socioemocional contribui para formação dos alunos</p>
			</div>
			<p class="title-sm marker yellow pl-10 pr-5 w-full max-w-max">R7</p>
			<div class="flex items-center justify-center mt-5">
				<a><edu-button onclick="window.open('https://noticias.r7.com/educacao/educacao-socioemocional-contribui-para-formacao-dos-alunos-30122022')">Saiba mais</edu-button></a>
			</div>
		</div>
	</div>

	<div class="flex items-center max-w-19xl ml-auto">
		<h2 class="section-title w-full max-w-max text-left" id="press-resources">RECURSOS DA IMPRENSA</h2>

		<div class="border-t-2 border-edu-primary-green w-full ml-10"></div>
	</div>

	<div class="w-full mx-auto mb-10 grid grid-cols-1 lg:grid-cols-2 py-6 sm:py-14 lg:gap-x-4 xl:gap-x-0 gap-y-4">

		<div class="flex flex-col gap-y-4 w-full">
			<div class="flex items-center justify-start">
				<span class="title-sm">Marca</span>
				<div class="ml-5">
					<edu-button variant="secondary">
						<a href="https://drive.google.com/drive/u/2/folders/15cwcBIxVgTQkEqT7IZlO8M6ZdYmnPA9P" target="_blank" rel="noreferrer noopener">
							<div class="flex flex-row"><img alt="Download" src="./assets/icons/download.svg" class="mr-2.5" /> Download</div>
						</a>
					</edu-button>
				</div>
			</div>

			<div class="flex items-center justify-start">
				<span class="title-sm">Vídeos</span>
				<a href="https://drive.google.com/drive/u/4/folders/1nVaMvv4VWkXcNFxO8tYI5N46jJZJoYsj" target="_blank" rel="noreferrer noopener">
					<div class="ml-5">
						<edu-button variant="secondary">
							<div class="flex flex-row"><img alt="Download" src="./assets/icons/download.svg" class="mr-2.5" /> Download</div>
						</edu-button>
					</div>
				</a>
			</div>

			<div class="flex items-center justify-start">
				<span class="title-sm">Guias (e-books)</span>
				<a href="https://drive.google.com/drive/u/4/folders/1mbD0zP0VFyWaEDAUci4sKHd8DQ4rRIJH" target="_blank" rel="noreferrer noopener">
					<div class="ml-5">
						<edu-button variant="secondary">
							<div class="flex flex-row"><img alt="Download" src="./assets/icons/download.svg" class="mr-2.5" /> Download</div>
						</edu-button>
					</div>
				</a>
			</div>
		</div>

		<div class="flex flex-col gap-y-4 w-full">
			<div class="flex items-center justify-start">
				<span class="title-sm">Fotos do produto</span>
				<a href="https://drive.google.com/drive/folders/1vITXDNDVRscdshRgvFNOBUQgZsx2DQCH?usp=share_link" target="_blank" rel="noreferrer noopener">
					<div class="ml-5">
						<edu-button variant="secondary">
							<div class="flex flex-row justify-center"><img alt="Download" src="./assets/icons/download.svg" class="mr-2.5" /> Download</div>
						</edu-button>
					</div>
				</a>
			</div>

			<div class="flex items-center justify-start">
				<span class="title-sm">Fotos dos Fundadores (Rossandro)</span>
				<a href="https://drive.google.com/drive/u/2/folders/1K2bDP5V54T57_GAF8RHHr9Vv9zUI6bJx" target="_blank" rel="noreferrer noopener">
					<div class="ml-5">
						<edu-button variant="secondary">
							<div class="flex flex-row "><img alt="Download" src="./assets/icons/download.svg" class="mr-2.5" />Download</div>
						</edu-button>
					</div>
				</a>
			</div>
			<div class="flex items-center justify-start">
				<span class="title-sm">Fotos dos Fundadores (Jaime)</span>
				<a href="https://drive.google.com/drive/u/4/folders/1-b62MtNh6QAOz80diHY_Cey4r2NPg8Kh" target="_blank" rel="noreferrer noopener">
					<div class="ml-5">
						<edu-button variant="secondary">
							<div class="flex flex-row"><img alt="Download" src="./assets/icons/download.svg" class="mr-2.5" /> Download</div>
						</edu-button>
					</div>
				</a>
			</div>
		</div>
	</div>

	<div class="founders flex flex-col w-full max-w-13xl mx-auto mb-10 lg:mb-14">
		<h2 class="section-title text-left mb-12" id="founders">FUNDADORES</h2>

		<edu-founder-card [founder]="rossandro"></edu-founder-card>
		<br /><br /><br /><br />
		<edu-founder-card [founder]="jaime"></edu-founder-card>
	</div>

	<!-- <div class="flex items-center max-w-19xl ml-auto mt-16 lg:mt-28">
		<h2 class="section-title w-full max-w-max text-left">EQUIPE</h2>

		<div class="border-t-2 border-edu-primary-green w-full max-w-1/2 ml-10"></div>
	</div> -->


	<div class="w-full max-w-13xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-10 mt-12" id="staff">
		<div class="flex flex-col items-center col-span-1" *ngFor="let person of staffPersons">
			<figure class="mb-5 flex flex-col items-center">
				<img [src]="'./assets/images/' + person?.profilePicture" [alt]="person?.alt" class="w-48 h-48" />
				<figcaption class="secondary-title-sm marker green px-2.5 whitespace-nowrap">{{ person?.name }}</figcaption>
			</figure>
			<p class="body text-center mb-5">{{ person?.position }}</p>
			<a [href]="person?.socialMedia.link" target="_blank" rel="noreferrer noopener">
				<edu-button variant="secondary">
					<div class="flex items-center">
						<img [alt]="person?.socialMedia?.alt" [src]="'./assets/icons/' + person?.socialMedia?.icon" class="mr-2.5" />
						Ver perfil
					</div>
				</edu-button>
			</a>
		</div>
	</div>




	<div class="flex items-center justify-center w-full max-w-13xl mx-auto my-24">
		<p class="title w-full max-w-2xl text-center">Quer saber mais sobre a Educa? <a class="email-link" target="_blank" href="mailto:atendimento@educa21.com.br">Clique aqui</a> e envie um e-mail para nós. </p>
	</div>
</main>
