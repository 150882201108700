import {
	AfterContentInit,
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';

export interface Option {
	label: string;
	value: string | number;
	image: string;
	labelColor?: 'dark' | 'red';
}

const QUANTITY_TO_SHOW = 4;

@Component({
  selector: 'edu-image-dropdown',
  templateUrl: './image-dropdown.component.html',
  styleUrls: ['./image-dropdown.component.scss']
})
export class ImageDropdownComponent implements OnInit {
	@Input() options: Option[];
	@Input() staticLabel: boolean;
	@Input() label: string;
	@Input() image:string;

	@Output() selected: EventEmitter<Option>;

	selectedOption: Option | undefined;
	optionsVisible: boolean;
	optionsGroups: any[];

  constructor() {
  	this.selected = new EventEmitter<any>();
  	this.options = [];
		this.optionsGroups = [];
  	this.staticLabel = false;
  	this.label = 'Selecionar';
  	this.optionsVisible = false;
		this.image = ' ';
	}

  ngOnInit(): void {
  	console.log('DEBUG:: Options ->', this.options)

		this.handleOptions();
  }

	handleOptions() {
		let totalOptions = 0;

		do {
			const array = this.options.slice(totalOptions, totalOptions + QUANTITY_TO_SHOW);
			this.optionsGroups.push(array);
		} while (totalOptions += QUANTITY_TO_SHOW, totalOptions < this.options.length);
	}

	handleDropdownToggle() {
  	this.optionsVisible = !this.optionsVisible;
	}

  handleOptionSelected(selectedOption) {
  	if (!selectedOption) return;

  	this.selected.emit({ ...selectedOption });
  	this.optionsVisible = false;

  	if (!this.staticLabel) {
  		this.selectedOption = { ...selectedOption }
		}
	}

}
