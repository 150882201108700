import { Component, OnInit } from '@angular/core';
import {FounderCardInput} from "../../components/founder-card/founder-card.component";

@Component({
  selector: 'app-resources',
  templateUrl: './recursos-gratuitos.component.html',
  styleUrls: ['./recursos-gratuitos.component.scss']
})
export class ResourcesComponent implements OnInit {
		public contentCards:{ title: string, subtitle: string, icon?: string, alt?:string, contentLink: string }[] = [
			{ title: "Canal do Youtube", subtitle: "Assista aos vídeos da Educa com dicas, entrevistas e tutoriais.", icon: "youtube-icon.svg", alt: "Youtube", contentLink: "https://www.youtube.com/channel/UCQAW3lWEdaDHxPWemEtoiOw"},
			{ title: "Podcast da Educa", subtitle: "Ouça os episódios do podcast Educa.", icon: "podcast-icon.svg", alt: "Podcast", contentLink: "#"},
			{ title: "Guias (e-books)", subtitle: "Conteúdos de estudo e aplicação prática no formato de livros digitais.", icon: "ebook-icon.svg", alt: "E-book", contentLink: "#"},
		]

		public forTeachersCards:{ title: string, subtitle: string, contentLink: string, style?: string, btn: string }[] = [
			{ title: "Pacote de decoração", subtitle: "Artes, cartazes, posts para mídias sociais e muito mais!", contentLink: "#", btn: "Download"},
			{ title: "E-book “Aulas híbridas com criatividade e conexão”", subtitle: "Tudo o que você precisa saber sobre como aplicar aulas híbridas utilizando ideias criativas e fortalecendo a conexão com os alunos.", contentLink: "#", btn: "Download"},
			{ title: "Oficina online sobre autonomia no ambiente escolar", subtitle: "Como desenvolver a autonomia com os alunos e transformar a experiência escolar em uma jornada positiva e de crescimento.", contentLink: "#", btn: "Assistir", style: "sm:-mt-16 md:-mt-8"},
			{ title: "Vídeo tour", subtitle: "Assista este vídeo e veja como a Educa junto com líderes escolares, professores e famílias pode ajudar a transformar a escola e seus alunos.", contentLink: "#", btn: "Assistir", style: "lg:-mt-16"}
		]

		public schoolLeadersCards:{ title: string, subtitle: string, contentLink: string, btn: string }[] = [
			{ title: "Kit de Recursos para Líderes Escolares", subtitle: "Confira nossas apresentações, eBooks, podcasts e guias exclusivos", contentLink: "#", btn: "Download"}
		]

		public forParentsCards:{ title: string, subtitle: string, contentLink: string, btn: string }[] = [
			{ title: "Conexão família e escola", subtitle: "Saiba mais sobre a integração da escola no ambiente familiar.", contentLink: "#", btn: "Quero conhecer"},
			{ title: "Como os alunos utilizam a Educa", subtitle: "Conheça neste vídeo tudo o que os alunos podem fazer no portal Educa.", contentLink: "#", btn: "Assistir"},
			{ title: "Educa na palma da sua mão", subtitle: "Artigos, eBooks, podcasts e materiais exclusivos para levar a Educa para sua casa.", contentLink: "#", btn: "Assistir"}
		]

  constructor() { }

  ngOnInit(): void {
  }

	}

