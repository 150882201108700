<ng-container *ngIf="provider">
	<button *ngIf="provider === 'google'" class="bg-edu-white shadow-md flex items-center justify-center px-1.5 py-2 w-full outline-none focus:outline-none" (click)="googleClicked.emit()">
		<img alt="Google" src="./assets/icons/google.svg" class="mr-5" />
		<span class="text-center text-base text-edu-gray">Entrar com Google</span>
	</button>

	<button *ngIf="provider === 'facebook'" class="bg-fb-blue flex items-center justify-center px-1.5 py-2 w-full outline-none focus:outline-none" (click)="facebookClicked.emit()">
		<img alt="Facebook" src="./assets/icons/facebook-icon.svg" class="mr-5" />
		<span class="text-center text-base text-edu-white">Entrar com Facebook</span>
	</button>

	<button *ngIf="provider === 'apple'" class="bg-edu-black flex items-center justify-center px-1.5 py-2 w-full outline-none focus:outline-none" (click)="appleClicked.emit()">
		<img alt="Apple" src="./assets/icons/apple.svg" class="mr-5" />
		<span class="text-center text-base text-edu-white">Entrar com Apple</span>
	</button>
</ng-container>
