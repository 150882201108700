import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiService, PaginationResponse } from "../api.service";
import { BlogCategory } from "../../entities/blogCategory.entity";
import { BlogPost } from "../../entities/blogPost.entity";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

@Injectable({
	providedIn: "root",
})
export class BlogService {
	constructor(private apiService: ApiService) {}

	async getCategories(): Promise<BlogCategory[]> {
		return this.apiService.get<BlogCategory[]>(`app/blogCategory`);
	}

	async getBlogPosts(query?: string): Promise<PaginationResponse<BlogPost>> {
		return this.apiService.get<PaginationResponse<BlogPost>>(`app/blogPost?${query || ""}`);
	}

	async getBlogPostById(postId: number | string): Promise<BlogPost> {
		if (!postId) return;

		const qb = RequestQueryBuilder.create();
		const query = qb.setJoin({ field: "category" }).resetCache().query();

		return this.apiService.get<BlogPost>(`app/blogPost/${postId}?${query}`);
	}
}
