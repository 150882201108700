import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BlogPost } from "../entities/blogPost.entity";
import { User } from "../entities/user.entity";

@Injectable({
	providedIn: "root",
})
export class RepositoryService {
	public authenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
	blogPosts: BehaviorSubject<BlogPost[]> = new BehaviorSubject<BlogPost[]>([]);
	public loggedUser: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

	constructor() {}

	setLoggedUser(user: User) {
    return this.loggedUser.next(user);
  }

  getLoggedUser() {
    return this.loggedUser.asObservable();
  }

	clean() {
		this.authenticated.next(false);
		this.loggedUser.next(undefined);
	}
}
