import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weekly-planning',
  templateUrl: './weekly-planning.component.html',
  styleUrls: ['./weekly-planning.component.scss']
})
export class WeeklyPlanningComponent implements OnInit {
	public stages = [
		{ name: "Educação Infantil", icon:"../../assets/icons/EI.svg", link:"planejamento/pipas" },
		{ name: "Ensino Fundamental Anos Iniciais", icon:"../../assets/icons/EFI.svg", link:"planejamento/anos-iniciais" },
		{ name: "Ensino Fundamental Anos Finais", icon:"../../assets/icons/EFII.svg", link:"planejamento/anos-finais" },
		{ name: "Ensino Médio", icon:"../../assets/icons/EM.svg", link:"planejamento/ensino-medio"},
	]

  constructor() { }

  ngOnInit(): void {
  }

}
