import { Component, OnInit } from "@angular/core";

export interface PDF {
	alt: string;
	icon: string;
	link: string;
}

@Component({
	selector: "app-digital-literacy",
	templateUrl: "./digital-literacy.component.html",
	styleUrls: ["./digital-literacy.component.scss"],
})
export class DigitalLiteracyComponent implements OnInit {
	public pdfs: PDF[] = [
		{ alt: "5º Ano", icon: "../../../assets/images/livros/5_ano/5_ano_v2.png", link: "https://drive.google.com/file/d/10A3qnBPg6KnH1be61-OpJ7eBGevQp3hH/view?usp=share_link" },
		{ alt: "6º Ano", icon: "../../../assets/images/livros/6_ano/6_ano_v2.png", link: "https://drive.google.com/file/d/1aJw9DLgorWhWIpOoGbmQQJGJq7DDNrAo/view?usp=share_link" },
		{ alt: "7º Ano", icon: "../../../assets/images/livros/7_ano/7_ano_v2.png", link: "https://drive.google.com/file/d/1skI58l5Hi5xsghKCfwsTDRLGCl5wmH0E/view?usp=share_link" },
		{ alt: "8º Ano", icon: "../../../assets/images/livros/8_ano/8_ano_v2.png", link: "https://drive.google.com/file/d/1nsAUXhk_nWvtDBAP8m2h_QnBz1eM0eKM/view?usp=share_link" },
		{ alt: "9º Ano", icon: "../../../assets/images/livros/9_ano/9_ano_v2.png", link: "https://drive.google.com/file/d/1vQxsghZzJAseqkNkWvDMBVPXNxhAvle-/view?usp=share_link" },
	];

	constructor() {}

	ngOnInit(): void {}
}
