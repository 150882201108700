import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'edu-helmet',
  templateUrl: './helmet.component.html',
  styleUrls: ['./helmet.component.scss']
})
export class HelmetComponent implements OnInit, OnDestroy {
	@Input() title: string;

  constructor() {
  	this.title = '';
	}

  ngOnInit(): void {
  	if (this.title) {
  		document.title = `Educa | ${this.title}`
		} else {
  		document.title = 'Educa'
		}
  }

  ngOnDestroy(): void {
		document.title = 'Educa'
	}

}
