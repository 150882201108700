<ng-template #passwordRecoverModal let-modal>
	<div class="flex flex-col items-center content-center p-2 h-full w-130 m-0">
		<div class="w-full flex items-center justify-end">
			<button type="button" aria-label="Close" (click)="modal.dismiss('Cross click'); resetState()">
				<span aria-hidden="true" class="text-base">&times;</span>
			</button>
		</div>

		<div class="flex flex-col" *ngIf="!recoverPasswordRequested">
			<h1 class="section-title w-full text-center">ALTERAR SENHA</h1>
			<p class="body w-full text-center mb-5">Vamos enviar um código no seu e-mail para a criação de uma nova senha.</p>

			<form (submit)="handlePasswordRecoverFormSubmit($event)">
				<edu-input placeholder="Digite o e-mail da sua conta" label="E-mail" [(value)]="recoverEmail" [error]="recoverEmailError"></edu-input>
				<edu-button type="submit" class="w-full md:w-auto flex justify-center mt-7" [disabled]="!recoverEmail">Enviar</edu-button>
			</form>

			<p class="body w-full text-center my-2.5 text-edu-red transition-opacity duration-500 ease-in-out" [class.opacity-1]="recoverPasswordSubmissionError" [class.opacity-0]="!recoverPasswordSubmissionError">{{ recoverPasswordSubmissionError }}</p>

		</div>

		<div *ngIf="recoverPasswordRequested">
			<h1 class="title w-full text-center">Código enviado!</h1>
			<p class="body w-full text-center">Verifique seu e-mail {{ recoverEmail }} para criar sua nova senha</p>
		</div>
	</div>
</ng-template>

<ng-template #updatePasswordModal let-modal>
	<div class="flex flex-col items-center p-2 h-full w-130 m-0">
		<div class="w-full flex items-center justify-end">
			<button type="button" aria-label="Close" (click)="modal.dismiss('Cross click'); resetState()">
				<span aria-hidden="true" class="text-base">&times;</span>
			</button>
		</div>

		<div class="flex flex-col" *ngIf="!recoverCodeInserted">
			<h1 class="section-title w-full text-center">CÓDIGO DE RECUPERAÇÃO</h1>
			<p class="body w-full text-center mb-5">Verifique seu e-mail {{ recoverEmail }} e insira aqui o código enviado para criar sua nova senha</p>

			<form (submit)="handleCodeFormSubmit($event)">
				<edu-input placeholder="Digite o código" label="Código" [(value)]="recoverCode" [error]="recoverCodeError"></edu-input>
				<edu-button type="submit" class="w-full md:w-auto flex justify-center mt-7" [disabled]="!recoverCode">Enviar</edu-button>
			</form>

			<p class="body w-full text-center my-2.5 text-edu-red transition-opacity duration-500 ease-in-out" [class.opacity-1]="recoverCodeSubmissionError" [class.opacity-0]="!recoverCodeSubmissionError">{{ recoverCodeSubmissionError }}</p>

		</div>

		<div class="flex flex-col" *ngIf="recoverCodeInserted && !newPasswordSent">
			<h1 class="section-title w-full text-center mb-5">ALTERAR SENHA</h1>

			<form (submit)="handleUpdatePasswordFormSubmit($event)">
				<edu-input type="password" placeholder="Digite sua nova senha" label="Nova senha" [(value)]="newPassword" [error]="newPasswordError" (change)="validateInput('password')"></edu-input>
				<br />
				<edu-input type="password" placeholder="Confirme a nova senha" label="Confirmação da nova senha" [(value)]="newPasswordConfirmation" [error]="newPasswordConfirmationError" (change)="validatePassword('passwordConfirmation')"></edu-input>
				<edu-button type="submit" class="w-full md:w-auto flex justify-center mt-7" [disabled]="!newPassword && !newPasswordConfirmation || newPassword !== newPasswordConfirmation">Salvar senha</edu-button>
			</form>

			<p class="body w-full text-center my-2.5 text-edu-red transition-opacity duration-500 ease-in-out" [class.opacity-1]="updatePasswordSubmissionError" [class.opacity-0]="!updatePasswordSubmissionError">{{ updatePasswordSubmissionError }}</p>
		</div>

		<div class="flex flex-col items-center" *ngIf="newPasswordSent">
			<img src="./assets/icons/logo-primary-green.png" alt="Carregando" class="h-23 w-40"/>
			<h1 class="section-title w-full text-center mb-5">SENHA ALTERADA!</h1>
			<p class="body w-full text-center mb-5">Sua senha foi alterada com sucesso, realize o login a seguir.</p>

			<edu-button type="button" aria-label="Close" (click)="modal.dismiss('Cross click'); resetState()" class="w-full md:w-auto flex justify-center mt-2 mb-2">Entendi!</edu-button>
		</div>
	</div>
</ng-template>

<form class="flex flex-col items-center bg-edu-white" (submit)="handleSubmit($event)">
	<div class="flex flex-col items-stretch w-full max-w-lg">
		<h2 class="section-title mb-5 w-full text-center">LOGIN</h2>

		<edu-input label="E-mail" placeholder="Digite seu e-mail" type="email" [(value)]="email"></edu-input>

		<br/>

		<edu-input label="Senha" placeholder="Digite sua senha" type="password" [(value)]="password" (change)="validateInput('password')" [error]="passwordError"></edu-input>

		<br/>

		<a routerLink="/login" class="link" (click)="handleRecoverPasswordClick($event)">Esqueci minha senha</a>

		<br/>

		<p class="body text-edu-red w-full text-center transition-all duration-500 ease-in-out mb-5" [class.opacity-1]="loginAttempted" [class.opacity-0]="!loginAttempted">{{ loginAttempted ? 'Acesso inválido. Para receber seu acesso, entre em contato com a equipe Educa21' : '' }}</p>

		<edu-button variant="secondary" type="submit" [disabled]="!isFormValid()" class="w-full md:w-auto flex justify-center">Entrar</edu-button>

		<!-- todo decomentar quando for a hora -->
<!--		<br />-->
<!--		<p class="body w-full text-center">Ou</p>-->
<!--		<br />-->

		<!-- social logins -->
<!--		<edu-social-login-button provider="google" (googleClicked)="handleLoginWithGoogleClick()"></edu-social-login-button>-->
<!--		<br />-->

<!--		<edu-social-login-button provider="facebook" (facebookClicked)="handleLoginWithFacebookClick()"></edu-social-login-button>-->
<!--		<br />-->

<!--		<edu-social-login-button provider="apple" (appleClicked)="handleLoginWithAppleClick()"></edu-social-login-button>-->
<!--		<br />-->

	</div>
</form>
