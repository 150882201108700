import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { translateOccupation, User } from "src/app/entities/user.entity";
import { Session } from "selenium-webdriver";
import { Router } from "@angular/router";
import { RepositoryService } from "../repository.service";
import { Session as UserSession } from "../../entities/session.entity";

export function validatePassword(password, passwordToCompare?) {
	if (password.length < 6) {
		return "A senha deve ter no mínimo 6 caracteres!";
	} else if (passwordToCompare && password !== passwordToCompare) {
		return "As senhas não coincidem!";
	} else {
		return null;
	}
}

@Injectable({
	providedIn: "root",
})

export class AuthService {
	constructor(private apiService: ApiService, private router: Router, private repository: RepositoryService) {}
	async login(user: User): Promise<Session[]> {
		const loginResponse = await this.apiService.post<User>(`auth/login`, user, {
			customErrorHandling: this.getCustomErrors()
		});
		const session = loginResponse.token;
		const responseUser = loginResponse.user;

		localStorage.setItem("accessToken", session);
		localStorage.setItem("userInfo", JSON.stringify(responseUser));
		this.repository.setLoggedUser(responseUser);

		return session;
	}

	async completeSignup(userDto: User) {
		const session = await this.apiService.post<User, UserSession>(`auth/completeSignup`, userDto, {
			customErrorHandling: this.getCustomErrors()
		});

		this.setLoggedUser(session);

		return session;
	}

	async logout(user: User) {
		await this.apiService.post<User>(`auth/logout`, user, { tokenRequired: true, customErrorHandling: this.getCustomErrors() });
		this.clean();
		return this.router.navigate(["home"]);
	}

	async requestPasswordReset(email) {
		return this.apiService.post<any>(`auth/requestPasswordReset`, { email }, { tokenRequired: false, customErrorHandling: this.getCustomErrors() });
	}

	async resetPassword(code, password) {
		return this.apiService.post<any>(`auth/resetPassword`, { passwordResetCode: code,  password }, { tokenRequired: false, customErrorHandling: this.getCustomErrors() });
	}

	autoLogin() {
		if (this.isUserLoggedIn()) {
			const user = JSON.parse(this.getUserInfo());
			this.repository.authenticated.next(true);
			this.repository.setLoggedUser(user);
		}
	}

	setLoggedUser({ token, user }: UserSession) {
		localStorage.setItem("accessToken", token);
		localStorage.setItem("userInfo", JSON.stringify(user));

		this.repository.setLoggedUser(user);
	}

	getAccessToken() {
		return localStorage.getItem("accessToken");
	}

	getUserInfo() {
		return localStorage.getItem("userInfo");
	}

	isUserLoggedIn() {
		return localStorage.getItem("accessToken") && !!JSON.parse(localStorage.getItem("userInfo"));
	}

	getTranslatedUserOccupation() {
		let occupation = "";
		if (this.isUserLoggedIn()) {
			occupation = JSON.parse(this.getUserInfo()).occupation || "none";
		}

		return translateOccupation(occupation);
	}

	clean() {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("userInfo");
		this.repository.clean();
	}

	getCustomErrors() {
		const errorMessage = [
			{
				code: "NOT_PRE_REGISTERED",
				alertConfig: {
					titleText: "Ops… Parece que seu e-mail não está autorizado",
					text: "O seu e-mail não foi pré-cadastrado em nosso portal. Entre em contato com a sua escola.",
				},
			},
			{
				code: "PRE_REGISTERED",
				alertConfig: {
					titleText: "Seu cadastro ainda está pendente",
					text: "Clique no botão abaixo \"Cadastre-se\" para executar essa ação.",
				},
			},
			{
				code: "WRONG_PASSWORD",
				alertConfig: {
					titleText: "Ops... Sua senha está incorreta",
					text: "Verifique se digitou corretamente e tente novamente ou clique em \"Esqueci minha senha\".",
				},
			},
			{
				code: "USER_NOT_FOUND",
				alertConfig: {
					titleText: "Ops… Parece que seu e-mail não está autorizado",
					text: "O seu e-mail não foi pré-cadastrado em nosso portal. Entre em contato com a sua escola.",
				},
			},
			{
				code: "INCORRECT_CODE",
				alertConfig: {
					titleText: "Ops... O código de recuperação não está correto",
					text: "Verifique seu e-mail e tente novamente ou clique para reenviar o código.",
				},
			},
			{
				code: "EMAIL_NOT_SENT",
				alertConfig: {
					titleText: "Poxa, não foi possível enviar o e-mail de recuperação",
					text: "Verifique se preencheu o e-mail corretamente. Caso o problema persista, entre em contato com a sua escola.",
				},
			},

		];

		return errorMessage;
	}
}
