import { Component, OnInit } from "@angular/core";

export interface PDF {
	alt: string;
	icon: string;
	link: string;
}

@Component({
	selector: "app-highschool-plan",
	templateUrl: "./highschool-plan.component.html",
	styleUrls: ["./highschool-plan.component.scss"],
})
export class HighSchoolPlanComplement implements OnInit {
	public pdfs: PDF[] = [
		{ alt: "1ª série", icon: "../../assets/images/livros/1_serie/1_serie-2.png", link: "https://drive.google.com/file/d/1_mbTNprW4jv64wOuhU4TIcY5NUKxbOYC/view" },
		{ alt: "2ª série", icon: "../../assets/images/livros/2_serie/2_serie-2.png", link: "https://drive.google.com/file/d/1T6Ypm9aBBwmdMTkvrO-Ora0WE6AwPCPM/view" },
		{ alt: "3ª série", icon: "../../assets/images/livros/3_serie/3_serie-2.png", link: "https://drive.google.com/file/d/15wQOdE5wclFl3IV8VDoKzcgtPqiRbuRc/view" },
	];

	constructor() {}

	ngOnInit(): void {}
}
