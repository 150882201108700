import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgSelectConfig} from "@ng-select/ng-select";

@Component({
  selector: 'edu-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() value: any;
  @Input() bindLabel = "label";
  @Input() bindValue = "value";
  @Input() disabled = false;
  @Input() label = "";
  @Input() id = "";
  @Input() name = "";
  @Input() clearAllText = "";
  @Input() loadingText = "";
  @Input() searchText = "";
  @Input() placeholder = "";
  @Input() notFoundText = "";
  @Input() error = "";

  @Output() change: EventEmitter<any> = new EventEmitter<any>()

  constructor(private config: NgSelectConfig) {
  }

  ngOnInit(): void {
  }

  handleChange(value) {
    this.change.emit(value)
  }

}
