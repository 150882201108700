import { Component, Input, OnInit, SecurityContext } from "@angular/core";
import { Router } from "@angular/router";
import { BlogPost } from "../../../entities/blogPost.entity";
import { DomSanitizer } from "@angular/platform-browser";

const DEFAULT_CONTENT_PREVIEW_MAX_LENGTH = 200;
const THUMBNAIL_DEFAULT_URL = "./assets/images/edu-logo.png";

@Component({
	selector: "edu-post-card",
	templateUrl: "./post-card.component.html",
	styleUrls: ["./post-card.component.scss"],
})
export class PostCardComponent implements OnInit {
	@Input() highlighted: boolean;
	@Input() fixedWidth: boolean;
	@Input() slidePostList: boolean;
	@Input() post: BlogPost;
	@Input() contentPreviewMaxLength;

	postThumbnail: string;
	usingThumbnailFallback: boolean;

	constructor(private router: Router, private domSanitizer: DomSanitizer) {
		this.highlighted = false;
		this.fixedWidth = false;
		this.contentPreviewMaxLength = DEFAULT_CONTENT_PREVIEW_MAX_LENGTH;
		this.setPostThumbnail();
	}

	get postPreview(): string {
		const sanitizedHTMLContent = this.domSanitizer.sanitize(SecurityContext.HTML, this.post?.content);

		if (!sanitizedHTMLContent) return "";

		const domParser = new DOMParser();
		const parsedContent = domParser.parseFromString(sanitizedHTMLContent, "text/html");

		if (!parsedContent) return "";

		return this.extractParagraphs(parsedContent);
	}

	ngOnInit(): void {
		this.setPostThumbnail();
	}

	extractParagraphs(htmlContent: Document): string {
		if (!htmlContent) return "";
		const paragraphs = Array.from(htmlContent?.querySelectorAll("p, body"));
		return paragraphs?.map(paragraphElement => paragraphElement?.textContent)?.join(" ");
	}

	handleThumbnailLoadingError() {
		this.setPostThumbnail();
	}

	setPostThumbnail() {
		this.postThumbnail = this.post?.thumbnailUrl || THUMBNAIL_DEFAULT_URL;
		this.usingThumbnailFallback = this.postThumbnail === THUMBNAIL_DEFAULT_URL;
	}

	handleReadMoreClick() {
		this.router.navigate(["blog", this.post?.id]);
	}
}
