<edu-helmet title="Início"></edu-helmet>

<ng-template #content let-modal class="w-full">
	<div class="flex flex-col items-center w-auto px-8">
		<div class="modal-header w-full flex items-center justify-end">
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
				<span aria-hidden="true" class="text-base">&times;</span>
			</button>
		</div>
		<div class="modal-body flex flex-col items-center">
			<p class="title marker yellow text-edu-black text-center w-full mb-2">Vamos falar sobre a transformação</p>
			<p class="title marker yellow text-edu-black text-center w-full mb-2">que a Educa pode gerar na sua</p>
			<p class="title marker yellow text-edu-black text-center w-max mb-10">escola ou família</p>

			<p class="body text-edu-black text-center w-full max-w-2xl">Com a Educa as escolas desenvolvem as competências socioemocionais para todas as pessoas envolvidas no ecossistema educacional: alunos, pais, professores e líderes escolares. Conheça mais!</p>
			<edu-marketing-form class="w-full" [handleSubmitted]="handleEmailSubmitted"></edu-marketing-form>
		</div>
	</div>
</ng-template>

<edu-pop-up-card [visible]="isNewsLetterPopUpVisible" (closed)="isNewsLetterPopUpVisible = false">
	<div class="w-full max-w-max flex flex-col items-start mt-1">
		<p class="title leading-none">Assine nossa news!</p>
		<div class="border-t-4 border-edu-primary-green my-2.5 w-1/3"></div>
		<p class="body mb-2.5 w-full max-w-xs">Tenha acesso a diversos conteúdos incríveis no seu e-mail!</p>
		<a href="home#newsletter" class="mx-auto" (click)="handleNavigateToNewsLetter()"><edu-button>Eu quero!</edu-button></a>
	</div>
</edu-pop-up-card>

<main class="w-full max-w-full flex flex-col lg:min-h-screen overflow-x-hidden newsletter">
	<div class="first-section w-full md:border-b-4 border-edu-primary-green ">
		<div class="flex flex-col items-start w-full max-w-screen-2xl mx-auto md:pl-2 lg:pl-6 md:h-3/5 2xl:mt-10 2xl:my-auto">
			<div class="w-full h-130 sm:h-160 md:h-120 flex flex-col justify-end lg:justify-between mb-2.5 sm:ml-12 -mt-48 sm:mt-40 md:mt-0">
				<div class="pl-4 ml-3 w-full lg:w-auto md:ml-5 border-l-4 border-edu-primary-green mb-2 md:mb-0 mt-12">
					<h1 class="section-title w-80 md:w-96 leading-none break-words md:break-normal">PROGRAMA SOCIOEMOCIONAL EDUCA</h1>

					<p class="marker yellow title w-max mt-2 mb-7">Por Rossandro Klinjey</p>

					<p class="body mb-5 md:mb-7 w-80">Educação socioemocional, projeto de vida, letramento digital e educação parental em uma só solução.</p>

					<div class="w-11/12 md:w-auto">
						<edu-button routerLink="/programa-socioemocional">Saiba mais</edu-button>
					</div>
				</div>

			</div>

			<div class="w-full flex items-center justify-center h-auto my-10">
				<div class="video-placeholder justify-center items-center flex flex-col">
					<img src="./assets/icons/chevron-down-outline.svg" alt="Continuar" class="h-20 w-12 bg-edu-primary-yellow pb-7 px-1" />

					<youtube-player class="hidden sm:flex" videoId="p1whLl3U9-c" suggestedQuality="highres"> </youtube-player>
					<youtube-player class="flex sm:hidden" videoId="p1whLl3U9-c" [width]="370" suggestedQuality="highres"> </youtube-player>
				</div>
			</div>

			<div class="w-full call-to-action-newsletter mt-10 mb-20 md:mb-100 flex flex-col items-center mx-auto px-8 md:px-0" id="newsletter">
				<img class="md:mb-14 h-full md:w-3/5" src="../../../assets/images/edu-gray-waves.svg" />
				<p class="title marker text-center max-w-2xl w-9/12 mx-auto yellow leading-tight">Mais conteúdos como esses direto no seu e-mail. Assine nossa news!</p>
				<edu-marketing-form [isNewsletterForm]="true" [withMessageBox]="false" class="mx-auto w-full"></edu-marketing-form>
			</div>

			<div class="w-full h-auto mt-6 md:mt-2">
				<div class="flex flex-col items-start md:items-center sm:block md:pl-4 lg:px-20 mt-8 md:mt-8 relative z-10 md:-top-80 lg:-top-96">
					<h1 class="section-title leading-none marker yellow w-max mb-3 text-left ml-4 md:ml-0">CONHEÇA A</h1>
					<h1 class="section-title marker yellow w-max leading-none text-left ml-4 md:ml-0">EDUCA</h1>
					<img src="../../../assets/images/girl-smilling@2x.png" alt="ConhecaEduca21" class="md:hidden border-b-4 border-edu-primary-green" />

					<p class="body mb-7 w-full text-center md:text-justify md:w-4/12 px-4 md:px-0 mt-5 md:mt-0 break-words">Uma solução educacional que conecta gente boa para construir um mundo mais humanizado.</p>

					<div class="w-11/12 md:w-auto mx-auto md:mx-0">
						<edu-button class="w-full flex justify-center md:justify-start" routerLink="/educa">Saiba mais</edu-button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="second-section w-full mt-20">
		<div class="flex flex-col items-start w-full max-w-screen-2xl mx-auto sm:px-20">
			<div class="w-full flex flex-col mb-10">
				<div class="flex flex-col items-center lg:flex-row px-5">
					<div class="w-full md:w-auto">
						<div class="flex flex-row md:items-center pl-2.5">
							<img alt="EduLogo" src="./assets/icons/feeling.png" class="mr-2.5 lg:mr-10 w-28 h-28" />
							<h1 class="section-title w-96 leading-none">NOSSO EMBAIXADOR</h1>
						</div>

						<p class="body w-full max-w-lg text-left px-2.5 lg:w-120 mt-2 mb-7">Rossandro Klinjey é psicólogo, especialista em educação e desenvolvimento humano, e professor visitante da Fundação Dom Cabral. Fenômeno nas redes sociais, seus vídeos já alcançaram a marca de mais de 150 milhões de visualizações. Autor de vários de livros, sendo os mais recentes, "O Tempo do Autoencontro", "As Cinco Faces do Perdão" e "Help: me eduque!". É consultor da Rede Globo em temas relacionados a comportamento, educação e família, no programa Encontro, além de colunista da Rádio CBN. Como palestrante, viaja pelo Brasil e por países como Estados Unidos, Inglaterra, Áustria, Itália, Alemanha e Portugal. Suas apresentações já foram contratadas por grandes corporações, como Bradesco, Petrobrás, Grupo Algar, UNICAMP e FAAP, assim como por instituições de Estado, como Supremo Tribunal Federal, Ministério da Justiça, Tribunal Superior Eleitoral e Tribunal Superior do Trabalho.</p>

						<div class="flex w-full justify-center mb-2 lg:justify-end ambassador-social-media">
							<a class="cursor-pointer flex justify-center" href="https://www.instagram.com/rossandroklinjey/" rel="noreferrer" target="_blank"><img src="./assets/icons/green-instagram.svg" alt="Instagram" class="md:mx-4 w-1/2 md:w-16" /></a>
							<a class="cursor-pointer flex justify-center" href="https://www.youtube.com/user/rossandroklinjey" rel="noreferrer" target="_blank"><img src="./assets/icons/green-youtube.svg" alt="Youtube" class="md:mx-4 w-1/2 md:w-16" /></a>
							<a class="cursor-pointer flex justify-center" href="https://www.linkedin.com/in/rossandro-klinjey-04b72029/" rel="noreferrer" target="_blank"><img src="./assets/icons/green-linkedin.svg" alt="LinkedIn" class="md:mx-4 w-1/2 md:w-16" /></a>
						</div>
					</div>

					<div class="flex flex-col justify-between">
						<img src="/assets/images/rossandro-waves-short.png" alt="Rossandro" class="ambassador-picture" />
					</div>

				</div>

			</div>
		</div>

		<div class="flex flex-wrap justify-center items-end w-full max-w-screen-2xl sm:px-20 mx-auto mb-8">
			<div class="md:flex-1 w-10/12 md:w-1/2">
				<!-- <img src="./assets/images/mentes-conectadas.jpg" class="h-full w-full object-cover" /> -->
				<youtube-player class="flex w-full h-full justify-center lg:justify-end" videoId="AXu7AsHXSC4" suggestedQuality="highres"></youtube-player>
			</div>

			<div class="border-t-4 md:flex-1 px-5 md:pl-10 pt-5 border-edu-primary-green w-full md:w-1/2 text-justify">
				<p class="title text-edu-black my-3 md:my-0 text-left">Inspirando mentes conectadas.</p>
				<p class="body text-edu-black">O programa Educa por Rossandro Klinjey é orientado para o desenvolvimento das habilidades humanas em um mundo conectado. Ele se pauta na combinação de estudos e práticas nacionais e internacionais sobre as competências socioemocionais (CASEL, BNCC e Psicologia Positiva) e o letramento digital, preparando crianças e jovens para viver melhor dentro e fora da internet, dimensões educacionais inseparáveis.</p>
			</div>
		</div>

		<div class="px-20 pt-4">
			<edu-post-card-slider [showEmptyList]="false" [title]="'NOSSO BLOG'" [description]="'Acompanhe as últimas publicações do nosso blog e assine nossa newslatter para saber mais!'"></edu-post-card-slider>
		</div>

		<div class="flex flex-col items-center justify-center w-full max-w-screen-2xl mx-auto md:mt-20 mb-8 py-10">
			<p class="section-title text-edu-black w-full max-w-xl text-center px-2 md:px-0 lg:whitespace-nowrap">SEJA UMA ESCOLA EDUCA</p>

			<p class="body text-edu-black w-full max-w-2xl text-center px-8 md:px-0">A Educa ajudará a sua escola a desenvolver as competências do século 21 envolvendo todo o seu ecossistema educacional: alunos, pais, professores e líderes escolares.</p>

			<div class="w-11/12 md:w-auto">
				<edu-button class="mt-10 w-full md:w-auto flex justify-center" routerLink="/fale-conosco">Quero ser um parceiro!</edu-button>
			</div>
		</div>
	</div>
</main>

<!-- Facebook Pixel Code -->
<script>
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = "2.0";
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
	fbq("init", "964055004394753");
	fbq("track", "PageView");
</script>
<noscript><img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=964055004394753&ev=PageView&noscript=1" /></noscript>
<!-- End Facebook Pixel Code -->
