import { Session } from "selenium-webdriver";
import { BaseEntity } from "./base.entity";
import { UserDevice } from "./userDevice.entity";

export enum UserRole {
	USER = "Usuário",
	ADMINISTRATOR = "Administrador",
}

export enum UserStatus {
	UNCONFIRMED_EMAIL = "E-mail não confirmado",
	APPROVAL_PENDING = "Aprovação pendente",
	ACTIVE = "Ativo",
}

export enum Gender {
	MALE = "Masculino",
	FEMALE = "Feminino",
	OTHER = "Outro",
}

export function translateOccupation(occupation) {
  switch (occupation) {
    case Occupation.PARENT:
      return 'Família';
		case Occupation.SCHOOL_LEADER:
      return 'Líder Escolar';
		case Occupation.STUDENT:
      return 'Aluno(a)';
		case Occupation.TEACHER:
      return 'Educador(a)';
    default:
      return 'Usuário';
  }
}

export enum Occupation {
	SCHOOL_LEADER = "SCHOOL_LEADER",
	TEACHER = "TEACHER",
	PARENT = "PARENT",
	STUDENT = "STUDENT",
}

export enum ProfessionalRole {
	BOARD = "BOARD",
	COORDINATION = "COORDINATION",
	SOCIO_EMOTIONAL_TEACHER = "SOCIO_EMOTIONAL_TEACHER",
	ELEMENTARY_TEACHER = "ELEMENTARY_TEACHER",
	MANAGEMENT = "MANAGEMENT",
	MAINTAINER = "MAINTAINER",
	PSYCHOLOGIST = "PSYCHOLOGIST",
	OTHER = "OTHER",
}

export enum Avatar {
	ROBERTO = "https://educa21-media.elabs.xyz/avatar/roberto.png",
	ROBERTOTEEN = "https://educa21-media.elabs.xyz/avatar/robertoteen.png",
	YUMI = "https://educa21-media.elabs.xyz/avatar/yumi.png",
	YUMITEEN = "https://educa21-media.elabs.xyz/avatar/yumiteen.png",
	CHICO = "https://educa21-media.elabs.xyz/avatar/chico.png",
	CHICHOTEEN = "https://educa21-media.elabs.xyz/avatar/chicoteen.png",
	LINA = "https://educa21-media.elabs.xyz/avatar/lina.png",
	LINATEEN = "https://educa21-media.elabs.xyz/avatar/linateen.png",
	MARIA = "https://educa21-media.elabs.xyz/avatar/maria.png",
	MARIATEEN = "https://educa21-media.elabs.xyz/avatar/mariateen.png",
	PEDRO = "https://educa21-media.elabs.xyz/avatar/pedro.png",
	PEDROTEEN = "https://educa21-media.elabs.xyz/avatar/pedroteen.png",
	REDSMILE = "https://educa21-media.elabs.xyz/avatar/redsmile.png",
	BLACKSMILE = "https://educa21-media.elabs.xyz/avatar/blacksmile.png",
	YELLOWSMILE = "https://educa21-media.elabs.xyz/avatar/yellowsmile.png",
	GREENSMILE = "https://educa21-media.elabs.xyz/avatar/greensmile.png",
	ROSSANDRO = "https://educa21-media.elabs.xyz/avatar/rossandro.png",
}

export class User extends BaseEntity {
	email?: string;
	password?: string;
	role?: UserRole;
	status?: UserStatus;
	occupation?: Occupation;
	professionalRole?: ProfessionalRole;
	currentPassword?: string;
	name?: string;
	birthDate?: Date;
	phoneNumber?: string;
	gender?: Gender;
	cpf?: string;
	zipCode?: string;
	about?: string;
	addressStreet?: string;
	addressNumber?: number;
	addressComplement?: string;
	district?: string;
	city?: string;
	state?: string;
	profilePictureUrl?: string;
	emailConfirmationCode?: string | undefined;
	passwordResetCode?: string | undefined;
	sessions?: Session[];
	devices?: UserDevice[];
	currentToken?: string;
	playerId?: string;
	termsAccepted?: boolean;
}
