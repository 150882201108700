<main class="w-full min-h-screen items-center educa-waves">
	<edu-back-button></edu-back-button>
	<div class="w-full grid grid-cols-10 md:grid-cols-7 gap-4">
		<div class="col-start-2 col-end-4 md:col-start-2 md:col-end-3 justify-center row-start-1 md:mt-8 mt-14">
			<img src="../../../assets/icons/logo-primary-green.png" alt="Logo" class="h-23 md:ml-0 md:w-40 md:h-24 mb-4"/>
			<p class="title text-edu-primary-green text-center text-4xl md:text-7xl xl:text-8xl w-1/5">EM <br> BREVE</p>
		</div>
		<div class="col-start-5 col-end-9 md:col-start-4 md:col-end-7">
			<p class="section-title">QUERIDO(A) {{ getUserOccupation() }},</p>
			<p class="title-sm">Um passarinho me disse que tem novidades chegando por aí, aguarde que em breve a notícia chegará para você.</p>
			<p class="body">Estamos em constante inovação.</p>
		</div>
	</div>
</main>

