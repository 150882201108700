import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedagogical-foundation',
  templateUrl: './pedagogical-foundation.component.html',
  styleUrls: ['./pedagogical-foundation.component.scss']
})
export class PedagogicalFoundationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
