<main class="w-full flex flex-col min-h-screen">
	<edu-back-button></edu-back-button>
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex items-center w-full max-w-19xl ml-20">
			<h2 class="section-title w-full max-w-max" id="educa-press">TERMOS DE USO</h2>
			<div class="border-t-2 border-edu-primary-green w-full max-w-1/2 ml-10"></div>
		</div>
	</div>

	<div class="w-full max-w-13xl mx-auto grid grid-cols-1 gap-x-10 gap-y-10 mt-12 ml-20" id="staff">
		<div class="flex flex-col pl-5 w-10/12 justify-start md:pl-10 mt-5 md:mt-0 mb-10">
			<p>
				Este Termos de Uso contém termos gerais que se aplicam a você como um usuário do Portal Educa ("Usuário"), junto com termos adicionais que podem se aplicar a você como um Usuário registrado como aluno, professor, líder de escola, colaborador escolar, ou um pai/responsável.
			</p>
			<p>
				Ao usar o Serviço, você também estará sujeito à Política de Privacidade da Educa e a quaisquer diretrizes, políticas ou regras publicadas aplicáveis a recursos específicos do Serviço, que podem ser publicadas de tempos em tempos (coletivamente, as "Diretrizes"). Estes Termos de Uso, incluindo quaisquer outras Diretrizes e modificações futuras, é um contrato legal entre você, Usuário, e a Educa. Ao registrar-se em uma conta, ou de outra forma acessar o Portal ou usar o Serviço Educa, você reconhece que leu e concorda em ficar vinculado por este Acordo.
			</p>
			<p>
				Se for menor de 18 anos, você declara que seu responsável legal revisou e concordou com o Contrato. Caso contrário, você não tem permissão para usar o Serviço.
			</p>
			<p>
				Se você usará este Acordo em nome de uma empresa, escola ou outra entidade legal, você declara que tem autoridade para vincular tal entidade a estes termos e condições, nos quais caso os termos "você", "seu" ou "Usuário" se refiram a tal entidade. Se você não tiver tal autoridade, ou se não concordar com estes termos e condições, você não deve aceitar este Acordo e não pode usar o Portal Educa.
			</p>
			<p>
				O acesso ao Portal Educa está sujeito ao Termo de Uso que o vincula legalmente. Ao acessar, navegar, compartilhar, contribuir ou usar o Site, você reconhece que leu, entendeu e concordou com as disposições a seguir estabelecidas. </p>
			<p>
				Resumo: Ao usar ou inscrever-se no Portal Educa, você concorda com estes termos. Se você for menor de 18 anos, seu responsável legal ou pai precisará revisar e concordar com o Contrato em seu nome. Seja bem-vindo(a) a comunidade Educa.
			</p>

			<div>
				<h5>Sobre Nós</h5>
				<p>
					EDUCA LTDA ("Educa"), sociedade empresária limitada, inscrita no CNPJ/MF sob o n° 40.651.433/0001-21, com sede na Cidade de São Paulo, Estado de São Paulo, prestadora de serviços de ensino voltados ao contratante ("Escolas" e a comunidade escolar relacionada a ela, "Usuários", como Alunos, Família e Educadores).
				</p>
				<p>
					Por intermédio destes Termos de Uso e Condições Gerais de Contratação ("Termos"), a Educa apresenta as Escolas as condições essenciais para o uso da Plataforma e para a Prestação dos Serviços Educacionais ("Serviços").
				</p>
				<p>
					Ao se valer dos Serviços Educacionais e do uso do Portal, ambos disponibilizados pela Educa, as Escola e a comunidade escolar relacionada a ela, "Usuários", como Alunos, Família e Educadores, aceitam e se submetem às condições destes Termos e às Políticas de Privacidade.
				</p>
				<p>
					Caso você não concorde com quaisquer dispositivos desses Termos de Uso, não utilize o Portal Educa, nem divulgue a disponibilização do Portal para seus alunos e representantes legais, pois a utilização do Portal representa sua aceitação integral e incondicional a esses Termos de Uso.
				</p>
			</div>

			<div>
				<h5>Capacidade de Cadastro no Portal Educa</h5>
				<p>
					Para tornar-se usuário do Portal Educa, você deverá ser vinculado a uma Escola Parceira da Educa, como aluno, pai ou responsável, educador ou colaborador. Além de efetuar o cadastro, fornecendo dados pessoais, endereço de e-mail e senha, e deverá mantê-los atualizados. Outras informações pessoais adicionais poderão ser solicitadas. Ao realizar o cadastro você declara que toda a informação fornecida é atual, completa e precisa. Você concorda e entende que é responsável por manter a confidencialidade das senhas associadas com qualquer conta que você usar para acessar o Portal. Assim, você concorda que será unicamente responsável por todas as atividades realizadas com o seu login e senha, e entende que o registro é pessoal e intransferível.
				</p>
				<p>
					Os Serviços do Portal Educa estão disponíveis para pessoas físicas regularmente inscritas nos cadastros de contribuintes federal e estaduais que, com exceção aos Usuários menores de idade, para os quais a Educa se reserva o direito de solicitar informações e autorizações dos responsáveis que tenham capacidade legal para contratá-los. Não podem utilizá-los, assim, pessoas que tenham sido inabilitadas da Educa, temporária ou definitivamente.
				</p>
				<p>
					Somente será permitida a vinculação de um cadastro a um CPF, um telefone e um e-mail, não podendo haver duplicidade de dados em nenhum caso.
				</p>
			</div>

			<div>
				<h5>Objeto</h5>
				<p>
					Os presentes Termos têm por objeto o uso do Portal Educa para a prestação dos Serviços educacionais. A Educa terá autonomia na execução de seus serviços, seguindo o cronograma, o programa e a temática previamente divulgados. Ademais, a Educa oferecerá ao Usuário acesso ao material didático e/ou informativo relacionado ao serviço a que o Usuário estiver vinculado. Ao se cadastrar, o Usuário poderá utilizar todos os serviços disponibilizados na Plataforma disponíveis para o seu cadastro, declarando, para tanto, ter lido, compreendido e aceitado os Termos.
				</p>
			</div>

			<div>
				<h5>Portal Educa</h5>
				<p>
					O Usuário terá a permissão para usar o Portal Educa desde que cumpra todos os termos e condições deste Acordo. O Serviço está disponível para seu uso pessoal e não comercial e só deve ser usado para fins educacionais ou legais que ajudem a unir as comunidades escolares ou ajudem pais e filhos a construir relacionamentos mais fortes em casa.
				</p>
				<p>
					Estamos constantemente mudando e melhorando nossos serviços. Podemos adicionar ou remover funcionalidades ou recursos, e podemos suspender ou interromper um Serviço completamente, incluindo, por exemplo, se você não cumprir este Contrato ou se estivermos investigando uma suspeita de má conduta. Você pode parar de usar nosso Serviço a qualquer momento, embora lamentemos sua saída. Também podemos adicionar ou criar novos limites ao nosso Serviço ou restringir o seu acesso a todo ou parte do Serviço a qualquer momento, sem aviso ou responsabilidade.
				</p>
				<p>
					Acreditamos que você (ou sua escola, conforme aplicável) seja o proprietário de seus dados e preservar seu acesso a tais dados é muito importante para nós. Se descontinuarmos um serviço, onde for razoavelmente possível, daremos a você um aviso com antecedência razoável e a chance de obter informações desse serviço.
				</p>
				<p class="summary">
					Resumo: Você pode usar a Educa, contanto que siga as regras destes termos.
				</p>
			</div>

			<div>
				<h5>Conteúdo e Tecnologia</h5>
				<p>
					O Conteúdo e a Tecnologia Educa destinam-se exclusivamente ao uso pessoal de nossos Usuários e só podem ser acessados com o acordo deste Termo. Todo o conteúdo Educa, incluindo, o software, hardware e tecnologia usados para fornecer o Serviço Educa (incluindo código proprietário Educa e software de terceiros), interfaces de usuário, materiais e conteúdos exibidos, como texto, gráficos, artigos, fotografias, imagens, ilustrações e o design, estrutura, sequência e "aparência" dos Serviços Educa estão protegidos por este contrato.
				</p>
				<p>
					O Conteúdo e a Tecnologia Educa é protegida por direitos autorais e leis de propriedade intelectual. O uso de nosso serviço não concede a você a propriedade de quaisquer direitos de propriedade intelectual do conteúdo ou da tecnologia Educa. Estes termos não concedem a você o direito de usar quaisquer Marcas Educa.
				</p>
				<p>
					Com este contrato, você não irá:
				</p>
				<div class="items">
					<p>
						1. Executar, licenciar, alugar, emprestar, distribuir ou vender acesso ao Portal Educa.
					</p>
					<p>
						2. Construir ou oferecer suporte (e / ou auxiliar um terceiro na construção ou suporte) de produtos ou serviços concorrentes da Educa para desenvolver um produto usando ideias, recursos, funções, interface ou gráficos semelhantes da Educa.
					</p>
					<p>
						3. Usar, armazenar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, carregar, criar trabalhos derivados, exibir, licenciar, vender ou explorar a Tecnologia Educa para quaisquer fins que não sejam expressamente permitidos neste Acordo.
					</p>
					<p>
						4.	Remover, ocultar ou alterar qualquer direito autoral, logotipo, marca registrada ou outros avisos legais exibidos em ou junto com nossos Serviços ou quaisquer Recursos Educa ou outros produtos de uma maneira que crie a impressão de que quaisquer Conteúdo da Educa pertence a você.
					</p>
				</div>
				<p class="summary">
					Resumo: O uso de nosso serviço não concede a você nenhum direito de propriedade sobre nossa propriedade intelectual. Respeitamos direitos autorais, marcas registradas e marcas.
				</p>
			</div>

			<div>
				<h5>Privacidade e Segurança</h5>
				<p>
					Sua privacidade é extremamente importante para nós e tomamos muito cuidado para limitar o que coletamos e como o usamos, e para permitir que você entenda como funciona nossas políticas. Por favor, leia nossa Política de Privacidade que explica como tratamos suas informações pessoais e protegemos sua privacidade quando você usa nosso Serviço.
				</p>
				<p class="summary">
					Resumo: Aconselhamos a você ler nossa Política de Privacidade, que descreve nossas práticas para lidar com qualquer informação pessoal que você possa nos fornecer.
				</p>
			</div>

			<div>
				<h5>Atualizações no Portal Educa</h5>
				<p>
					Podemos modificar este Acordo ou Diretrizes para, por exemplo, refletir mudanças na lei ou mudanças em nossos Serviços do Portal Educa. Aconselhamos que consulte este documento regularmente. Publicaremos notificações de modificações a este Contrato nesta página ou em qualquer outro lugar nos Serviços e / ou conforme exigido por lei. As alterações não serão aplicadas retroativamente e só entrarão em vigor quando você usar o Serviço após saber sobre as alterações ou trinta dias após a publicação (o que ocorrer primeiro). No entanto, as alterações relativas a novas funções para um serviço ou alterações feitas por motivos legais entrarão em vigor imediatamente. Seu uso acesso contínuo a nosso Portal, após a notificação das mudanças no Acordo ou Diretrizes, constitui sua aceitação de nossos Termos de Uso e Políticas. Se você não concordar com o Acordo modificado, deverá interromper o uso do Serviço.
				</p>
				<p class="summary">
					Resumo: Se este Termo de Uso passar por alguma atualização, nós notificaremos você. Se você não gostar das novas mudanças, daremos a você a oportunidade de entrar em contato conosco e cancelar a mudança. Não mudaremos os Termos retroativamente.
				</p>
			</div>

			<div>
				<h5>Suas Representações e Garantia</h5>
				<p>Você garante e concorda que não fornecerá nenhum Conteúdo ou usará o Portal Educa de maneira que:</p>
				<div class="items">
					<p>
						1. Infrinja, viole ou se aproprie indevidamente dos direitos de propriedade intelectual, direitos de publicidade ou privacidade de terceiros ou outros direitos;
					</p>
					<p>
						2. Viole qualquer lei, estatuto, decreto ou regulamento internacional, federal, estadual ou local de quaisquer leis ou regulamentos aplicáveis.
					</p>
					<p>
						3. Seja prejudicial, fraudulento, ameaçador, abusivo, ofensivo, tortuoso, difamatório, vulgar, obsceno, calunioso ou de outra forma questionável;
					</p>
					<p>
						4. Coloque em risco a segurança de sua conta ou do Portal de qualquer forma, como permitir que outra pessoa acesse sua conta ou enviar algum conteúdo que contenha vírus.
					</p>
				</div>
				<p class="summary">
					Resumo: Você concorda em não postar conteúdo que prejudique outras pessoas ou viole os direitos de outra pessoa. Achamos que você será razoável e responsável, mas se não for, talvez tenhamos que remover seu acesso ao Portal.
				</p>
			</div>

			<div>
				<h5>Uso e Conduta Positiva</h5>
			<p>
				Fazemos o possível para manter o Portal Educa seguro, mas não podemos garantir isso. Precisamos de sua ajuda para mantê-lo seguro, o que inclui os seguintes compromissos de sua parte ao usar nosso Serviço:
			</p>
			<div class="items">
				<p>
					1. Você só usará o Serviço conforme permitido por lei, incluindo leis e regulamentos de controle de exportação ou reexportação aplicáveis.
				</p>
				<p>
					2. Você não coletará o conteúdo ou quaisquer informações usando meios automatizados (como robôs de colheita, robôs, spiders ou scrapers) sem nossa permissão prévia.
				</p>
				<p>
					3. Você não coletará, solicitará ou obterá de outra forma informações de login ou acessar uma conta pertencente a outra pessoa.
				</p>
				<p>
					4. Você não intimidará ou assediará qualquer usuário ou usará o serviço de qualquer forma que seja ameaçadora, abusiva, violenta ou prejudicial a qualquer pessoa ou entidade, ou invasiva da privacidade de outra.
				</p>
				<p>
					5. Você não usará o Conteúdo da Educa de nenhuma forma para fazer upload, postar, transmitir, enviar por e-mail ou de qualquer outra forma de distribuição que: seja discurso de ódio, discriminatório, difamatório, ameaçador, obsceno, que incite a violência ou de outra forma questionável.
				</p>
				<p>
					6. Você não usará o Portal Educa para fazer nada ilegal, enganoso, enganoso, ilegal, antiético, malicioso ou discriminatório.
				</p>
				<p>
					7. Você não acessará (ou tentará obter acesso não autorizado) ao Serviço ou aos sistemas de computador da Educa por qualquer meio que não seja o permitido ou se envolverá em qualquer atividade que interrompa, diminua a qualidade ou prejudique a funcionalidade do Portal Educa.
				</p>
				<p>
					8. Você não usará o serviço de nenhuma maneira comercialmente irracional ou de qualquer maneira que deprecie a Educa
				</p>
				<p>
					9. Você não irá se passar por um funcionário da Educa, ou qualquer outra pessoa, ou declarar falsamente ou de outra forma deturpar sua afiliação com qualquer pessoa ou entidade.
				</p>
				<p>
					10. Você não usará o Portal Educa de maneira que seja prejudicial a menores. Você não transmitirá ou postará qualquer conteúdo que viole as leis de pornografia infantil ou leis de exploração sexual infantil. A Educa absolutamente não irá tolerar e irá relatar quaisquer casos suspeitos de pornografia infantil, incluindo relatar informações de registro do usuário, às autoridades competentes.
				</p>
				<p>
					11. Você não irá copiar, modificar ou distribuir qualquer texto, gráfico ou outro material ou conteúdo disponível no Portal Educa sem nossa permissão prévia por escrito.
				</p>
				<p>
					12. Você sempre cumprirá as diretrizes da comunidade.
				</p>
			</div>
			<p class="summary">
				Resumo: Você concorda em não usar indevidamente os serviços Educa.
			</p>
			</div>

			<div>
				<h5>Indenização</h5>
				<p>
					O Usuário indenizará a Educa, empresas controladas, controladores diretos ou indiretos, diretores, administradores, colaboradores, representantes e empregados, inclusive quanto a honorários advocatícios, por qualquer demanda promovida por outros Usuários ou terceiros, decorrentes das atividades do primeiro na Plataforma, de quaisquer descumprimentos, por aquele, dos Termos e das demais políticas da Educa ou, ainda, de qualquer violação de lei ou de direitos de terceiros pelo Usuário.
				</p>
			</div>

			<div>
				<h5>Foro</h5>
				<p>
					Todos os itens desta Política são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a esta Política, as partes concordam e elegem, expressamente, o Foro Central da Comarca de São Paulo.
				</p>
			</div>

			<div>
				<h5>Declaração do Usuário</h5>
				<p>
					O Usuário declara ter lido, entendido e aceitado todas as regras, condições e obrigações estabelecidas na presente Política de Privacidade.
				</p>
			</div>

			<div class="update text-sm">
				<p>
					Atualização: 1 de dezembro de 2021. <br>
					São Paulo /SP, data de aceite, acesso e utilização da Plataforma e demais aplicações.
				</p>
			</div>
		</div>
		</div>


	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex items-center w-full max-w-19xl ml-20">
			<h2 class="section-title w-full max-w-max" id="educa-press">POLÍTICA DE PRIVACIDADE</h2>
			<div class="border-t-2 border-edu-primary-green w-full max-w-1/2 ml-10"></div>
		</div>
	</div>


	<div class="w-full max-w-13xl mx-auto grid grid-cols-1 gap-x-10 gap-y-10 mt-12 ml-20" id="staff">
		<div class="flex flex-col pl-5 w-10/12 justify-start md:pl-10 mt-5 md:mt-0 mb-10">
			<div>
				<p>
					A privacidade de seus dados é uma responsabilidade levada muito a sério na Educa. Por esse motivo, a nossa Política de Privacidade é projetada para fornecer transparência em nossas práticas e princípios de privacidade. Esta Política de Privacidade detalha as formas de coleta, guarda, utilização, compartilhamento e divulgação dos dados pessoais dos Usuários, bem como aponta claramente as medidas tomadas para assegurar a proteção dos dados coletados. Nossos processos estão de acordo com a Lei Geral de Proteção de Dados (LGPD) que, principalmente, garante aos usuários o direito de entender para qual finalidade seus dados são utilizados.
				</p>
				<p>
					Nossa Política de Privacidade é aplicável aos usuários do Portal (website) Educa (https://educa2l.com.br), sociedade empresária limitada, inscrita no Cadastro Nacional das Pessoas Jurídicas do Ministério da Economia ("CNPJ/ME"), sob o 40.651.433/0001-21 e tem como objetivo esclarecer as suas práticas no que diz respeito à privacidade das informações prestadas pelo Usuário.
				</p>
				<p>
					Ao acessar ou usar o Portal Educa, você concorda com nossa Política de Privacidade, com a coleta, uso, divulgação e outro manuseio de suas informações.
				</p>
				<p>
					Caso você queira entrar em contato conosco, basta enviar um e-mail <a class="email-link" target="_blank" href="mailto:atendimento@educa21.com.br">clicando aqui</a>.
				</p>
			</div>
			<div>
				<h5>Informações Coletadas</h5>
				<p>
					Quando o Usuário acessa e/ou se cadastra na Educa, bem como os serviços nela oferecidos, aceita as regras sobre a coleta, acesso, uso, armazenamento, transferência, enriquecimento e eliminação dos seus dados pessoais coletados, conferindo sua livre e expressa concordância com os termos aqui estipulados. Caso o Usuário opte por não fornecer informações pessoais à Educa, este não poderá ter acesso aos serviços do Portal, uma vez que a área do Usuário é de acesso restrito a somente aqueles devidamente cadastrados.
				</p>
				<p>
					A Educa coleta as informações fornecidas pelo Usuário, tanto no momento que este realiza o seu cadastro, quanto no período em que este utiliza o Portal. Os dados do Usuário somente serão utilizados pela Educa nos termos e condições da nossa Política de Privacidade quando fornecido com o seu consentimento livre, expresso, informado e inequívoco pelos Usuários ou nos casos previstos em lei.

					Em nosso Portal, coletamos dois tipos de informações sobre você:
				</p>
				<div class="items">
					<p>
						(1) Informações que você pode escolher nos fornecer voluntariamente durante o uso do Portal (descrito abaixo em "Informações que você nos fornece"
					</p>
					<p>
						(2) Informações coletadas automaticamente como resultado do uso do Portal (descrito abaixo em "Informações coletadas automaticamente"

						Observação: a Educa não é responsável pela veracidade ou falta dela nas informações prestadas pelo Usuário, bem como pela sua desatualização. É responsabilidade do Usuário prestá-las com exatidão ou atualizá-las.
					</p>
				</div>

				<h6>(1) Informações que você nos fornece</h6>
				<p>
					Você pode optar por fornecer voluntariamente certas informações pessoais para acessar certos recursos, eventos ou ter acesso a área logada de nosso Portal. Por exemplo, quando você nos envia uma mensagem usando a página "Fale conosco" ou via e-mail, retemos o conteúdo da mensagem, seu endereço de e-mail e qualquer informação que você nos fornecer, como seu nome, número de telefone, sua persona, nome de sua escola e suas preferências de contato. Isso nos ajuda a gerenciar qualquer dúvida de acompanhamento que você possa ter e medir a eficácia com que tratamos suas preocupações. Qualquer informação retida será usada para garantir a entrega dos serviços ou informações que você solicitou e para otimizar sua experiência e melhorar a qualidade do tempo que você passa conosco online.
				</p>
				<p>
					As informações coletadas são:
				</p>
				<div class="items">
					<p>1. Nome Completo; </p>
					<p>2. CPF;</p>
					<p>3. Data de Nascimento;</p>
					<p>4. Gênero;</p>
					<p>5. E-mail;</p>
					<p>6. Celular;</p>
					<p>7. CEP;</p>
					<p>8. Endereço Completo;</p>
					<p>9. Cidade;</p>
					<p>10. Estado;</p>
					<p>11. Ocupação Profissional Atual;</p>
					<p>12. Nome da Empresa ou Escola.</p>
				</div>
				<h6>(2) Informações coletadas automaticamente</h6>
				<p>
					Para fornecer uma experiência personalizada e de alta qualidade para nossos Usuários, nós usamos tecnologias que registram e coletam automaticamente certas informações de seu navegador ou dispositivo quando você usa o Portal, lê nossos e-mails ou se compromete de outra forma conosco. Esta informação é necessária para nos permitir cumprir com as obrigações legais e dado o nosso legítimo interesse em poder fornecer e melhorar o Portal Educa. Por exemplo, isso pode incluir a frequência e a duração de suas visitas ao Portal (semelhante às classificações de TV que indicam quantas pessoas assistiram a um determinado programa). Se você visitar o Portal em dispositivos diferentes, podemos vincular as informações que coletamos desses dispositivos diferentes para nos ajudar a fornecer um serviço consistente em seus diferentes dispositivos ou para fornecer a você conteúdo da Educa relevante.
				</p>
				<p>
					As informações coletadas são:
				</p>
				<div class="items">
					<p>1. Endereço de IP;</p>
					<p>2. Tipo do navegador e idioma; </p>
					<p>3. Informações de cookie ou tecnologia semelhante;</p>
					<p>4. Tipo do sistema;</p>
					<p>5. Horários de acesso;</p>
					<p>6. Quais aulas ou conteúdos foram acessados e assistidos pelo Usuário no Portal;</p>
					<p>7. Quais conteúdos foram abertos pelo Usuário na Plataforma.</p>
				</div>
				<p>
					Como muitos serviços, a Educa usa essas tecnologias para analisar tendências, reunir informações demográficas, entender seu envolvimento com o nosso Portal, administrar e personalizar o Portal para você com o objetivo de potencializar sua experiência - por exemplo, lembrando-se de seu idioma preferências.
				</p>
			</div>

			<div>
				<h5>Como Desativar as Ferramentas de Coleta?</h5>
				<p>
					A qualquer momento o Usuário pode desativar as Ferramentas de Coleta, acessando as configurações do seu navegador. No entanto, a desativação da Ferramentas de Coleta pode fazer com que o Usuário não tenha acesso a recursos que fazem com que sua experiência seja mais eficaz e alguns dos nossos serviços e experiências podem não funcionar de forma adequada.
				</p>
			</div>

			<div>
				<h5>Como Usamos as Informações Coletadas?</h5>
				<p>A Educa coleta os dados e informações dos Usuários para:</p>
				<div class="items">
					<p>1. Habilitar o uso do Usuário na Plataforma;</p>
					<p>2. Aprimorar, customizar e personalizar as comunicações com os Usuários;</p>
					<p>3. Atender os pedidos do Usuário e fornecer todas as informações solicitadas, como resposta a seus e-mails, envios, perguntas, comentários, solicitações, reclamações e atendimento ao cliente com o objetivo de manter os Usuários informados sobre os serviços da Educa;</p>
					<p>4. Manter contato com o Usuário informando sobre novidades, promoções e conteúdos de nossos serviços e eventos;</p>
					<p>5. Compartilhar os dados coletados utilizando a tecnologia de cookies e ferramentas de personalização e recomendações para oferecer uma experiência mais adequada aos nossos Usuários;</p>
					<p>6. Realizar a análise das atividades e dos comportamentos dos Usuários ao utilizarem o Portal; </p>
					<p>7. Nos resguardar de direitos e obrigações relacionadas ao uso da nossa Plataforma, conforme disposições da legislação brasileira;</p>
					<p>8. Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa;</p>
					<p>9. Por motivos de segurança, detectar, investigar e prevenir atividades que possam violar nossas políticas ou serem ilegais;</p>
					<p>10. Compartilhar os dados coletados com nossos parceiros para manutenção e aprimoramento da nossa Plataforma, produtos e serviços;</p>
					<p>11. Compartilhar os dados coletados utilizando a tecnologia de cookies e ferramentas de personalização e recomendações para oferecer uma experiência mais adequada aos nossos Usuários.</p>
				</div>
				<p>
					Além disso, processaremos suas informações pessoais para os fins listados nesta seção relacionados ao marketing, dados nossos legítimos interesses em realizar atividades de marketing para oferecer a você produtos e serviços que possam ser de seu interesse. Você pode optar por não receber nossas comunicações de marketing seguindo as instruções de cancelamento de assinatura incluídas em nossas comunicações de marketing.
				</p>
				<p>
					Caso a Educa altere ou adicione nova finalidade para as quais utiliza os dados dos Usuários, estes serão notificados com relação às mudanças na próxima vez que utilizarem o Portal. No momento em que os Usuários forem notificados, a Educa irá solicitar por e-mail novo consentimento desses para coleta e tratamento dos novos dados pessoais.
				</p>
			</div>

			<div>
				<h5>Compartilhando suas Informações </h5>
				<p>Não vendemos ou venderemos suas informações pessoais coletadas em nosso Portal a terceiros para fins promocionais. O uso, acesso e compartilhamento dos dados coletados pela Educa serão feitos dentro dos limites e propósito dos negócios da Educa, desde que obedecido ao disposto na presente Política de Privacidade.</p>
				<p>Não compartilhamos informações pessoais com terceiros, exceto nas circunstâncias limitadas estabelecidas nesta Política de Privacidade, incluindo: </p>
				<div class="items">
					<p>1. Integrações de Terceiros em nosso Portal: Quando você usa aplicativos, sites ou outros serviços de terceiros que usam ou estão integrados ao nosso site, eles podem receber informações sobre o que você publica ou compartilha. Por exemplo, quando você compartilha via Facebook ou Twitter, esses serviços recebem as informações que você compartilha por meio dessa funcionalidade e as informações que você está compartilhando em nosso Portal. As informações coletadas por esses aplicativos, sites ou serviços integrados estão sujeitas aos seus próprios termos e políticas.</p>
					<p>2. Provedores de Serviços: Trabalhamos com fornecedores, provedores de serviços e outros parceiros para nos ajudar a fornecer os serviços de nosso Portal. Podemos precisar compartilhar ou fornecer informações com segurança para ajudá-los a desempenhar essas funções de negócios, por exemplo, enviar e-mails em nosso nome, serviços de gerenciamento de banco de dados, hospedagem de banco de dados, fornecimento de software de suporte ao cliente, fornecimento de pesquisas, assistência no emprego aplicativos e segurança. Também podemos autorizar essas empresas terceirizadas a coletar informações em nosso nome. Esses provedores têm acesso limitado às suas informações pessoais para realizar essas tarefas em nosso nome e são contratualmente obrigados a protegê-las e usá-las apenas para os fins para os quais foram divulgadas. Além disso, esses parceiros e prestadores de serviços devem cumprir as obrigações de confidencialidade e segurança de maneira consistente com esta Política de Privacidade.</p>
					<p>3. Serviços de Análise: Para nos ajudar a entender melhor seu uso no nosso Portal, podemos usar provedores de análise terceirizados, como o Google Analytics. Você pode descobrir mais sobre a posição do Google sobre privacidade em relação ao seu serviço de análise em <a href="www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>. Esses terceiros usam o tipo de tecnologia descrito na seção "Informações coletadas automaticamente" acima. Esses serviços podem coletar, armazenar e usar informações para nos ajudar a entender coisas, como por exemplo, a frequência com que você usa o Portal. </p>
					<p>4. Plataformas de Mídia Social: Quando de acordo com a lei, podemos compartilhar certas informações pessoais limitadas com plataformas de mídia social (como Google ou Facebook) e outros sites, aplicativos ou parceiros (isso nunca incluirá informações pessoais de alunos). Essas atividades de processamento baseiam-se em nosso legítimo interesse em realizar atividades de marketing para oferecer a você produtos e serviços que possam ser de seu interesse. </p>
					<p>5. Requisitos Legais: Podemos divulgar informações, incluindo informações pessoais, se acreditarmos de boa fé que isso é necessário para cumprir a lei, como uma intimação ou outro processo legal. Podemos precisar divulgar informações pessoais onde, de boa fé, acharmos necessário para proteger os direitos, propriedade ou segurança da Educa, nossos funcionários, nossa comunidade ou outros, ou para evitar violações de nossa Política ou outros acordos. Isso inclui, sem limitação, responder a pedidos de aplicação da lei e do governo.</p>
					<p>6. Fraude e Proteção de Segurança: Podemos compartilhar as informações para auxiliar nas operações de segurança para testes de penetração programados, auditoria e melhoria contínua de nossas práticas de segurança, incluindo, sem limitação, a troca de informações com outras empresas e organizações para proteção contra fraudes. As partes autorizadas das informações cumprirão nossas políticas atuais de privacidade e segurança.</p>
					<p>7. Compartilhando com empresas Educa: Com o tempo, a Educa pode crescer e se reorganizar. Podemos compartilhar suas informações, incluindo informações pessoais com afiliados, como uma empresa controladora, subsidiárias, parceiros de joint venture ou outras empresas que controlamos ou que estão sob controle comum conosco, caso em que exigiremos que essas empresas concordem em usar o seu informações pessoais de forma consistente com esta Política de Privacidade. </p>
					<p>8. Mudança de Controle: No caso de todo ou parte da Educa ou seus ativos serem adquiridos ou fundidos com terceiros, podemos divulgar, compartilhar ou transferir as informações. Esta Política de Privacidade continuará a se aplicar às suas informações, e qualquer adquirente só poderá lidar com suas informações pessoais de acordo com esta Política (a menos que você dê consentimento para uma nova política). </p>
					<p>9. Com o Seu Consentimento: Exceto nos casos acima, não divulgaremos suas informações pessoais para qualquer finalidade. Além disso, conforme discutido acima, nunca venderemos suas informações pessoais a anunciantes ou terceiros. </p>
				</div>
			</div>

			<div>
				<h5>Proteção de Informações e Medidas de Segurança</h5>
				<p>Os dados dos Usuários são acessados somente por colaboradores e/ou por terceiros contratados pela Educa para a prestação dos serviços de aprendizagem, conforme acima disposto, todos esses devidamente autorizados e sujeitos ao disposto na nesta Política de Privacidade, além do compromisso de confidencialidade e preservação da privacidade nos termos desta Política de Privacidade. </p>
				<p>A Educa recomenda que o Usuário mantenha sua senha sob total sigilo, evitando a sua divulgação a terceiros. A Educa nunca solicitará a senha do Usuário fora de nossa Plataforma.</p>
				<p>A Educa possui procedimentos internos para reagir frente a suspeita de violação de dados pessoais e notificar o Usuário e as autoridades competentes. Em casos de suspeita de violação de vazamento de informações sensíveis, a Educa compromete-se a notificar os Usuários afetados em até 10 dias da confirmação de violação de seus dados pessoais. Os mecanismos empregados pela Educa com o objetivo de garantir a inviolabilidade dos dados. </p>
			</div>


			<div>
				<h5>Acesse, Corrija ou Exclua suas Informações Pessoais </h5>
				<p>Usuário poderá exercer seus direitos de acesso, retificação, cancelamento e oposição, em relação aos dados e informações coletadas diretamente pela Educa, basta entrar em contato através do e-mail <a class="email-link" target="_blank" href="mailto:atendimento@educa21.com.br">clicando aqui</a>. Apenas os dados identificáveis são passíveis do exercício de tais direitos.</p>
				<p>A Educa se reserva o direito de utilizar todos os meios lícitos necessários para aferir a identidade daqueles que solicitam o exercício dos direitos dos Usuários de modo a comprovar tratar-se, de fato, dos respectivos titulares de dados.</p>
				<p>A Educa empregará os melhores esforços para responder a todos os pedidos legítimos dos Usuários descrito no item em até 15 dias. Ocasionalmente, pode demorar mais se a sua solicitação for particularmente complexa ou se tiver feito várias solicitações. Neste caso, iremos notificá-lo.</p>
				<p>Usuário deve notificar imediatamente a Educa em caso de quaisquer dados perdidos, roubados ou utilizados sem a permissão do Usuário, por meio do seguinte canal <a class="email-link" target="_blank" href="mailto:atendimento@educa21.com.br">clicando aqui</a>. Nesse caso, a Educa se compromete a remover os dados indevidos do Usuário e atualizar o cadastro da forma correta.</p>
				<p>Usuário pode solicitar a exclusão de seus dados pessoais coletados e registrados pela Educa. Para solicitar a exclusão dos dados pessoais basta entrar em contato com a Educa através do seguinte e-mail <a class="email-link" target="_blank" href="mailto:atendimento@educa21.com.br">clicando aqui</a>, ou por uma de nossas ferramentas de atendimento e contato. Os dados pessoais dos Usuários serão excluídos no prazo médio de 15 dias da solicitação de exclusão de dados ou da solicitação de encerramento de conta.</p>
			</div>

			<div>
				<h5>Comunicações da Educa e Cancelamento da Inscrição </h5>
				<p>Se você se registrou no nosso Portal, forneceu um e-mail ou número de telefone para nós, ou de outra forma optou por receber nossas comunicações, podemos enviar-lhe mensagens e atualizações sobre sua conta, atualizações sobre o Portal ou Serviço, e informações sobre produtos, recursos ou serviços da Educa (ou terceiros em que acreditamos que você possa estar interessado). Esses métodos de comunicação podem incluir, mas não estão limitados a e-mail, correio postal, comunicações de mídia social, aplicativos de mensagens diretas (como o WhatsApp) chamadas telefônicas e notificações push.</p>
				<p>Você sempre pode cancelar o recebimento de qualquer um de nossos 1) e-mails de marketing ou outras comunicações de marketing sempre que desejar, clicando no link "Cancelar inscrição" na parte inferior do e-mail; ou 2) notificações push de marketing desativando as notificações push em seu dispositivo. Caso tenha cancelado a sua assinatura para não receber mais nenhum comunicado da Educa, por conta do tráfego em nossos servidores, o Usuário ainda poderá receber comunicados já agendados na fila de disparos. No entanto, nenhum novo disparo será agendado a partir do momento que solicitar o descadastramento.</p>
			</div>

			<div>
				<h5>Crianças Menores de 13 Anos</h5>
				<p>O Portal da Educa não é direcionado a indivíduos menores de treze (13) anos sem acompanhamento de um adulto responsável. Solicitamos que os menores de treze (13) anos não forneçam informações pessoais. Além disso, não coletamos intencionalmente (nem permitimos que terceiros coletem) ou mantemos informações pessoais de menores de 13 anos. Se tomarmos conhecimento de que uma criança menor de 13 anos nos forneceu informações pessoais ou de que essas informações pessoais foram coletadas de uma criança menor de 13 anos, excluiremos essas informações de nossos arquivos o mais rápido possível.</p>
			</div>

			<div>
				<h5>Como Ter Acesso a Maiores Esclarecimentos? </h5>
				<p>Em atendimento às disposições da LGPD, a Educa é uma equipe responsável por intermediar a comunicação entre nós e os titulares dos dados pessoais e/ ou a Autoridade Nacional de Proteção de Dados. Assim, caso você tenha qualquer dúvida sobre como seus dados são utilizados por nós ou queria exercer seus direitos mencionados acima, basta entrar em contato através do e-mail <a class="email-link" target="_blank" href="mailto:atendimento@educa21.com.br">clicando aqui</a>.</p>
			</div>

			<div>
				<h5>Atualizações na Política de Privacidade Educa </h5>
				<p>Como os serviços prestados pela Educa estão em constante aprimoramento, ao longo do tempo, também será necessário atualizar esta Política de Privacidade. A Educa avisará sobre a atualização na Política, colocando um aviso no site, enviando um e-mail e/ou por meio de algum outro meio disponível. </p>
			</div>

			<div>
				<h5>Termos de Uso</h5>
				<p>O uso dos serviços da Educa e quaisquer disputas oriundas delas, está sujeito a esta Política de Privacidade, bem como aos nossos Termos de Uso. Visite nossos Termos de Uso, que explica outros termos que regem o uso dos nossos produtos e serviços. </p>
			</div>

			<div>
				<h5>Foro</h5>
				<p>Todos os itens desta Política são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a esta Política, as partes concordam e elegem, expressamente, o Foro Central da Comarca de São Paulo.</p>
			</div>

			<div>
				<h5>Declaração do Usuário </h5>
				<p>O Usuário declara ter lido, entendido e aceitado todas as regras, condições e obrigações estabelecidas na presente Política de Privacidade. </p>
			</div>

			<div class="update text-sm">
				<p>Atualização: 1 de dezembro de 2021. <br>
					São Paulo/SP, data de aceite, acesso e utilização da Plataforma e demais aplicações.</p>
			</div>
		</div>
	</div>
</main>
