<ng-container *ngIf="post">
	<div class="shadow-lg flex flex-col items-stretch flex-1 bg-edu-white" [class.h-auto]="slidePostList" [class.lg:flex-row]="highlighted" [class.h-auto]="highlighted" [class.lg:max-h-80]="highlighted"  [class.w-100]="fixedWidth">
		<img alt="Thumbnail" class="w-full" [class.object-cover]="!usingThumbnailFallback" [class.object-contain]="usingThumbnailFallback" [class.lg:w-half]="highlighted" [class.h-52]="!highlighted" [class.px-5]="usingThumbnailFallback" [src]="post.thumbnailUrl || './assets/images/edu-logo.png'" (error)="handleThumbnailLoadingError()" />

		<div class="flex flex-col items-stretch justify-start p-4 md:p-6 w-full lg:p-8" [class.lg:w-half]="highlighted" [class.h-auto]="!highlighted && !slidePostList" [class.h-full]="slidePostList">
			<p class="font-carbon font-bold text-2xl text-edu-black mb-5 leading-tight">{{  post?.title }}</p>
			<p class="body mb-5 break-words">{{ postPreview?.length > contentPreviewMaxLength ? postPreview?.substring(0, contentPreviewMaxLength) + "..." : postPreview }}</p>
			<div class="w-full flex flex-col lg:flex-row items-start lg:items-center justify-between mt-auto">
				<edu-post-author [post]="post"></edu-post-author>
				<edu-button variant="secondary" class="w-full md:w-auto mx-auto lg:mx-0" (click)="handleReadMoreClick()">Ler mais</edu-button>
			</div>
			<p class="body-sm text-edu-dark-grey text-right w-full mt-5 lg:mt-1" *ngIf="post?.readingTime">Tempo de leitura: {{ post?.readingTime }}</p>
		</div>
	</div>
</ng-container>
