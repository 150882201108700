<edu-helmet title="Recursos Gratuitos"></edu-helmet>

<main class="w-full">
	<div class="banner-section w-full flex h-72 sm:h-80 md:h-100 xl:h-120 2xl:h-140">
		<div class="flex flex-col items-start w-full max-w-screen-2xl mx-auto md:h-3/5 px-0 md:px-10 xl:mt-10 2xl:mt-auto xl:my-auto">
			<div class="w-full flex flex-col">
				<div class="w-full flex flex-col items-start max-w-md lg:max-w-xl mt-10 md:mt-4 mb-2 md:mb-0 sm:mx-8 px-4 md:pl-4 md:ml-0">
					<h1 class="section-title w-auto leading-tight break-words lg:break-normal">IDEIAS, GUIAS E INSPIRAÇÃO</h1>
					<span>
						<img src="./assets/icons/chevron-down-outline.svg" alt="↓" class="w-7 md:w-12 h-auto relative" />
					</span>
				</div>
			</div>
		</div>
	</div>

	<div class="w-full max-w-max mx-auto px-2 md:px-10 grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-y-8 -mt-10 md:-mt-36 md:pb-16 mb-8 resources-section-waves">
		<div class="shadow-xl w-full md:w-4/5 bg-edu-white flex flex-col items-center justify-between mx-auto py-6 md:py-10 pb-7 px-4 col-span-1 md:max-w-xs lg:max-w-sm" *ngFor="let card of contentCards">
			<img [src]="'./assets/icons/' + card.icon" [alt]="card.alt" class="w-10 md:w-14" />
			<p class="title-sm text-center whitespace-wrap px-2.5 leading-none">{{ card.title }}</p>
			<div class="border-t-2 border-edu-primary-green w-1/2 mx-auto max-w-1/2 my-2"></div>
			<p class="body-sm text-center mb-6 px-2">{{ card.subtitle }}</p>
			<div class="w-full sm:w-2/3 flex justify-center">
				<a [href]="card.contentLink" rel="noreferrer noopener" target="_blank" class="w-full md:w-auto mx-2 sm:mx-auto">
					<edu-button>Saiba mais</edu-button>
				</a>
			</div>
		</div>
	</div>

	<div class="flex items-center w-full max-w-19xl ml-auto py-10 px-6">
		<h2 class="section-title w-full max-w-max leading-tight md:px-14">RECURSOS PARA PROFESSORES</h2>

		<div class="border-t-2 border-edu-primary-green w-full max-w-1/2"></div>
	</div>

	<div class="w-full max-w-13xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-5 px-2 md:px-20 pb-10 items-start mb-14 resources-section-waves">
		<div class="shadow-xl bg-edu-white flex flex-col justify-start w-full md:w-4/5 py-5 col-span-1" *ngFor="let teachersCard of forTeachersCards" [class]="teachersCard.style">
			<p class="title-sm px-5 leading-tight mb-2">{{ teachersCard.title }}</p>
			<p class="body-sm px-5">{{ teachersCard.subtitle }}</p>
			<div class="flex items-center justify-start px-5 md:pl-5 mt-5 w-full md:w-1/2">
				<a class="w-full md:w-1/2" [href]="teachersCard.contentLink" rel="noreferrer noopener" target="_blank"
					><edu-button variant="secondary">{{ teachersCard.btn }}</edu-button></a
				>
			</div>
		</div>
	</div>

	<div class="flex items-center w-full max-w-19xl ml-auto mt-14 md:my-20 pb-10 px-6 md:pl-20 justify-between">
		<h2 class="section-title w-2/5 max-w-max">RECURSOS PARA LÍDERES ESCOLARES</h2>
		<div class="flex flex-col w-1/2 justify-between">
			<div class="border-t-2 border-edu-primary-green w-full max-w-1/2 ml-2"></div>
			<div class="md:z-20 hidden md:flex absolute md:-mt-28 justify-center">
				<div class="shadow-xl bg-edu-white flex flex-col justify-start w-2/3 py-5 px-4" *ngFor="let schoolCard of schoolLeadersCards">
					<p class="title-sm md:px-5 mb-2 leading-tight">{{ schoolCard.title }}</p>
					<p class="body-sm md:px-5">{{ schoolCard.subtitle }}</p>
					<div class="flex items-center justify-start md:pl-5 mt-5">
						<a class="w-full md:w-1/2" [href]="schoolCard.contentLink" rel="noreferrer noopener" target="_blank"
							><edu-button variant="secondary">{{ schoolCard.btn }}</edu-button></a
						>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="flex md:hidden px-2">
		<div class="shadow-xl bg-edu-white flex flex-col justify-start w-full py-5 px-4" *ngFor="let schoolCard of schoolLeadersCards">
			<p class="title-sm md:px-5 mb-2 leading-tight">{{ schoolCard.title }}</p>
			<p class="body-sm md:px-5">{{ schoolCard.subtitle }}</p>
			<div class="flex items-center justify-start md:pl-5 mt-5">
				<a class="w-full md:w-1/2" [href]="schoolCard.contentLink" rel="noreferrer noopener" target="_blank"
					><edu-button variant="secondary">{{ schoolCard.btn }}</edu-button></a
				>
			</div>
		</div>
	</div>

	<div class="flex items-center w-full max-w-19xl ml-auto mt-14 pb-10 px-6">
		<h2 class="section-title w-full max-w-max md:px-14">RECURSOS PARA PAIS</h2>

		<div class="border-t-2 border-edu-primary-green w-full max-w-1/2 ml-2"></div>
	</div>

	<div class="w-full max-w-13xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-5 px-2 md:px-20 pb-10 items-start mb-14">
		<div class="shadow-xl bg-edu-white flex flex-col justify-start w-full md:w-4/5 py-5 col-span-1" *ngFor="let parentCard of forParentsCards">
			<p class="title-sm px-5 mb-2 leading-tight">{{ parentCard.title }}</p>
			<p class="body-sm px-5">{{ parentCard.subtitle }}</p>
			<div class="flex items-center justify-start px-5 md:pl-5 mt-5">
				<a class="whitespace-nowrap w-full md:1/2" [href]="parentCard.contentLink" rel="noreferrer noopener" target="_blank"
					><edu-button variant="secondary">{{ parentCard.btn }}</edu-button></a
				>
			</div>
		</div>
	</div>
</main>
