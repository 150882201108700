import { Component, OnInit } from '@angular/core';
import { PARENTAL_ITEMS } from './parental-educators.constants';

@Component({
  selector: 'app-parental-educators',
  templateUrl: './parental-educators.component.html',
  styleUrls: ['./parental-educators.component.scss']
})
export class ParentalEducatorsComponent implements OnInit {

	public items = PARENTAL_ITEMS;

  constructor() { }

  ngOnInit(): void {
  }

}
