import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { BlogPost } from "../../entities/blogPost.entity";
import { RepositoryService } from "../../services/repository.service";
import { BlogService } from "../../services/blog/blog.service";
import { DomSanitizer } from "@angular/platform-browser";

const THUMBNAIL_DEFAULT_URL = "./assets/images/edu-logo.png";

@Component({
	selector: "app-blog-post",
	templateUrl: "./blog-post.component.html",
	styleUrls: ["./blog-post.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class BlogPostComponent implements OnInit, OnDestroy {
	activatedRouteParamsSubscription: Subscription;
	postId: number;
	post: BlogPost;
	otherPostsSectionScrollPositionReference: number;

	postThumbnail: string;
	usingThumbnailFallback: boolean;

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location, private repository: RepositoryService, private blogService: BlogService, private domSanitizer: DomSanitizer) {
		this.otherPostsSectionScrollPositionReference = 0;
		this.activatedRouteParamsSubscription = this.subscribeToActivatedRouteParams();
		this.setPostThumbnail();
	}

	ngOnInit(): void {
		this.setPostThumbnail();
	}

	ngOnDestroy() {
		this.activatedRouteParamsSubscription?.unsubscribe();
	}

	subscribeToActivatedRouteParams() {
		return this.activatedRoute?.params?.subscribe(params => {
			this.postId = params.id;
			this.loadBlogPost();
		});
	}

	async loadBlogPost() {
		if (!this.postId) {
			return;
		}

		this.post = await this.blogService.getBlogPostById(this.postId);
		if (this.post) {
			this.setPostThumbnail();
		}
	}

	setPostThumbnail() {
		this.postThumbnail = this.post?.thumbnailUrl || THUMBNAIL_DEFAULT_URL;
		this.usingThumbnailFallback = this.postThumbnail === THUMBNAIL_DEFAULT_URL;
	}

	handleThumbnailLoadingError() {
		this.postThumbnail = THUMBNAIL_DEFAULT_URL;
		this.usingThumbnailFallback = this.postThumbnail === THUMBNAIL_DEFAULT_URL;
	}
}
