<div class="relative w-max overflow-visible max-h-11">
	<button
		(click)="handleDropdownToggle()"
		class="w-full flex items-center justify-between focus:outline-none py-2 px-4 bg-edu-white border-2 border-edu-primary-green"
		[class.border-b-0]="optionsVisible"
	>
		<div class="border-2 border-edu-primary-green object-center bg-white p-2 mr-4 h-8 w-8 bg-center bg-cover inline-block bg-no-repeat" [ngStyle]="{ 'background-image': 'url(' + image + ')'}" style="border-radius: 100%;"></div>
		<span class="mr-1">{{ staticLabel ? label : selectedOption?.label }}</span>
		<img src="./assets/icons/chevron-down-black.svg" [alt]="optionsVisible ? 'Ocultar' : 'Mostrar'" [class.transform]="optionsVisible" [class.rotate-180]="optionsVisible" />
	</button>

	<ul
		id="dropdown-menu-list"
		*ngIf="options && options.length > 0 && optionsVisible"
		class="w-full border-2 border-edu-primary-green py-2 bg-edu-white"
		[class.border-t-0]="optionsVisible"
	>
		<li *ngFor="let option of options" class="block h-8 w-full border-0 edu-btn-primary ">
			<button (click)="handleOptionSelected(option)" class=" border-0 w-full hover-dropdown h-full text-left px-4" [class.text-edu-red]="option?.labelColor">{{ option.label }}</button>
		</li>
	</ul>
</div>
