import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { validateBrPhone, validateCPF, validateEmail } from "../../../../_common/validators";

@Component({
	selector: "edu-input",
	templateUrl: "./input.component.html",
	styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit, OnChanges {
	@Input() id: string = "";
	@Input() name: string = "";
	@Input() type: string = "text";
	@Input() placeholder: string = "text";
	@Input() label: string = "text";
	@Input() error: string = "";
	@Input() mask: string | string[] = "";

	@Input() value = "";
	@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

	@Output() onBlur: EventEmitter<string> = new EventEmitter<string>();

	errorMessage: string;

	showingPassword: boolean;

	constructor() {
		this.errorMessage = "";
		this.getErrors();
		this.showingPassword = false;
	}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		this.getErrors();
	}

	getErrors() {
		if (this.type === "email") {
			return this.isEmailValid();
		}

		if (this.mask === "cpf") {
			return this.isCPFValid();
		}

		if (this.mask === "phone") {
			return this.isPhoneValid();
		}

		return (this.errorMessage = this.error);
	}

	updateValue(value) {
		this.value = value;
		this.valueChange.emit(value);
	}

	handleInputBlur() {
		this.onBlur.emit(this.value);
	}

	getMask() {
		switch (this.mask) {
			case "cpf":
			case "cnpj":
				return "CPF_CNPJ";
			case "phone":
				return "(00) 00000-0000||(00) 0000-0000";
			case "date":
				return "d0/M0/0000";
			case "cep":
				return "00000-000";
			default:
				return this.mask;
		}
	}

	isPhoneValid() {
		this.errorMessage = !this.value || validateBrPhone(this.value) ? this.error : "Telefone inválido";
	}

	isEmailValid() {
		this.errorMessage = !this.value || validateEmail(this.value) ? this.error : "Email inválido";
	}

	isCPFValid() {
		this.errorMessage = !this.value || validateCPF(this.value) ? this.error : "CPF inválido";
	}
	togglePasswordVisibility() {
		this.type = this.type === "password" ? "text" : "password";
		this.showingPassword = this.type === "text";
	}
}
