import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BlogPost } from "../../../entities/blogPost.entity";
import { RepositoryService } from "../../../services/repository.service";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { BlogService } from "../../../services/blog/blog.service";
import { Subscription } from "rxjs";

@Component({
	selector: "edu-post-card-slider",
	templateUrl: "./post-card-slider.component.html",
	styleUrls: ["./post-card-slider.component.scss"],
})
export class PostCardSliderComponent implements OnInit {
	@Input() title: string;
	@Input() description: string;
	@Input() showEmptyList: boolean;
	@Input() filterPostId: string;

	otherPosts: BlogPost[];
	otherPostsSubscription: Subscription;
	otherPostsSectionScrollPositionReference: number;

	constructor(private repository: RepositoryService, private blogService: BlogService) {
		this.otherPostsSectionScrollPositionReference = 0;
		this.otherPosts = [];
	}


	ngOnInit(): void {
		this.otherPostsSubscription = this.subscribeToBlogPosts();
	}

	ngOnDestroy() {
		this.otherPostsSubscription?.unsubscribe();
	}

	subscribeToBlogPosts() {
		return this.repository.blogPosts.subscribe(async blogPosts => {
			if (!blogPosts?.length) {
				return this.loadBlogPosts();
			}

			this.otherPosts = [...blogPosts].filter(blogPost => blogPost);
		});
	}

	async loadBlogPosts() {
		const qb = RequestQueryBuilder.create();
		let queryBuilder;
		if (this.filterPostId) {
			queryBuilder = qb.setJoin({ field: "category" }).setFilter({ field: "id", operator: "$ne", value: this.filterPostId }).sortBy({ field: "createdDate", order: "DESC" }).setLimit(10).setPage(1).resetCache();
		} else {
			queryBuilder = qb.setJoin({ field: "category" }).sortBy({ field: "createdDate", order: "DESC" }).setLimit(10).setPage(1).resetCache();
		}
		const blogListResponse = await this.blogService.getBlogPosts(queryBuilder?.query());
		this.otherPosts = this.filterPostId ? [...blogListResponse?.data] : [...blogListResponse?.data].slice(0, 5);
	}


	handleScroll(intent: "left" | "right") {
		const blogPostsNodeReference = document.querySelector("div .blog-posts");
		blogPostsNodeReference.scrollLeft = intent === "left" ? this.otherPostsSectionScrollPositionReference - 200 : (blogPostsNodeReference.scrollLeft = this.otherPostsSectionScrollPositionReference + 200);

		if (intent === "left") {
			this.otherPostsSectionScrollPositionReference = blogPostsNodeReference.scrollLeft === 0 ? 0 : blogPostsNodeReference.scrollLeft;
		} else {
			this.otherPostsSectionScrollPositionReference = blogPostsNodeReference.scrollLeft;
		}
	}

}
