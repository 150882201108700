import { Injectable } from '@angular/core';
import { Occupation } from 'src/app/entities/user.entity';

export const featurePermission = {
	trail: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER, Occupation.PARENT ],
	home: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER, Occupation.PARENT, Occupation.STUDENT ],
	academy: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER ],
	parental: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER, Occupation.PARENT ],
	marketing: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER ],
	manual: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER ],
	teachersPanel: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER ],
	mySchool: [ Occupation.SCHOOL_LEADER ],
	student: [ Occupation.STUDENT ],
	digitalLiteracy: [ Occupation.SCHOOL_LEADER, Occupation.TEACHER, Occupation.STUDENT ]
}

@Injectable({
  providedIn: 'root'
})

export class PermissionService {

  checkPermission(user, feature):boolean {
		const occupation = user.occupation;
		return feature.includes(occupation);
  }
}
