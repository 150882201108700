import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'edu-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() label;
  @Input() labelPosition: 'left' | 'right';
  @Input() name;

  @Input() checkboxValue;
  @Input() checked: boolean;

  @Input() value;
  @Output() valueChange: EventEmitter<string>;

  constructor() {
    this.label = ''
    this.labelPosition = 'right'
    this.name = ''

    this.checkboxValue = ""

    this.value = "";
    this.valueChange = new EventEmitter<string>();
  }

  ngOnInit(): void {
  }

  handleRadioChange(event, checkbox: HTMLInputElement) {
    this.checked = checkbox?.checked;
    this.valueChange.emit(event?.target?.checked);
  }

}
