<div class="header-wrapper w-full fixed bg-edu-white top-0 left-1/2 z-40">
	<header class="flex items-center flex-col lg:flex-row lg:justify-between w-full max-w-screen-2xl px-2 sm:px-10 pt-5 sm:py-4 bg-edu-white z-40 mx-auto">
		<!-- large screens -->
		<img routerLink="/" class="lg:pl-10 w-48 hidden cursor-pointer lg:block outline-none" src="./assets/images/edu-logo-slogan.png" alt="Educa21 - Inspirando mentes conectadas." />

		<!-- mobile screens -->
		<div class="flex items-center justify-between lg:hidden w-full pb-5 px-5">
			<img routerLink="/" class="w-36 cursor-pointer outline-none" src="./assets/images/edu-logo-slogan.png" alt="Educa21 - Inspirando mentes conectadas." />
			<img class="cursor-pointer" alt="Menu" [src]="mobileMenuOpen ? './assets/icons/close.svg' : './assets/icons/menu.svg'" (click)="toggleMobileMenu()"/>
		</div>

		<!-- menu for larger screens -->
		<nav class="items-center hidden md:hidden lg:flex">
			<ul class="flex items-center">
				<li><a class="link" routerLink="/educa" [class.active]="location.path().includes('/educa')">Educa</a></li>
				<li><a class="link" routerLink="/programa-socioemocional" [class.active]="location.path().includes('/programa-socioemocional')">Programa Socioemocional</a></li>
				<li><a class="link" routerLink="/blog" [class.active]="location.path().includes('/blog')">Blog</a></li>
				<!-- <li><a class="link" routerLink="/recursos-gratuitos" [class.active]="location.path().includes('/recursos-gratuitos')">Recursos gratuitos</a></li> -->
				<li><a class="link" routerLink="/imprensa" [class.active]="location.path().includes('/imprensa')">Imprensa</a></li>
				<li><a class="link" routerLink="/fale-conosco" [class.active]="location.path().includes('/fale-conosco')">Fale conosco</a></li>
			</ul>
			<edu-button variant="secondary" routerLink="/login" *ngIf="!getLoggedUser().isAuthenticated">Entrar</edu-button>
			<!-- todo mudar label com nome do usuário logado -->
			<edu-dropdown [options]="dropdownMenuOptions" [staticLabel]="true" [label]="getLoggedUser().name" (selected)="handleOptionSelected($event)" [image]="getLoggedUser().user?.profilePictureUrl" *ngIf="getLoggedUser().isAuthenticated"></edu-dropdown>
		</nav>

		<!-- menu for mobile -->
		<nav *ngIf="mobileMenuOpen" class="flex flex-col items-center lg:hidden pb-7 w-full max-w-sm">
			<ul class="flex flex-col items-center w-full">
				<li class="mb-5"><a class="link" (click)="goToRoute('educa')" [class.active]="location.path().includes('/educa')">Educa</a></li>
				<li class="mb-5"><a class="link" (click)="goToRoute('programa-socioemocional')" [class.active]="location.path().includes('/programa-socioemocional')">Programa Socioemocional</a></li>
				<li class="mb-5"><a class="link" (click)="goToRoute('/blog')"  [class.active]="location.path().includes('/blog')">Blog</a></li>
				<!-- <li class="mb-5"><a class="link" (click)="goToRoute('recursos-gratuitos')" [class.active]="location.path().includes('/recursos-gratuitos')">Recursos gratuitos</a></li> -->
				<li class="mb-5"><a class="link" (click)="goToRoute('imprensa')" [class.active]="location.path().includes('/imprensa')">Imprensa</a></li>
				<li><a class="link" (click)="goToRoute('fale-conosco')" [class.active]="location.path().includes('/fale-conosco')">Fale conosco</a></li>
			</ul>
			<edu-button variant="secondary" [isFullWidth]="true" class="w-full" routerLink="/login" *ngIf="!getLoggedUser().isAuthenticated" (click)="toggleMobileMenu()">Entrar</edu-button>
			<edu-dropdown [options]="dropdownMenuOptions" [staticLabel]="true" [label]="getLoggedUser().name" (selected)="handleOptionSelected($event)" [image]="getLoggedUser().user?.profilePictureUrl" *ngIf="getLoggedUser().isAuthenticated"></edu-dropdown>
		</nav>
	</header>
</div>
