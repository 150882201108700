import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { translateOccupation } from 'src/app/entities/user.entity';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor(private authService: AuthService) {
	}

  ngOnInit(): void {
  }

	getUserOccupation() {
		return this.authService.getTranslatedUserOccupation().toUpperCase();
	}


}
