<div class="w-full h-screen flex items-center justify-center" *ngIf="!educationUnits">
	<div class="flex flex-col items-center">
		<img src="./assets/icons/logo-primary-green.png" alt="Carregando" class="h-23 w-40"/>
		<span class="title text-edu-primary-green">Aguarde..</span>
	</div>
</div>
<main class="w-full flex flex-col min-h-screen" *ngIf="educationUnits">
	<edu-back-button></edu-back-button>
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex items-center w-full max-w-19xl ml-20">
			<h2 class="section-title max-w-max w-2/3" id="educa-press">{{ educationUnits[0].educationLevel?.name }}</h2>
			<div class="border-t-2 border-edu-primary-yellow w-full max-w-1/2 ml-10"></div>
		</div>
	</div>
	<div class="press-section w-full max-w-13xl mx-auto grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5 px-2 sm:px-20 pb-32">
		<div class="flex flex-col justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1" *ngFor="let pdf of educationUnits">
			<div class="md:px-5 md:pt-5 h-60 md:w-96">
				<a [href]="pdf.pdfUrl" target="_blank">
					<img [alt]="pdf.name" [src]="pdf.imageUrl" class="mr-2.5 shadow-xl md:w-96 h-60" />
					<edu-button colorClass="edu-btn-yellow">Acesse a {{ pdf.name }}</edu-button>
				</a>
			</div>
		</div>
	</div>
</main>
