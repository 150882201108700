<main class="w-full flex flex-col min-h-screen">
	<edu-back-button></edu-back-button>
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex items-center w-full max-w-19xl ml-20">
			<h2 class="section-title w-full max-w-max" id="educa-press">CADERNO DO PROFESSOR</h2>
			<div class="border-t-2 border-edu-primary-yellow w-full max-w-1/2 ml-10"></div>
		</div>
	</div>

	<div class="w-full max-w-13xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-10 mt-12" id="staff">
		<div class="flex flex-col items-center col-span-1" *ngFor="let stage of stages">
			<edu-notebook-card markerColor="yellow-marker" [stage]="stage"></edu-notebook-card>
		</div>
	</div>
</main>
