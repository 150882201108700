<div class="w-full h-screen flex items-center justify-center" *ngIf="!videoClass">
	<div class="flex flex-col items-center">
		<img src="./assets/icons/logo-primary-green.png" alt="Carregando" class="h-23 w-40" >
		<span class="title text-edu-primary-green">Aguarde..</span>
	</div>
</div>
<main class="w-full flex flex-col lg:min-h-screen" *ngIf="videoClass">
	<div class="flex justify-start">
		<edu-back-button></edu-back-button>
	</div>
	<div class="first-section md:w-full flex h-auto mb-12 md:pl-8">
		<div class="grid grid-cols-1 lg:grid-cols-2 flex-col items-start w-full max-w-screen-2xl mx-auto md:h-3/5 px-0 md:px-10 xl:mt-10 2xl:mt-auto lg:gap-x-28 xl:my-auto">
			<div class="flex-col flex p-5 flex-wrap w-auto h-auto md:flex-nowrap items-center justify-center md:justify-start md:items-start lg:mr-5">
				<iframe class="w-full md:h-80 md:w-110 lg:h-96 lg:w-120 2xl:h-80 2xl:w-120" [src]="getLink()" allowfullscreen></iframe>
			</div>
			<div class="w-full flex flex-col lg:pl-6 ">
				<div class="w-72 lg:w-auto mt-10 md:mt-0 md:w-98 mb-2 md:mb-5 mx-8 px-4 md:pl-4 ml-3 md:ml-0">
					<h1 class="title w-full leading-tight break-words md:break-normal">{{ videoClass.title }}</h1>
					<p class="subtitle w-full mt-2 text-sm mb-2 md:mb-1 md:text-sm font-bold">Tempo de aula: {{ videoClass.duration }} min</p>
					<p class="subtitle w-full mt-2 text-sm mb-2 md:mb-1 md:text-sm font-bold">{{ videoClass.subtitle }}</p>
				</div>
				<p class="body mb-7 w-80 px-4 mt-1 text-sm pb-2 md:pb-0 md:text-sm md:w-110 2xl:w-120">{{ videoClass.description }}</p>
			</div>
		</div>
	</div>
	<div class="first-section md:w-full flex ml-10 h-full md:h-full mb-12 md:pl-8 xl:h-110">
		<div class="grid grid-cols-1 mt-0 flex-col items-start w-full max-w-screen-2xl mx-auto md:h-3/5 px-0 md:px-10">
			<h1 class="title-sm w-80 leading-tight break-words md:break-normal md:w-98">COM QUEM VOCÊ IRÁ APRENDER</h1>
			<div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-x-0 gap-y-5 w-full">
				<div class="shadow-xl bg-edu-white flex flex-col h-full w-52 md:justify-start text-center mx-0 xl:mx-0 py-5 col-span-1" *ngFor="let teacher of videoClass.teachers">
					<img [src]="teacher.imageUrl" class="w-32 self-center" alt="">
					<p class="secondary-title-sm mt-2 mb-2 md:mb-1 md:text-xl font-bold">{{ teacher.name }}</p>
					<p class="subtitle mt-2 mb-2 md:mb-1 md:text-sm font-bold">{{ teacher.about }}</p>
					<div class="flex items-center justify-center mt-5">
						<a *ngIf="teacher.instagram" [href]="teacher.instagram" rel="noreferrer noopener" target="_blank" class="mr-5"><img src="./assets/icons/instagram-sm.svg" class="w-8" /></a>
						<a *ngIf="teacher.linkedin" [href]="teacher.linkedin" rel="noreferrer noopener" target="_blank" class="mr-5 "><img src="./assets/icons/linkedin-sm.svg" class="w-8"  /></a>
						<a *ngIf="teacher.youtube" [href]="teacher.youtube" rel="noreferrer noopener" target="_blank" class="mr-5"><img src="./assets/icons/youtube-icon.svg" class="w-8" /></a>
						<a *ngIf="teacher.site" [href]="teacher.site" rel="noreferrer noopener" target="_blank" class="mr-5"><img src="./assets/icons/green-globe.svg" class="w-8" /></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>

