<!-- todo remover essa classe min-h-screen se necessário -->
<main class="w-full flex flex-col min-h-screen items-center">
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex justify-start items-end w-full md:max-w-13xl md:mx-auto md:ml-8">
			<div class="flex flex-col items-start md:hidden">
				<p class="title mb-1 marker yellow">A Educa acredita no poder das</p>
				<p class="title mb-1 marker yellow">habilidades socioemocionais</p>
				<p class="title mb-1 marker yellow">para a construção de um mundo</p>
				<p class="title mb-1 marker yellow pr-10">mais humanizado.</p>
			</div>
			<div class="flex flex-col items-start hidden md:flex pl-10 leading-tight">
				<p class="title mb-1 marker yellow">A Educa acredita</p>
				<p class="title mb-1 marker yellow">no poder das competências </p>
				<p class="title mb-1 marker yellow">socioemocionais para a</p>
				<p class="title mb-1 marker yellow">construção de um mundo</p>
				<p class="title mb-1 marker yellow pr-10">mais humanizado.</p>
			</div>

			<img src="./assets/icons/chevron-down-outline.svg" alt="↓" class="w-7 h-7 md:w-10 md:h-10 relative -left-36 md:-left-28" />
		</div>
	</div>

	<div class="flex flex-wrap md:flex-nowrap items-center justify-center md:justify-between mt-5 md:mt-10 w-full ml-auto px-5 md:px-2">
		<h2 class="section-title w-100 md:w-110 lg:ml-24 leading-tight">OI, NÓS SOMOS A EDUCA!</h2>
		<div class="border-2 border-edu-primary-green w-100 md:w-3/5 my-5 md:my-0"></div>
	</div>

	<div class="flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between mt-1 lg:ml-24">
		<p class="body w-full lg:max-w-1/2 md:w-120 px-5 md:px-2 md:pr-2.5">Apoiamos escolas, alunos e professores na promoção da equidade e excelência na educação brasileira por meio do desenvolvimento socioemocional. Inspiramos educadores, pensadores e psicólogos para garantir que as habilidades humanas sejam uma prioridade em nosso país.</p>
		<img alt="Mãe e filha com lanterna" src="./assets/images/mom-daughter@2x.png" class="h-28 md:h-40 w-full object-cover lg:w-6/12 lg:max-w-1/2 my-5 lg:my-0"/>
	</div>

	<div class="flex flex-wrap-reverse lg:flex-nowrap items-center justify-center lg:justify-between mt-1 md:mt-8">
		<img alt="Crianças brincando" src="./assets/images/kids-playing@2x.png" class="h-28 md:h-40 w-full object-cover lg:w-6/12 lg:max-w-1/2 my-5 lg:my-0"/>
		<p class="body w-full lg:max-w-1/2 md:w-120 px-5 md:px-2 md:pl-2.5 md:ml-5 ">Estabilidade emocional, autonomia, resiliência e solidariedade são conceitos que devem estar cada vez mais ligados à sala de aula. Trabalhar essas habilidades ajuda os jovens a conhecer os próprios limites e os dos outros, os auxilia a tomar decisões conscientes, a lidar com desafios e a resolver problemas complexos da vida real.</p>
	</div>

	<div class="flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between mt-1 md:mt-8 lg:ml-24">
		<p class="body w-full lg:max-w-1/2 md:w-120 px-5 md:px-2 md:pr-2.5">Dinâmicas de socialização, projetos, jogos e criação de espaços de debate estão envolvidos na proposta de mediar conflitos, desenvolvendo a liderança, estimulando a empatia na relação entre os pares e capacitando crianças e jovens para serem a mudança positiva para o mundo no século 21.</p>
		<img alt="Crianças estudando" src="./assets/images/kids-science@2X.png" class="h-28 md:h-40 w-full object-cover lg:w-6/12 lg:max-w-1/2 my-5 lg:my-0"/>
	</div>

	<div class="flex flex-wrap-reverse lg:flex-nowrap items-center justify-center lg:justify-between mt-1 md:mt-8">
		<img alt="Crianças jogando basquete" src="./assets/images/basketball@2x.png" class="h-28 md:h-40 w-full object-cover lg:w-6/12 lg:max-w-1/2 my-5 lg:my-0"/>
		<p class="body w-full lg:max-w-1/2 md:w-120 px-5 md:px-2 md:pl-2.5 md:ml-5">Este é o plano da Educa: trazemos as melhores experiências de educação socioemocional do mundo para que professores, alunos e famílias possam vivenciá-las na escola ou em casa.</p>
	</div>


	<div class="flex flex-col items-center justify-center lg:flex-row w-full max-w-13xl mx-auto px-6 sm:px-20 mt-20 mb-4 sm:mb-0">
		<div class="flex flex-col items-center justify-start w-full sm:w-1/3 lex-1 mx-2 my-5 md:my-0 md:h-80 md:px-2">
			<img class="w-24" src="./assets/icons/target.svg" />
			<p class="title text-center">NOSSO PROPÓSITO</p>
			<p class="body text-center">Ajudar escolas e famílias a educar uma geração mais humanizada.</p>
		</div>

		<div class="flex flex-col items-center justify-start w-full sm:w-1/3 flex-1 mx-2 my-5 md:my-0 md:h-80 md:px-2">
			<img class="w-24" src="./assets/icons/pyramid.svg" />
			<p class="title text-center">NOSSA MISSÃO</p>
			<p class="body text-center">Conectar a comunidade escolar e proporcionar experiências educacionais que preparem as novas gerações para viver melhor no século 21.</p>
		</div>

		<div class="flex flex-col items-center justify-start w-full sm:w-1/3 flex-1 mx-2 my-5 md:my-0 md:h-80 md:px-2">
			<img class="w-24" src="./assets/icons/aim.svg" />
			<p class="title text-center">NOSSO FOCO</p>
			<p class="body text-center">Conectar gerações, promovendo o progresso das pessoas por meio das competências socioemocionais.</p>
		</div>
	</div>

	<div class="founders flex flex-col w-full max-w-13xl mx-auto mb-2 md:mb-10 px-6 sm:px-20">
		<h2 class="section-title text-left mb-5 md:mb-20 px-5 md:px-0">FUNDADORES</h2>

		<edu-founder-card [founder]="rossandro"></edu-founder-card>
		<br /><br /><br /><br />
		<edu-founder-card [founder]="jaime"></edu-founder-card>
	</div>

	<div class="w-full flex items-center justify-center my-6 md:my-16">
		<edu-button variant="tertiary" routerLink="/imprensa" fragment="staff">Conheça toda a equipe!</edu-button>
	</div>

	<div class="flex flex-col w-full max-w-13xl mx-auto px-6 sm:px-20" >
		<div class="gray-waves w-full h-20 md:w-1/2 md:h-40 mx-auto -mb-12 md:-mb-60 z-10"></div>
		<div class="w-full max-w-13xl mx-auto flex flex-wrap md:flex-nowrap items-center md:mb-36 md:pt-36 mt-20 md:mt-0 px-5">
			<div class="flex flex-col items-center md:items-left border-t-4 border-edu-primary-green w-100 md:w-1/2 h-3/5 md:pr-5 py-2 md:py-0">

				<p class="subtitle-sm mt-2 md:mt-8">Com a Educa por Rossandro Klinjey as escolas desenvolvem as competências socioemocionais para todas as pessoas envolvidas no ecossistemas educacional: alunos, pais e responsáveis, educadores e líderes escolares.</p>
				<p class="body mt-2 pr-5">Conte-nos mais sobre você e vamos descobrir como a Educa pode transformar sua escola ou família.</p>
			</div>

			<img class="w-full md:w-1/2 m-auto object-cover md:object-contain" alt="Crianças segurando medalha" src="./assets/images/kids-medal@2x.png" />
		</div>
	</div>

	<div class=" w-full mx-auto mb-12 mt-10 md:mt-0 px-6 md:px-0">
		<div class="mb-20">
			<p class="title marker yellow text-center w-max mx-auto mb-1">Vamos falar sobre a transformação</p>
			<p class="title marker yellow text-center w-max mx-auto mb-1">que a Educa pode gerar</p>
			<p class="title marker yellow text-center w-max mx-auto mb-1">na sua escola ou família.</p>
		</div>
		<div class="about-educa-form">
			<edu-marketing-form></edu-marketing-form>
		</div>

	</div>

</main>

