<div class="w-full h-screen flex items-center justify-center" *ngIf="!post">
	<img src="./assets/icons/feeling.png" alt="Carregando" class="h-40 w-40" >
</div>

<main class="w-full flex flex-col items-center mt-7" *ngIf="post">
	<div class="flex flex-col items-start justify-between w-full max-w-3xl mb-5 px-7 2xl:px-0">
		<div class="flex items-center mb-2.5">
			<a routerLink="/blog" class="font-dinosaur cursor-pointer text-edu-black">Blog</a>
			<img alt="Seta" src="./assets/icons/chevron-right-green.svg" class="h-4 w-4 mx-1.5" *ngIf="post?.category?.id" />
			<a routerLink="/blog" [queryParams]="{ categoria: post?.category?.name }" class="font-dinosaur cursor-pointer text-edu-dark-grey hover:text-edu-black" *ngIf="post?.category?.id">{{ post?.category?.name }}</a>
		</div>
		<h1 class="title leading-tight text-edu-black text-left w-full max-w-3xl">{{ post?.title }}</h1>
		<p class="body text-edu-dark-grey" *ngIf="post?.readingTime">Tempo de leitura: {{ post?.readingTime }}</p>
	</div>
	<article class="w-full max-w-3xl flex flex-col px-7 2xl:px-0">
		<edu-post-author class="mb-10" [post]="post"></edu-post-author>

		<img
			*ngIf="!usingThumbnailFallback"
			alt="Thumbnail do post"
			class="h-100 w-full mb-10"
			[src]="postThumbnail"
			(error)="handleThumbnailLoadingError()"
			[class.object-cover]="!usingThumbnailFallback"
			[class.object-contain]="usingThumbnailFallback"
		/>

		<div class="post-content" [innerHTML]="post?.content | safeHtml">
		</div>

		<img class="mt-10 w-full max-w-2xl mx-auto mb-10" alt="Ondas decorativas" src="./assets/images/edu-gray-waves.svg" />

		<div class="w-full mt-28 mb-20 md:mb-60 flex flex-col items-center mx-auto">
			<h2 class="title text-edu-black mb-5">Acompanhe as novidades da Educa</h2>
			<p class="body text-edu-black mb-5 px-5">Mais conteúdos como esses direto no seu e-mail. Assine nossa news!</p>
			<edu-marketing-form [isNewsletterForm]="true" [withMessageBox]="false" class="mx-auto w-full"></edu-marketing-form>
		</div>
	</article>

	<section class="w-full max-w-13xl mb-40">
		<edu-post-card-slider [title]="'OUTROS ARTIGOS'" [showEmptyList]="true" [filterPostId]="postId"></edu-post-card-slider>
	</section>
</main>
