import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teachers-notebook',
  templateUrl: './teachers-notebook.component.html',
  styleUrls: ['./teachers-notebook.component.scss']
})
export class TeachersNotebookComponent implements OnInit {
	public stages = [
		{ name: "Educação Infantil", icon:"../../assets/icons/EI.svg", link:"/infantil/unidades/13" },
		{ name: "Ensino Fundamental Anos Iniciais", icon:"../../assets/icons/EFI.svg", link:"/anos-iniciais" },
		{ name: "Ensino Fundamental Anos Finais", icon:"../../assets/icons/EFII.svg", link:"/anos-finais" },
		{ name: "Ensino Médio", icon:"../../assets/icons/EM.svg", link:"/ensino-medio"},
	]

  constructor() { }

  ngOnInit(): void {
  }

}
