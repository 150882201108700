import { Component, OnInit } from "@angular/core";

export interface PDF {
	alt: string;
	icon: string;
	link: string;
}

@Component({
	selector: "app-early-elementary-plan",
	templateUrl: "./early-elementary-plan.component.html",
	styleUrls: ["./early-elementary-plan.component.scss"],
})
export class EarlyElementaryPlanComponent implements OnInit {
	public pdfs: PDF[] = [
		{ alt: "1º Ano", icon: "../../assets/images/livros/1_ano/1_ano_1.jpg", link: "https://drive.google.com/file/d/1cFSjsl9VTI2mxoN8P-REyoeRrRiPGj-N/view?usp=sharing" },
		{ alt: "2º Ano", icon: "../../assets/images/livros/2_ano/2_ano_1.jpg", link: "https://drive.google.com/file/d/1FzRTOoMiLUI3ixmbuQdYuNlaSC53QhlD/view?usp=sharing" },
		{ alt: "3º Ano", icon: "../../assets/images/livros/3_ano/3_ano_1.jpg", link: "https://drive.google.com/file/d/1YHEHDDIIz2cwNTrFMH8zhGEl9QtDwTyr/view?usp=sharing" },
		{ alt: "4º Ano", icon: "../../assets/images/livros/4_ano/4_ano_1.jpg", link: "https://drive.google.com/file/d/1ZxWiQ90mTE8O5wespfGQRTQvGhyUfrO2/view?usp=sharing" },
		{ alt: "5º Ano", icon: "../../assets/images/livros/5_ano/5_ano_1.jpg", link: "https://drive.google.com/file/d/1MzB4RnsqJoWXsR5SQJ-F2onzoN_OYoSd/view?usp=sharing" },
	];

	constructor() {}

	ngOnInit(): void {}
}
