<footer class="flex flex-col items-center bg-edu-primary-green w-full pt-16 pb-7 px-10">
	<div class="w-full max-w-screen-2xl md:grid md:grid-flow-col md:auto-cols-auto md:gap-y-0 flex flex-col mb-5 px-5 md:px-0">
		<div></div>

		<div class="flex flex-col md:flex-row items-center mt-10 md:mt-0 w-full">
			<div class="flex flex-col items-center md:items-start justify-start px-2.5 h-full w-full">
				<a class="text-edu-black text-sm cursor-pointer hover:opacity-50 mb-2.5" routerLink="/educa">Educa</a>
				<a class="text-edu-black text-sm cursor-pointer hover:opacity-50 mb-2.5" routerLink="/programa-socioemocional">Programa Socioemocional</a>
				<!-- <a class="text-edu-black text-sm cursor-pointer hover:opacity-50 mb-2.5" routerLink="/recursos-gratuitos">Recursos gratuitos</a> -->
				<a class="text-edu-black text-sm cursor-pointer hover:opacity-50 mb-2.5" routerLink="/blog">Blog</a>
			</div>

			<div class="flex flex-col items-center md:items-start justify-start px-2.5 h-full w-full">
				<a class="text-edu-black text-sm cursor-pointer hover:opacity-50 mb-2.5" routerLink="/imprensa">Imprensa</a>
				<a class="text-edu-black text-sm cursor-pointer hover:opacity-50 mb-2.5" routerLink="/fale-conosco">Fale conosco</a>
			</div>
		</div>

		<div class="mt-10 mb-5 md:mb-0 md:mt-0 w-full">
			<div class="flex flex-col items-center md:items-start justify-start px-2.5 h-full">
				<p class="text-edu-black text-sm mb-2.5">Rua Harmonia, 1250 - Vila Madalena, São Paulo - SP</p>
				<p class="text-edu-black text-sm mb-2.5">05435-001</p>
				<a rel="noreferrer" target="_blank" href="https://wa.me/5511932667774">
					<p class="text-edu-black text-sm mb-2.5">(11) 93266-7774</p>
				</a>
				<a class="noreferrer" target="_blank" href="mailto:atendimento@educa21.com.br">
					<p class="text-edu-black text-sm mb-2.5">Quero enviar um e-mail para a Educa</p>
				</a>
			</div>
		</div>

		<!-- social medias -->
		<div class="flex items-center justify-center">
			<a class="cursor-pointer px-2.5" href="https://www.instagram.com/educa.rossandroklinjey" rel="noreferrer" target="_blank"><img alt="Instagram" src="./assets/icons/instagram.svg" /></a>
			<a class="cursor-pointer px-2.5" href="https://www.facebook.com/Educa21-105641994918548" rel="noreferrer" target="_blank"><img alt="Facebook" src="./assets/icons/facebook.svg" /></a>
			<a class="cursor-pointer px-2.5" href="https://www.linkedin.com/company/educa-rossandroklinjey/mycompany/?viewAsMember=true" rel="noreferrer" target="_blank"><img alt="LinkedIn" src="./assets/icons/linkedin.svg" /></a>
			<a class="cursor-pointer px-2.5" rel="noreferrer" target="_blank" href="https://wa.me/5511932667774">
				<img alt="Whatsapp" src="./assets/icons/whatsapp.svg" />
			</a>
			<!--      <a class="cursor-pointer px-2.5"><img alt="Tiktok" src="./assets/icons/tiktok.svg" /></a>-->
		</div>
	</div>

	<div class="flex items-center justify-center w-full max-w-1/2">
		<span class="text-sm font-dinosaur text-edu-black opacity-50"><a class="text-edu-black text-sm cursor-pointer hover:opacity-50 mb-2.5" routerLink="/central-de-privacidade">Central de Privacidade</a></span>
		<span class="text-sm font-dinosaur text-edu-black opacity-50 ml-10">Educa© | Todos os direitos reservados</span>
	</div>
</footer>
