import { Component, OnInit } from "@angular/core";

export interface PDF {
	alt: string;
	icon: string;
	link: string;
}

@Component({
	selector: "app-elementary-early",
	templateUrl: "./elementary-early.component.html",
	styleUrls: ["./elementary-early.component.scss"],
})
export class ElementaryEarlyComponent implements OnInit {
	public pdfs: PDF[] = [
		{ alt: "1º Ano", icon: "../../assets/images/livros/1_ano/1_ano_1.jpg", link: "/fundamental/unidades/1" },
		{ alt: "2º Ano", icon: "../../assets/images/livros/2_ano/2_ano_1.jpg", link: "/fundamental/unidades/2" },
		{ alt: "3º Ano", icon: "../../assets/images/livros/3_ano/3_ano_1.jpg", link: "/fundamental/unidades/3" },
		{ alt: "4º Ano", icon: "../../assets/images/livros/4_ano/4_ano_1.jpg", link: "/fundamental/unidades/4" },
		{ alt: "5º Ano", icon: "../../assets/images/livros/5_ano/5_ano_1.jpg", link: "/fundamental/unidades/5" },
	];

	constructor() {}

	ngOnInit(): void {}
}
