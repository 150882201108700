<main class="w-full flex flex-col min-h-screen">
	<edu-back-button></edu-back-button>
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0 mb-14">
		<div class="flex items-center w-full max-w-19xl ml-20">
			<h2 class="section-title w-full max-w-max" id="educa-press">FUNDAMENTAÇÃO PEDAGÓGICA</h2>
			<div class="border-t-2 border-edu-primary-yellow w-full max-w-1/2 ml-10"></div>
		</div>
	</div>

	<div class="w-full max-w-14xl mx-auto px-6 md:px-20 grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-10 mb-10">
		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="../../../../assets/images/kids-playing-pointing@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10 mb-5 relative">
				<p class="title marker green text-left mb-1">Felicidade e</p>
				<p class="title marker green text-left">Bem-Estar</p>
				<p class="body text-left mt-2.5">Por meio do autoconhecimento desenvolvemos a autonomia, a prática do autocuidado e a gratidão. Assim, inspiramos os alunos a encontrar um caminho para a felicidade.</p>
			</div>
			<a href="/em-breve" class="mt-auto ml-5 mb-5">
				<edu-button colorClass="edu-btn-yellow">Assista</edu-button>
			</a>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="../../../../assets/images/kid-zoom@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10 mb-5 relative">
				<p class="title marker orange text-left mb-1">Sabedoria</p>
				<p class="body text-left mt-2.5">As ideias criativas se juntam com a curiosidade são capazes de realizações incríveis. Contudo, com o desenvolvimento do pensamento crítico e da responsabilidade, esse potencial é transformador.</p>
			</div>
			<a href="/em-breve" class="mt-auto ml-5 mb-5">
				<edu-button colorClass="edu-btn-yellow">Assista</edu-button>
			</a>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="../../../../assets/images/grandma-kissing@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10 mb-5 relative">
				<p class="title marker yellow text-left mb-1">Humanidade</p>
				<p class="body text-left mt-2.5">Valores cada vez mais necessários na sociedade, tais como a generosidade, amor, esperança, compaixão e a prática da empatia nas relações. Através dessas habilidades, construiremos um mundo mais humanizado.</p>
			</div>
			<a href="/em-breve" class="mt-auto ml-5 mb-5">
				<edu-button colorClass="edu-btn-yellow">Assista</edu-button>
			</a>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="../../../../assets/images/kid-telescope@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10 mb-5 relative">
				<p class="title marker red text-left text-edu-white mb-1">Liderança</p>
				<p class="body text-left mt-2.5">As competências do século 21 se constituem por meio da colaboração, tomada de decisões, negociação e trabalho em equipe. Desenvolvemos a noção de justiça, a capacidade de resolver problemas complexos e a autoliderança.</p>
			</div>
			<a href="/em-breve" class="mt-auto ml-5 mb-5">
				<edu-button colorClass="edu-btn-yellow">Assista</edu-button>
			</a>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="../../../../assets/images/pointing-clock@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10 mb-5 relative">
				<p class="title marker purple text-left text-edu-white mb-1">Moderação</p>
				<p class="body text-left mt-2.5">Desenvolver o equilíbrio permite que práticas como o perdão, a humildade, a prudência e o autocontrole se tornem hábitos. Neste aspecto, a comunicação assertiva, a resiliência emocional e a paciência são ferramentas necessárias para o aprendizado da moderação.</p>
			</div>
			<a href="/em-breve" class="mt-auto ml-5 mb-5">
				<edu-button colorClass="edu-btn-yellow">Assista</edu-button>
			</a>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="../../../../assets/images/washing-hands@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10 mb-5 relative">
				<p class="title marker blue text-left text-edu-white mb-1">Cidadania</p>
				<p class="body text-left mt-2.5">Somos todos parte da sociedade, logo agentes transformadores do mundo. Para isso, desenvolvemos a consciência e o protagonismo ambiental, a integridade, perseverança e o civismo. Com bravura e vitalidade seremos melhores pessoas para o planeta!</p>
			</div>
			<a href="/em-breve" class="mt-auto ml-5 mb-5">
				<edu-button colorClass="edu-btn-yellow">Assista</edu-button>
			</a>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="../../../../assets/images/girl-smilling-watching.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10 mb-5 relative">
				<p class="title marker orange text-left text-edu-black mb-1">Letramento Digital</p>
				<p class="body text-left mt-2.5">O aluno aprenderá a usar a internet de uma forma mais segura e consciente. Jogos, sites e o contato com  pessoas no ambiente digital podem ser divertidos,  em especial se usado com sabedoria. Com  as aulas do  Letramento Digital da Educa o aluno aproveitará o ambiente digital de forma crítica e positiva.</p>
			</div>
			<a href="/em-breve" class="mt-auto ml-5 mb-5">
				<edu-button colorClass="edu-btn-yellow">Assista</edu-button>
			</a>
		</div>
	</div>
</main>
