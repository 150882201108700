import {Component, Input, OnInit} from '@angular/core';

export type ButtonType = 'primary' | 'secondary' | 'tertiary'

@Component({
  selector: 'edu-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() variant: ButtonType = "primary"
	@Input() colorClass = "edu-btn-green";
  @Input() isFullWidth = false;
  @Input() type: string;

  @Input() loading: boolean;

  constructor() {
  	this.type = 'button';
  	this.loading = false;
	}

  ngOnInit(): void {
  }

}
