import {Component, Input, OnInit} from '@angular/core';

export interface TestimonialCardContent {
	name: string;
	content: string;
	description: string;
}

@Component({
  selector: 'edu-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.scss']
})
export class TestimonialCardComponent implements OnInit {
	@Input() content: string;
	@Input() name: string;
	@Input() description: string;

  constructor() { }

  ngOnInit(): void {
  }

}
