import { Component, OnInit } from "@angular/core";

export interface PDF {
	alt: string;
	icon: string;
	link: string;
}

@Component({
	selector: "app-elementary-end",
	templateUrl: "./elementary-end.component.html",
	styleUrls: ["./elementary-end.component.scss"],
})
export class ElementaryEndComponent implements OnInit {
	public pdfs: PDF[] = [
		{ alt: "6º Ano", icon: "../../assets/images/livros/6_ano/6_ano.jpg", link: "/fundamental/unidades/6" },
		{ alt: "7º Ano", icon: "../../assets/images/livros/7_ano/7_ano.jpg", link: "/fundamental/unidades/7" },
		{ alt: "8º Ano", icon: "../../assets/images/livros/8_ano/8_ano.jpg", link: "/fundamental/unidades/8" },
		{ alt: "9º Ano", icon: "../../assets/images/livros/9_ano/9_ano.jpg", link: "/fundamental/unidades/9" },
	];

	constructor() {}

	ngOnInit(): void {}
}
