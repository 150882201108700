<main class="w-full flex flex-col lg:min-h-screen">
	<div class="flex justify-start">
		<edu-back-button></edu-back-button>
	</div>
	<div class="first-section lg:w-full flex h-120 lg:h-100 2xl:h-120 mb-12 md:mb-0 md:h-100 lg:pl-8">
		<div class="flex flex-col items-start w-full max-w-screen-2xl mx-auto lg:h-3/5 px-0 lg:px-10 xl:mt-10 2xl:mt-auto xl:my-auto">
			<div class="w-full flex flex-col lg:pl-6 border-l-4 border-edu-orange ">
				<div class="w-full 2xl:w-auto mt-10 lg:mt-6 lg:w-110 mb-2 lg:mb-0 mx-8 px-4 lg:pl-4 ml-3 lg:ml-0">
					<h1 class="section-title w-full leading-tight break-words md:w-96 md:text-3xl lg:break-normal xl:text-5xl xl:w-110 2xl:w-110 lg:text-4xl lg:w-98">ESCOLA DE EDUCAÇÃO PARENTAL E EDUCADORES</h1>
					<p class="title w-full mt-2 mb-2 lg:mb-1 lg:w-100 lg:text-2xl md:text-xl md:w-97 xl:text-2xl 2xl:text-xl 2xl:w-98"> Desenvolvimento profissional para uma gestão escolar humanizada.</p>
				</div>
				<p class="body mb-7 w-full px-6 mt-1 text-sm pb-2 md:w-96 md:pb-0 md:text-sm lg:w-100 xl:w-110 xl:text-lg 2xl:w-98">Conteúdo da Escola de Educação Parental e de Educadores da Educa exclusivamente desenvolvido para apoiar professores no desenvolvimento das competências do século 21 e ajudar gestores escolares a cuidarem da saúde mental de sua equipe.</p>
				<a href="https://educa21.com.br/blog/17">
					<edu-button colorClass="edu-btn-orange">Saiba mais</edu-button>
				</a>
			</div>
		</div>
	</div>

	<div class="press-section w-full max-w-13xl mx-auto grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5 px-2 sm:px-20 pb-32 educa-waves">
		<div class="shadow-xl bg-edu-white min-h-16 max-h-full flex justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1" *ngFor="let item of items">
			<div class="px-2 pt-5">
				<div class="border-l-2 border-edu-orange mb-4 h-full relative">
					<div class="justify-center h-full inline-block">
						<p class="title pl-5 font-bold overflow-ellipsis leading-none w-11/12">{{ item.title }}</p>
						<p class="text-sm pl-5 overflow-ellipsis leading-none w-11/12 pt-5" *ngIf="item.subtitle">{{ item.subtitle }}</p>
					</div>
					<div id="spacer" class="w-48 h-6 mr-0"></div>
					<div class="flex pl-5 items-center justify-start absolute bottom-0">
						<a [href]="item.link" target="_blank" *ngIf="item.otherTab">
							<edu-button colorClass="edu-btn-orange">{{ item.button }}</edu-button>
						</a>
						<a [href]="item.link" *ngIf="!item.otherTab">
							<edu-button colorClass="edu-btn-orange">{{ item.button }}</edu-button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
