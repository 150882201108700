import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { genderOptions as genders } from "../../../entities/user";
import { brazilStates } from "../../../_common/staticData";
import { AddressInformation, CepService } from "../../services/cep/cep.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { Gender, ProfessionalRole, User, Occupation, Avatar } from "src/app/entities/user.entity";
import { AuthService, validatePassword } from "src/app/services/auth/auth.service";

@Component({
	selector: "edu-sign-up-form",
	templateUrl: "./sign-up-form.component.html",
	styleUrls: ["./sign-up-form.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class SignUpFormComponent implements OnInit {
	@ViewChild("accountCreatedModal", { static: true }) accountCreatedModalReference: any;
	avatarFilename: string = undefined;

	name = "";
	cpf = "";
	birthDate = "";
	chosenGender: Gender;
	genderOptions = [...genders];
	occupation: Occupation;
	occupationOptions = Occupation;

	email = "";
	cellphone = "";
	zipCode = "";
	address = "";
	addressNumber = "";
	addressComplement = "";
	district = "";
	city = "";
	chosenState = "";
	stateOptions = [...brazilStates];

	professionalRole: ProfessionalRole;
	professionalRoleOptions = ProfessionalRole;

	company = "";

	password = "";
	passwordConfirmation = "";
	passwordError = null;
	passwordConfirmationError = null;

	termsAccepted = false;

	uploadError: string = "";
	avatars;

	constructor(private cepService: CepService, private modalService: NgbModal, private router: Router, private authService: AuthService) {
		this.avatars = this.convertEnumToObject();
	}

	ngOnInit(): void {}

	openAccountCreatedModal() {
		const accountCreatedModalOpenedInstance = this.modalService.open(this.accountCreatedModalReference || "", { centered: true, size: "sm", windowClass: "account-created-modal" });

		accountCreatedModalOpenedInstance?.dismissed.subscribe(() => this.handleGoToMainPage());
	}

	handleGenderChosen(chosenOption: { label: string; value: Gender }) {
		this.chosenGender = chosenOption?.value;
	}

	handleStateChosen(chosenOption: { label: string; value: string }) {
		this.chosenState = chosenOption?.value;
	}

	convertEnumToObject(): Object[] {
		return Object.keys(Avatar)
								.map(key => ({ value: key, image: Avatar[key] }))
	}

	async handleCEPBlur() {
		if (!this.zipCode) return;

		try {
			const addressInformation: AddressInformation = await this.cepService.getAddressInformationFromCep(this.zipCode);

			if (addressInformation) {
				this.address = addressInformation.logradouro;
				this.district = addressInformation.bairro;
				this.city = addressInformation.localidade;
				this.chosenState = this.stateOptions.find(stateOption => stateOption.value === addressInformation.uf)?.value;
			}
		} catch (error) {
			console.error("REQUEST_ERROR:: Error fetching address information from CEP:", error);
		}
	}

	isFormValid(): boolean {
		return !!this.name && !!this.cpf && !!this.birthDate && !!this.chosenGender && !!this.email && !!this.cellphone && !!this.occupation && !!this.password && !!this.passwordConfirmation && this.password === this.passwordConfirmation && this.termsAccepted;
	}

	handleAvatar(avatar) {
		if (avatar) {
			this.avatarFilename = Avatar[avatar.value];
		}
	}

	async handleSubmit(event: Event) {
		event.preventDefault();

		const user: User = {
			name: this.name,
			cpf: this.cpf,
			birthDate: new Date(this.birthDate.split("/").reverse().join("-")),
			gender: this.chosenGender,
			email: this.email,
			phoneNumber: this.cellphone,
			zipCode: this.zipCode,
			addressStreet: this.address,
			addressNumber: Number(this.addressNumber),
			addressComplement: this.addressComplement,
			district: this.district,
			city: this.city,
			state: this.chosenState,
			password: this.password,
			occupation: this.occupation,
			professionalRole: this.professionalRole,
			profilePictureUrl: this.avatarFilename,
			termsAccepted: this.termsAccepted,
		};

		try {
			await this.authService.completeSignup(user);
			this.handleGoToMainPage();
		} catch ({ error }) {
			console.error(error);
		}
	}

	validatePassword(event: Event, kind) {
		if (kind == "password") {
		 	if (this.passwordConfirmation) {
				this.passwordError = validatePassword(this.password, this.passwordConfirmation);
			} else {
				this.passwordError = validatePassword(this.password);
			}
		} else if (kind == "passwordConfirmation") {
			this.passwordConfirmationError = validatePassword(this.passwordConfirmation, this.password);
		}

	}

	handleGoToMainPage(modal?: NgbActiveModal) {
		console.log("modal -> ", modal);
		modal?.dismiss();
		this.router.navigate(["/produtos"]);
	}
}
