import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
	selector: "edu-search-bar",
	templateUrl: "./search-bar.component.html",
	styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
	@Output() search: EventEmitter<string>;

	searchString: string;

	constructor() {
		this.search = new EventEmitter();
		this.searchString = "";
	}

	ngOnInit(): void {}

	handleSearchSubmit(event: Event) {
		event?.preventDefault();
		this.search.emit(this.searchString);
	}
}
