import {Component, Input, OnInit} from '@angular/core';

export interface NotebookCard {
	name: string;
	icon: string;
	link: string;
	soon?: boolean;
}

@Component({
  selector: 'edu-notebook-card',
  templateUrl: './notebook-card.component.html',
  styleUrls: ['./notebook-card.component.scss']
})
export class NotebookCardComponent implements OnInit {
	@Input() stage: NotebookCard;
	@Input() markerColor = "green-marker";

  constructor() { }

  ngOnInit(): void {
  }

}
