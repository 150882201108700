import { Component, OnInit } from '@angular/core';
import { EDUCATORS_ITEMS } from './educators-academy.constants';

@Component({
  selector: 'app-educators-academy',
  templateUrl: './educators-academy.component.html',
  styleUrls: ['./educators-academy.component.scss']
})
export class EducatorsAcademyComponent implements OnInit {
	public items = EDUCATORS_ITEMS;

  constructor() { }

  ngOnInit(): void {
  }

}
