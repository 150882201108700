<ng-template #accountCreatedModal let-modal>
	<div class="flex flex-col items-center mb-10">
		<div class="flex items-center justify-end w-full modal-header">
			<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
				<span aria-hidden="true" class="text-base">&times;</span>
			</button>
		</div>

		<h1 class="section-title">ESTAMOS QUASE LÁ!</h1>

		<p class="body">Enviamos uma mensagem de confirmação de conta para o seu e-mail.</p>
		<br />

		<edu-button (click)="modal.dismiss()">Entendi!</edu-button>
	</div>
</ng-template>

<form class="flex flex-col items-center px-10 py-10 shadow-xl sm:px-20 bg-edu-white" (submit)="handleSubmit($event)">
	<h2 class="mb-16 section-title">CRIAR CONTA</h2>

	<div class="grid grid-cols-2 gap-7">
		<!-- <p class="w-full col-span-2 text-left title">Avatar</p> -->

		<!-- <edu-upload-photo class="col-span-2" (onUpload)="avatarFilename = $event"></edu-upload-photo> -->

		<p class="w-full col-span-2 text-left title">Informações pessoais</p>

		<div class="col-span-2 mb-5 w-full">
			<p class="w-full max-w-2xl col-span-2 mb-1 text-left body text-edu-black">Escolha um avatar:</p>
			<edu-image-dropdown [options]='avatars' (selected)="handleAvatar($event)"></edu-image-dropdown>
		</div>

		<edu-input class="col-span-2" label="Nome completo" placeholder="Digite seu nome" [(value)]="name"></edu-input>

		<edu-input class="col-span-2" label="CPF" placeholder="Digite seu CPF" mask="cpf" [(value)]="cpf"></edu-input>

		<edu-input class="col-span-2" label="Data de nascimento" placeholder="Exemplo: 01/01/2000" mask="date" [(value)]="birthDate"></edu-input>

		<edu-select class="col-span-2" label="Gênero" placeholder="Escolha uma das opções" (change)="handleGenderChosen($event)" [options]="genderOptions" [value]="chosenGender"></edu-select>

		<div class="flex flex-col col-span-2">
			<p class="w-full max-w-2xl col-span-2 mb-1 text-left body text-edu-black">Eu sou:</p>

			<edu-radio class="m-2 ml-0" [radioValue]="occupationOptions.SCHOOL_LEADER" [(value)]="occupation" label="Líder escolar" name="person-category" [checked]="occupation === occupationOptions.SCHOOL_LEADER"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="occupationOptions.TEACHER" [(value)]="occupation" label="Educador(a)" name="person-category" [checked]="occupation === occupationOptions.TEACHER"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="occupationOptions.PARENT" [(value)]="occupation" label="Pai/mãe ou responsável" name="person-category" [checked]="occupation === occupationOptions.PARENT"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="occupationOptions.STUDENT" [(value)]="occupation" label="Aluno(a)" name="person-category" [checked]="occupation === occupationOptions.STUDENT"></edu-radio>
		</div>

		<br />

		<p class="w-full col-span-2 text-left title" *ngIf="occupation  == occupationOptions.SCHOOL_LEADER || occupation == occupationOptions.TEACHER">Informações profissionais</p>

		<div class="flex flex-col col-span-2" *ngIf="occupation  == occupationOptions.SCHOOL_LEADER">
			<p class="w-full max-w-2xl col-span-2 mb-1 text-left body text-edu-black">Ocupação atual:</p>

			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.MAINTAINER" [(value)]="professionalRole" label="Mantenedor(a)" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.MAINTAINER"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.BOARD" [(value)]="professionalRole" label="Diretoria" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.BOARD"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.COORDINATION" [(value)]="professionalRole" label="Coordenação" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.COORDINATION"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.MANAGEMENT" [(value)]="professionalRole" label="Administração" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.MANAGEMENT"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.OTHER" [(value)]="professionalRole" label="Outros" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.OTHER"></edu-radio>
		</div>

		<div class="flex flex-col col-span-2" *ngIf="occupation == occupationOptions.TEACHER">
			<p class="w-full max-w-2xl col-span-2 mb-1 text-left body text-edu-black">Ocupação atual:</p>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.SOCIO_EMOTIONAL_TEACHER" [(value)]="professionalRole" label="Educador(a) Socioemocional" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.SOCIO_EMOTIONAL_TEACHER"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.ELEMENTARY_TEACHER" [(value)]="professionalRole" label="Educador(a) das demais disciplinas" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.ELEMENTARY_TEACHER"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.PSYCHOLOGIST" [(value)]="professionalRole" label="Psicólogo(a)" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.PSYCHOLOGIST"></edu-radio>
			<edu-radio class="m-2 ml-0" [radioValue]="professionalRoleOptions.OTHER" [(value)]="professionalRole" label="Outros" name="professionalRole" [checked]="professionalRole === professionalRoleOptions.OTHER"></edu-radio>
		</div>

		<br>

		<p class="w-full col-span-2 text-left title">Informações de contato</p>

		<edu-input class="col-span-2" label="E-mail" placeholder="Digite seu melhor e-mail" type="email" [(value)]="email"></edu-input>

		<edu-input class="col-span-2" label="Celular" placeholder="Digite seu celular" mask="phone" [(value)]="cellphone"></edu-input>

		<edu-input class="col-span-2" label="CEP" placeholder="Digite seu CEP" mask="cep" [(value)]="zipCode" (input)="handleCEPBlur()"></edu-input>

		<edu-input class="col-span-2" label="Endereço" placeholder="Digite seu endereço" [(value)]="address"></edu-input>

		<div class="flex items-center justify-between col-span-2">
			<edu-input class="w-1/4 mr-5" label="Número" placeholder="Número do endereço" [(value)]="addressNumber"></edu-input>
			<edu-input class="w-3/4" label="Complemento" placeholder="Digite o complemento" [(value)]="addressComplement"></edu-input>
		</div>

		<edu-input class="col-span-2" label="Bairro" placeholder="Digite seu bairro" [(value)]="district"></edu-input>

		<edu-input class="col-span-1" label="Cidade" placeholder="Sua cidade" [(value)]="city"></edu-input>
		<edu-select class="col-span-1" label="Estado" placeholder="Selecionar" (change)="handleStateChosen($event)" [options]="stateOptions" [value]="chosenState"></edu-select>

		<br />

		<p class="w-full col-span-2 text-left title">Senha</p>

		<edu-input class="col-span-2" label="Criar senha" placeholder="Digite sua senha desejada" type="password" [(value)]="password" (change)="validatePassword($event, 'password')" [(error)]="passwordError"></edu-input>

		<edu-input class="col-span-2" label="Repetir senha" placeholder="Digite novamente a senha escolhida acima" type="password" [(value)]="passwordConfirmation" (change)="validatePassword($event, 'passwordConfirmation')" [(error)]="passwordConfirmationError"></edu-input>

		<!-- todo pegar link para termos -->
		<label class="flex items-center col-span-2">
			<div class="w-max">
				<edu-checkbox [(value)]="termsAccepted"></edu-checkbox>
			</div>
			<span class="w-full ml-2.5 body">Ao criar sua conta, você concorda com nossos <a target="_blank" href="https://educa21-media.elabs.xyz/Termo%20de%20Uso%20-%20Educa%2021.pdf" class="text-edu-blue" rel="noopener noreferrer">Termos de Uso</a> e <a target="_blank" href="https://educa21-media.elabs.xyz/Pol%C3%ADtica%20de%20Privacidade%20-%20Educa%2021.pdf" class="text-edu-blue" rel="noopener noreferrer">Política de Privacidade</a>.</span>
		</label>

		<br />

		<div class="flex items-center justify-center col-span-2">
			<edu-button [disabled]="!isFormValid()">Finalizar cadastro</edu-button>
		</div>
	</div>
</form>
