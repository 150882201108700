import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'edu-pop-up-card',
  templateUrl: './pop-up-card.component.html',
  styleUrls: ['./pop-up-card.component.scss']
})
export class PopUpCardComponent implements OnInit {
	@Input() withCloseButton: boolean;
	@Input() visible: boolean;

	@Output() closed: EventEmitter<any>

  constructor() {
  	this.withCloseButton = true;
  	this.visible = false;
  	this.closed = new EventEmitter<any>()
	}

  ngOnInit(): void {
  }

}
