import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as DOMPurify from "dompurify";

@Pipe({
	name: "safeHtml",
	pure: true,
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(htmlContent: string): unknown {
		return this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(htmlContent, { ALLOWED_ATTR: ["style","src","href"], ADD_TAGS: ['script', 'iframe']}));
	}
}
