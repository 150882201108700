<div class="w-full">
  <label class="flex flex-col items-start w-full">
    <span class="text-edu-black font-dinosaur mb-2">{{ label }}</span>
    <div
			class="flex items-center w-full border-0 border-b focus:border-edu-primary-green relative"
			[class.border-edu-primary-green]="!!value && !!!errorMessage"
			[class.border-edu-red]="!!errorMessage"
			[class.border-edu-black]="!!!value && !!!errorMessage"
		>
      <input
        [id]="id"
        [name]="name"
        [type]="type"
        [placeholder]="placeholder"
        class="placeholder-gray-300 border-0 p-0 pb-0.5 bg-transparent outline-none focus:outline-none focus:border-0 w-full md:w-9/12 lg:w-10/12 font-dinosaur text-base h-9"
        [value]="value"
        (input)="updateValue($event.target.value)"
				(blur)="getErrors()"
        *ngIf="mask"
        [mask]="getMask()"
      />
      <input
				[id]="id"
        [name]="name"
        [type]="type"
        [placeholder]="placeholder"
        class="placeholder-gray-300 border-0 p-0 pb-0.5 bg-transparent focus:outline-none focus:border-0 w-full md:w-9/12 lg:w-10/12 font-dinosaur h-9"
        [value]="value"
        (input)="updateValue($event.target.value)"
				(blur)="getErrors()"
				*ngIf="!mask"
      />
			<img *ngIf="type === 'password' || showingPassword" [src]="showingPassword ? './assets/icons/hide-password.svg' : './assets/icons/show-password.svg'" class="absolute right-0 cursor-pointer" (click)="togglePasswordVisibility()" />
    </div>
    <span *ngIf="errorMessage" class="text-edu-red font-dinosaur text-sm text-right w-full mt-1 min-w-min inline-block">{{ errorMessage }}</span>
  </label>
</div>
