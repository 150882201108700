<main class="w-full flex flex-col min-h-screen items-center">
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex flex-wrap md:flex-nowrap justify-start md:justify-between md:mt-5 w-full ml-auto px-5 md:px-2">
			<h2 class="section-title w-100 text-4xl ml-10 md:w-110 lg:ml-20 leading-tight">SEJA BEM-VINDO(A), {{ getOccupation() }} </h2>
		</div>
	</div>

	<div class="press-section w-full max-w-13xl mx-auto grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5 px-2 sm:px-20 pb-32 educa-waves">
		<ng-container *ngFor="let item of items">
			<div class="shadow-xl bg-edu-white h-64 flex justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1" *ngIf="checkPermission(item.feature)">
				<div class="px-5 pt-5 w-full">
					<div class="border-l-2 mb-4 h-full relative" [class]="item.border">
						<div class="justify-start h-full inline-block">
							<img alt="Icone" [src]="item.image" class="ml-1 w-24 h-16 mb-2" *ngIf="item.image" />
							<p class="title pl-5 font-bold overflow-ellipsis leading-none w-11/12">{{ item.title }}</p>
							<p class="title pl-5 font-bold overflow-ellipsis leading-none w-11/12">{{ item.nextLine }}</p>
						</div>
						<div class="flex pl-5 items-center justify-start absolute bottom-0">
							<a [href]="item.link">
								<edu-button [(colorClass)]="item.buttonClass">{{ item.button }}</edu-button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</main>
