import { Component, OnInit } from "@angular/core";

export interface PDF {
	alt: string;
	icon: string;
	link: string;
}

@Component({
	selector: "app-end-elementary-plan",
	templateUrl: "./end-elementary-plan.component.html",
	styleUrls: ["./end-elementary-plan.component.scss"],
})
export class EndElementaryPlanComponent implements OnInit {
	public pdfs: PDF[] = [
		{ alt: "6º Ano", icon: "../../assets/images/livros/6_ano/6_ano.jpg", link: "https://drive.google.com/file/d/1Eyqa7ewPZhioF9vv1WzF8xBPAXfr1NAM/view?usp=sharing" },
		{ alt: "7º Ano", icon: "../../assets/images/livros/7_ano/7_ano.jpg", link: "https://drive.google.com/file/d/1n8X5dwOeEN-4_Uc7xAQ3e6IYStQCRl8g/view?usp=sharing" },
		{ alt: "8º Ano", icon: "../../assets/images/livros/8_ano/8_ano.jpg", link: "https://drive.google.com/file/d/13wioIxtFxM6YO3rkjCuza566wxU2I1Tk/view?usp=sharing" },
		{ alt: "9º Ano", icon: "../../assets/images/livros/9_ano/9_ano.jpg", link: "https://drive.google.com/file/d/1zYZWJ0SNnkCD8zX5Wa43-O9MjYYQ1YiV/view?usp=sharing" },
	];

	constructor() {}

	ngOnInit(): void {}
}
