import { Injectable } from "@angular/core";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { Lecture } from "src/app/entities/lecture.entity";
import { ApiService, PaginationResponse } from "../api.service";

@Injectable({
	providedIn: "root",
})
export class UnitService {
	BASE_ROUTE = "app/unit";

	constructor(private apiService: ApiService) {}

	async getUnit(query?: string): Promise<PaginationResponse<any>> {
		return this.apiService.get<PaginationResponse<any>>(`${this.BASE_ROUTE}?${query || ""}`);
	}

	async getUnitById(id: number): Promise<any> {
		if (!id) return;

		const qb = RequestQueryBuilder.create();
		const query = qb.setJoin({ field: "educationLevel" }).resetCache().query();

		return this.apiService.get<any>(`${this.BASE_ROUTE}${id}?${query}`);
	}
}
