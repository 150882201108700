import { Component, Input, OnInit } from "@angular/core";
import { BlogPost } from "../../../entities/blogPost.entity";

const AUTHOR_IMAGE_URL = "assets/icons/person.svg";

@Component({
	selector: "edu-post-author",
	templateUrl: "./post-author.component.html",
	styleUrls: ["./post-author.component.scss"],
})
export class PostAuthorComponent implements OnInit {
	@Input() post: BlogPost;

	constructor() {}

	ngOnInit(): void {}

	get authorThumbnail(): string {
		return this.post?.authorPicUrl || AUTHOR_IMAGE_URL;
	}
}
