<edu-helmet title="Fale conosco"></edu-helmet>

<main class="w-full pt-16">
	<div class="w-full max-w-7xl flex flex-col xl:flex-row items-center xl:items-start justify-between mx-auto">
		<!-- left section -->
		<div class="flex flex-col items-center xl:items-start w-full xl:w-1/2 mb-20 xl:mb-0 pr-8 pl-8 lg:pr-8 lg:pl-16">
			<div class="flex items-center">
				<h2 class="section-title w-3/4 xl:w-full max-w-sm">FALE COM A EDUCA</h2>
			</div>

			<br />

			<p class="body text-left w-full max-w-xl">Valorizamos parcerias fortes com pessoas e instituições que tenham o desejo sincero de transformar a vida das pessoas por meio da educação. Conecte-se conosco. Estamos sempre disponíveis para tirar dúvidas ou compartilhar informações sobre nossas soluções.</p>

			<br />

			<span class="flex items-center title">
				<img alt="Telefone" src="./assets/icons/phone.svg" class="mr-5" />
				(11) 93266-7774
			</span>

			<br />

			<span class="flex items-center title text-sm">
				<img alt="Email" src="./assets/icons/mail.svg" class="mr-5" />
				<a class="email-link" target="_blank" href="mailto:atendimento@educa21.com.br">Clique aqui</a>&nbsp;e envie um e-mail para nós.
			</span>

			<br />

			<div class="flex items-center items-start">
				<a href="https://www.instagram.com/educa.rossandroklinjey" rel="noreferrer" target="_blank"><img class="mr-2.5" alt="Instagram" src="./assets/icons/instagram-sm.svg" /></a>
				<a href="https://www.facebook.com/Educa21-105641994918548" rel="noreferrer" target="_blank"><img class="mr-2.5" alt="Facebook" src="./assets/icons/facebook-sm.svg" /></a>
				<a href="https://www.linkedin.com/company/educa-rossandroklinjey/" rel="noreferrer" target="_blank"><img class="mr-2.5" alt="Linkedin" src="./assets/icons/linkedin-sm.svg" /></a>
				<a rel="noreferrer" target="_blank" href="https://wa.me/5511932667774">
					<img class="mr-2.5" alt="Whatsapp" src="./assets/icons/whatsapp-sm.svg" />
				</a>
				<!--				<a><img class="mr-2.5" alt="Tiktok" src="./assets/icons/tiktok-sm.svg" /></a>-->
				<!--				<a><img class="mr-2.5" alt="Spotify" src="./assets/icons/spotify-sm.svg" /></a>-->
			</div>
		</div>

		<!-- right section -->
		<div class="w-1/2 mb-10 md:mb-0 flex flex-col w-full xl:w-1/2 lg:pr-16 px-8 lg:px-0">
			<edu-marketing-form [withMessageBox]="true"></edu-marketing-form>
		</div>
	</div>
</main>

<!-- Facebook Pixel Code -->
<script>
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = "2.0";
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
	fbq("init", "191581152845445");
	fbq("track", "PageView");
</script>
<noscript><img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=191581152845445&ev=PageView&noscript=1" /></noscript>
<!-- End Facebook Pixel Code -->
<!-- Start of HubSpot Embed Code -->
<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/22601503.js"></script>
<!-- End of HubSpot Embed Code -->
