import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Subscription } from 'rxjs';
import { LectureService } from 'src/app/services/lecture/lecture.service';

@Component({
  selector: 'app-watch-class',
  templateUrl: './watch-class.component.html',
  styleUrls: ['./watch-class.component.scss']
})
export class WatchClassComponent implements OnInit {

	public videoClass;
	public classId;
  constructor(private route: ActivatedRoute, private domSanitizer:DomSanitizer, public lectureService: LectureService) {
		this.subscribe();
		this.getClass();
	}

  ngOnInit(): void {}

	async getClass() {
		this.videoClass = await this.lectureService.getLectureById(this.classId);
	}

	subscribe() {
		return this.route?.params?.subscribe(params => {
			this.classId = params.id;
		});
	}

	getLink() {
		if (!this.videoClass.videoUrl) return;
		return this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoClass.videoUrl);
	}

}
