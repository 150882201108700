import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import apiUrl from "../../../api.config";
import { AuthService } from "./auth/auth.service";
import Swal, { SweetAlertOptions } from "sweetalert2";

const BASE_URL = apiUrl;
export interface PaginationResponse<DataType> {
	data: DataType[];
	count: number;
	page: number;
	pageCount: number;
	total: number;
}

interface CustomErrorHandling {
	code: number | string;
	alertConfig: SweetAlertOptions;
}

interface APIRequestOptions {
	customErrorHandling?: CustomErrorHandling[];
	tokenRequired?: boolean;
}

export interface APIHeaders {
	[header: string]: string;
}

@Injectable({
	providedIn: "root",
})
export class ApiService {
	constructor(private http: HttpClient) {}

	showDefaultErrorAlert(error: any, customErrorHandling?: CustomErrorHandling[]) {
		const getAlertProps = () => {
			switch (error.statusCode) {
				case 500:
					return {
						titleText: "Falha no servidor",
						text: "Tente novamente ou contate a equipe de desenvolvimento.",
					};
				case 401:
					return {
						titleText: "Não autorizado",
						text: "Faça login para executar essa ação.",
					};
				default:
					return {
						titleText: "Ops!",
						text: "Ocorreu um erro.",
					};
			}
		};

		const customError = customErrorHandling.find(customError => [error.statusCode, error.error].includes(customError.code));

		return Swal.fire({
			titleText: "Erro",
			iconHtml: "<img src='https://educa21-media.elabs.xyz/icon/sad-face.png'>",
			showConfirmButton: true,
			confirmButtonText: "Ok",
			showCloseButton: true,
			...(customError ? customError.alertConfig : getAlertProps()),
		} as SweetAlertOptions);
	}

	getAccessToken() {
		return localStorage.getItem("accessToken");
	}

	get<PromiseReturn>(endpoint: string): Promise<PromiseReturn> {
		if (!endpoint) {
			console.error("DEBUG::ERROR:: missing endpoint");
			return;
		}

		return new Promise((resolve, reject) => {
			this.http.get(`${BASE_URL}/${endpoint}`).subscribe({
				next: (response: PromiseReturn) => resolve(response),
				error: err => reject(err),
			});
		});
	}

	post<DataFormat, ResponseFormat = any>(endpoint: string, data?: DataFormat | Record<string, string | number | boolean | object>, options?: APIRequestOptions): Promise<ResponseFormat> {
		if (!endpoint) {
			console.error("DEBUG::ERROR:: missing endpoint");
			return;
		}

		const headers: APIHeaders = {};

		if (options?.tokenRequired) {
			headers.Authorization = "Bearer " + this.getAccessToken();
		}

		return new Promise((resolve, reject) => {
			this.http.post(`${BASE_URL}/${endpoint}`, data, { headers }).subscribe({
				next: (response: ResponseFormat) => resolve(response),
				error: err => {
					this.showDefaultErrorAlert(err.error, options?.customErrorHandling ?? []);
					return reject(err);
				},
			});
		});
	}

	async uploadAvatar(file: any): Promise<{ filename: string }> {
		const formData: FormData = new FormData();
		formData.append("file", file);

		return new Promise((resolve, reject) => {
			this.http.post(`${BASE_URL}/upload/avatar`, formData).subscribe({
				next: (response: { filename: string }) => resolve(response),
				error: err => reject(err),
			});
		});
	}
}
