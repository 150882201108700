<edu-helmet title="Socioemocional	"></edu-helmet>

<main class="w-full flex flex-col lg:min-h-screen">

	<a
		class="cursor-pointer"
		href="https://somos.educa21.com.br/whatsapp"
		rel="noreferrer"
		target="_blank"
	>
		<img alt="Whatsapp" src="./assets/icons/whatsapp-sm.svg" class="fixed right-0 bottom-0 m-5 h-16" />
	</a>

	<div class="first-section md:w-full flex h-120 md:h-100 lg:h-110 2xl:h-140 mb-12">
		<div class="flex flex-col items-start w-full max-w-screen-2xl mx-auto md:h-3/5 px-0 md:px-10 xl:mt-10 2xl:mt-auto xl:my-auto">
			<div class="w-full flex flex-col lg:pl-10">
				<div class="w-full lg:w-auto border-l-4 border-edu-primary-green mt-10 md:mt-6 mb-2 md:mb-0 mx-8 px-4 md:pl-4 ml-3 md:ml-0">
					<h1 class="section-title w-80 leading-tight break-words md:break-normal">PROGRAMA SOCIOEMOCIONAL EDUCA</h1>

					<p class="marker yellow title w-max mt-2 mb-2 md:mb-7">Por Rossandro Klinjey</p>
				</div>
				<p class="body mb-7 w-80 px-4 mt-1 pb-2 md:pb-0">Educação socioemocional, projeto de vida, letramento digital e educação parental em uma só solução.</p>
			</div>
		</div>
	</div>

	<div class="w-full max-w-14xl mx-auto px-6 md:px-20 flex flex-wrap mt-4 lg:mt-8">

		<div class="flex flex-col items-start w-full lg:w-1/2">
			<p class="title marker yellow mb-1 w-10/12  md:mr-4 leading-none">Mais do que livros de inteligência emocional,</p>
			<p class="title marker yellow mb-1 w-9/10 xl:w-6/12 md:mr-4 leading-none"> uma solução completa</p>
			<p class="title marker yellow mb-1 md:mr-4 leading-none">para sua escola desenvolver</p>
			<p class="title marker yellow mb-1 md:mr-4 leading-none">as competências do século 21.</p>

			<p class="body-sm text-left my-6 md:mr-6">Rossandro Klinjey e o time de educadores da Educa apresentam uma solução inovadora de desenvolvimento da educação socioemocional para o ecossistema escolar, incluindo alunos, pais e responsáveis, educadores e líderes escolares. Os pilares da Educa foram projetados para promover a empatia, autoconsciência, autogestão, consciência social, habilidades de relacionamento e tomada de decisão responsável nos alunos e incluem:
			</p>

			<div class="grid grid-cols-2 md:grid-cols-3 justify-start items-start my-4 lg:my-2 md:mt-5 w-full lg:w-10/12">
				<span class="md:mr-10 mb-1 marker body-sm leading-tight green w-5/12 px-0 md:px-1 md:w-10/12 lg:w-11/12 text-center xl:whitespace-nowrap">Felicidade e Bem-Estar</span>
				<span class="md:mr-10 mb-2 marker body-sm  yellow w-5/12 px-0 md:px-1 md:w-min text-center">Humanidade</span>
				<span class="md:mr-10 mb-2 marker body-sm  purple text-edu-white w-5/12 md:w-min px-0 md:px-1 text-center">Moderação</span>
				<span class="md:mr-10 mb-2 marker body-sm  orange w-5/12 px-0 md:px-1 md:w-min text-center">Sabedoria</span>
				<span class="md:mr-10 mb-2 marker body-sm  red text-edu-white w-5/12 px-0 md:w-min md:px-1 text-center">Liderança</span>
				<span class="md:mr-10 mb-2 marker body-sm  blue text-edu-white w-5/12 px-0 md:w-min md:px-1 text-center">Cidadania</span>
			</div>
		</div>

		<div class="lg:w-1/2 bg-edu-black h-96">
			<img src="./assets/images/kids-blowing.jpg" alt="Crianças assoprando" class="w-full h-full object-cover"/>
		</div>

	</div>

	<div class="w-full max-w-14xl mx-auto px-6 md:px-20 flex flex-col items-center my-10">
		<div class="flex items-start justify-between h-auto">
			<img src="./assets/images/EF1.png" alt="Livros Educa" class="book-image object-fit h-72 my-5 mr-5" />
			<img src="./assets/images/EF2.png" alt="Livros Educa" class="book-image object-fit h-72 my-5" />
		</div>

		<p class="body text-center w-full max-w-4xl my-5">O programa EDUCA por Rossandro Klinjey é orientado para o desenvolvimento das competências socioemocionais em um mundo conectado. Ele se pauta na combinação de estudos e práticas nacionais e internacionais sobre as competências socioemocionais (CASEL, BNCC e Psicologia Positiva) e o letramento digital, preparando crianças e jovens para viver melhor dentro e fora da internet, dimensões educacionais inseparáveis.</p>
	</div>

	<div class=" flex flex-col items-start w-full max-w-14xl mx-auto px-6 md:px-20 my-5 md:my-10">
		<div class="flex flex-wrap items-start justify-between">
			<div class="shadow-lg flex flex-col items-center w-full md:w-3/10 my-5 md:my-0">
				<img alt="Positivo" class="w-36" src="./assets/images/thumbs-up.svg" />
				<div class="w-full flex items-center justify-between marker green-light-25 py-1">
					<p class="text-center text-edu-black mx-auto title leading-tight">CULTURA POSITIVA</p>
				</div>
				<p class="body text-center px-5 pb-5 mt-4">Comprometimento, dedicação, gentileza e ajuda ao próximo fazem parte da cultura positiva
					que a Educa promove com os alunos, educadores e pais através do nosso programa
					socioemocional.</p>
			</div>

			<div class="shadow-lg flex flex-col items-center w-full md:w-3/10 my-5 md:my-0">
				<img alt="Alto-falante" class="w-36" src="./assets/images/loudspeaker.svg" />
				<div class="w-full flex items-center justify-between marker orange-light-25 py-1">
					<p class="text-center text-edu-black mx-auto title leading-tight">O ALUNO POSSUI SUA PRÓPRIA VOZ</p>
				</div>
				<p class="body text-center px-5 pb-5 mt-4">O aluno é agente de mudança social, praticando a cidadania, se conectando coletivamente com a sociedade por meio do civismo e dando o seu melhor ao mundo.</p>
			</div>

			<div class="shadow-lg flex flex-col items-center w-full md:w-3/10 my-5 md:my-0">
				<img alt="Sorrisos" class="w-36" src="./assets/images/smiles.svg" />
				<div class="w-full flex items-center justify-between marker yellow-light-25 text-edu-black py-1">
					<p class="text-center text-edu-black mx-auto title leading-tight">LETRAMENTO DIGITAL</p>
				</div>
				<p class="body text-center px-5 pb-5 mt-4">Nosso conteúdo ajuda a formar pessoas capazes de aproveitar o ambiente digital de forma crítica e positiva.</p>
			</div>
		</div>
	</div>

	<div class="flex items-center w-full ml-auto max-w-19xl px-6 md:px-20">
		<h2 class="section-title w-full sm:w-2/3 lg:w-1/3">OS PILARES DA EDUCA</h2>
		<div class="md:w-2/3 flex items-center">
			<div class="w-0 w-full  md:border-t-4 border-edu-primary-green"></div>
		</div>
	</div>

	<div class="flex items-center justify-start w-full max-w-14xl mx-auto px-6 md:px-20">
		<p class="body text-left w-full max-w-lg">A educação socioemocional contribui significativamente para a construção de um mundo mais humanizado.
			A Educa desenvolve nos alunos a capacidade de perceber e gerenciar suas emoções, aplicar o pensamento crítico na resolução de desafios, aprimorar a criatividade, estimular a liderança, ter mais empatia e estabelecer relações positivas com o mundo ao seu redor.</p>
	</div>

	<br />

	<div class="w-full max-w-14xl mx-auto px-6 md:px-20 grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-10">
		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="./assets/images/kids-playing-pointing@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10">
				<p class="title marker green text-left mb-1">Felicidade e</p>
				<p class="title marker green text-left">Bem-Estar</p>
				<p class="body text-left mt-2.5">Por meio do autoconhecimento desenvolvemos a autonomia, a prática do autocuidado e a gratidão. Assim, inspiramos os alunos a encontrar um caminho para a felicidade.</p>
			</div>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="./assets/images/kid-zoom@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10">
				<p class="title marker orange text-left mb-1">Sabedoria</p>
				<p class="body text-left mt-2.5">As ideias criativas se juntam com a curiosidade são capazes de realizações incríveis. Contudo, com o desenvolvimento do pensamento crítico e da responsabilidade, esse potencial é transformador.</p>
			</div>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="./assets/images/grandma-kissing@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10">
				<p class="title marker yellow text-left mb-1">Humanidade</p>
				<p class="body text-left mt-2.5">Valores cada vez mais necessários na sociedade, tais como a generosidade, amor, esperança, compaixão e a prática da empatia nas relações. Através dessas habilidades, construiremos um mundo mais humanizado.</p>
			</div>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="./assets/images/kid-telescope@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10">
				<p class="title marker red text-left text-edu-white mb-1">Liderança</p>
				<p class="body text-left mt-2.5">As competências do século 21 se constituem por meio da colaboração, tomada de decisões, negociação e trabalho em equipe. Desenvolvemos a noção de justiça, a capacidade de resolver problemas complexos e a autoliderança.</p>
			</div>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="./assets/images/pointing-clock@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10">
				<p class="title marker purple text-left text-edu-white mb-1">Moderação</p>
				<p class="body text-left mt-2.5">Desenvolver o equilíbrio permite que práticas como o perdão, a humildade, a prudência e o autocontrole se tornem hábitos. Neste aspecto, a comunicação assertiva, a resiliência emocional e a paciência são ferramentas necessárias para o aprendizado da moderação.</p>
			</div>
		</div>

		<div class="shadow-lg flex flex-col items-start">
			<img alt="Crianças apontando e felizes" src="./assets/images/washing-hands@2x.png" class="w-full mb-4" />
			<div class="flex flex-col items-start px-5 pb-10">
				<p class="title marker blue text-left text-edu-white mb-1">Cidadania</p>
				<p class="body text-left mt-2.5">Somos todos parte da sociedade, logo agentes transformadores do mundo. Para isso, desenvolvemos a consciência e o protagonismo ambiental, a integridade, perseverança e o civismo. Com bravura e vitalidade seremos melhores pessoas para o planeta!</p>
			</div>
		</div>
	</div>

	<div class="w-full flex-col flex flex-wrap md:flex-nowrap items-center justify-center md:justify-around px-6 md:px-10 my-10 md:my-20">
		<p class="body text-center w-full max-w-lg">A Educa reforça o comportamento positivo, promovendo uma cultura de crescimento e tornando a condução na sala de aula mais simplificada, motivando os alunos a fazerem boas escolhas com mais frequência.</p>
		<a class="edu-btn edu-btn-secondary cursor-pointer my-10 md:my-5" [routerLink]="['/fale-conosco/']">Quero ser uma escola parceira!</a>
	</div>

	<div class="w-full max-w-14xl mx-auto px-6 md:px-20 md:mb-5">
		<h2 class="section-title">EDUCA NA PRÁTICA</h2>
	</div>


	<div class="flex flex-wrap items-start justify-between w-full max-w-14xl mx-auto px-6 md:px-20 my-10">

		<div class="shadow-lg flex flex-col items-center mb-5 md:mb-0 w-full md:w-3/10">
			<img alt="Lampada acesa" src="./assets/images/lamp.svg" class="mb-2.5 w-28" />
			<div class="w-3/4 mx-auto flex items-center justify-between py-1">
				<p class="text-center text-edu-black mx-auto title leading-tight marker green">Na sala de aula</p>
			</div>
			<p class="body text-center px-5 pb-5 mt-4">A nossa proposta pedagógica permite aos professores e alunos explorar a criatividade, trabalho em equipe, escuta ativa e empatia. Com as atividades do programa socioemocional, a Educa promove compartilhamento de ideias, criação de projetos, interação e aprendizado entre a turma.</p>
		</div>

		<div class="shadow-lg flex flex-col items-center mb-5 md:mb-0  w-full md:w-3/10">
			<img alt="Coração" src="./assets/images/heart.svg" class="mb-2.5 w-28" />
			<div class="w-3/4 mx-auto flex items-center justify-between py-1">
				<p class="text-center text-edu-black mx-auto title leading-tight marker green">Além da escola</p>
			</div>
			<p class="body text-center px-5 pb-5 mt-4">A participação da família é um importante ponto de apoio no desenvolvimento das competências socioemocionais. A Educa oferece uma excelente oportunidade de conexão entre escola, alunos e famílias.
				Por meio de conteúdos exclusivos de educação parental e uma trilha de material de apoio complementar ao programa socioemocional, a
				família é envolvida na jornada de educação socioemocional do aluno.</p>
		</div>

		<div class="shadow-lg flex flex-col items-center mb-5 md:mb-0  w-full md:w-3/10">
			<img alt="Cadernos" src="./assets/images/notebook.svg" class="mb-2.5 w-28" />
			<div class="w-3/4 mx-auto flex items-center justify-between py-1">
				<p class="text-center text-edu-black mx-auto title leading-tight marker green w-max">Educa e a escola parceira</p>
			</div>
			<p class="body text-center px-5 pb-5 mt-4">O portal Educa fornece recursos para transformar a comunidade escolar, promovendo a educaçãosocioemocional. São
				aulas, atividades, recursos para impressão, quebra-gelos, prompts, vídeos e muito mais.</p>
		</div>

	</div>


	<div class="flex flex-col w-full max-w-14xl mx-auto px-6 md:px-20 mb-16 md:mb-32 socioemotional-habilities-section">
		<h2 class="section-title w-full max-w-sm">COMPETÊNCIAS SOCIOEMOCIONAIS</h2>

		<div class="flex align-start justify-between">

			<!-- quotations -->
			<div class="flex flex-col items-start md:mt-20 md:pr-32 md:w-3/5">

				<div class="flex flex-col justify-end block md:hidden">
					<img src="./assets/images/teacher-teaching@2x.png" alt="Professora ensinando" />
				</div>

				<!-- quotation -->
				<div class="flex flex-col pl-5 md:pl-10 border-l-4 border-edu-primary-green mt-5 md:mt-0 mb-10">
					<p class="title mb-2.5">Você sabe o que é educação socioemocional?</p>
					<p class="body">Educação socioemocional é o processo no qual crianças e adultos aprendem a gerenciar emoções, definir e alcançar objetivos positivos, sentir e mostrar empatia pelos outros, estabelecer relacionamentos saudáveis e tomar decisões responsáveis.</p>
				</div>

				<!-- quotation -->
				<div class="flex flex-col pl-5 md:pl-10 border-l-4 border-edu-primary-green mt-5 md:mt-0 mb-10">
					<p class="title mb-2.5">Muito mais que uma aula</p>
					<p class="body">As aulas de educação socioemocional incorporam o aprendizado sobre emoções e habilidades sociais ao dia a dia da sala de aula. Uma abordagem sistêmica das competências socioemocionais cultiva um ambiente de aprendizagem atencioso, participativo e equitativo. Com aulas baseadas em experiências práticas, a Educa por Rossandro Klinjey envolve ativamente os alunos, impactando o crescimento social, emocional e acadêmico com eficácia.</p>
				</div>

				<!-- quotation -->
				<div class="flex flex-col pl-5 md:pl-10 border-l-4 border-edu-primary-green mt-5 md:mt-0">
					<p class="title mb-2.5">Criando ambientes de aprendizagem</p>
					<p class="body">A escola transforma-se em um laboratório prático da vida, proporcionando interações entre educadores e alunos, que se estimulam mutuamente no aprendizado das competências do século 21, envolvendo a família e incluindo ambientes fora da sala de aula, digitais e presenciais, para o desenvolvimento socioemocional.</p>
				</div>

			</div>

			<div class="flex flex-col justify-end md:justify-center hidden md:block md:w-5/12">
				<img src="./assets/images/teacher-teaching@2x.png" alt="Professora ensinando" />
			</div>

		</div>
	</div>


	<div class="flex items-center w-full ml-auto max-w-19xl px-6 md:px-20">
		<h2 class="section-title w-full sm:w-3/5 md:w-2/5 lg:w-auto">PESQUISA</h2>
		<div class="w-full md:border-t-4 px-6 ml-10 border-edu-primary-green"></div>
	</div>

	<div class="research-section">
		<div class="flex flex-wrap items-start justify-between w-full max-w-14xl mx-auto px-6 md:px-20 my-10 mb-16 md:mb-32">

			<div class="shadow-lg flex flex-col items-center mb-5 md:mb-0 w-full md:w-3/10 py-10">
				<div class="w-3/4 mx-auto flex items-start justify-between flex-col py-1">
					<p class="text-center md:text-left text-edu-black subtitle-sm leading-tight">Aumento do desempenho acadêmico</p>
					<div class="border-b-4 mt-2.5 w-full md:w-1/2 border-edu-primary-yellow"></div>
				</div>
				<p class="body text-center md:text-left px-5 pb-5 mt-4">Uma pesquisa de 2011, realizada pela CASEL com mais de 270.000 alunos, mostrou um ganho de 11 pontos percentuais no resultado acadêmico de alunos que participam de programas socioemocionais.</p>
				<a class="w-4/5 md:w-auto" href="https://neuroconecte.com/competencias-socioemocionais-como-estao-as-suas/" target="_blank" rel="noreferrer">
					<edu-button>Saiba mais</edu-button>
				</a>
			</div>

			<div class="shadow-lg flex flex-col items-center mb-5 md:mb-0 w-full md:w-3/10 py-10">
				<div class="w-3/4 mx-auto flex items-start justify-between flex-col py-1">
					<p class="text-center md:text-left text-edu-black subtitle-sm leading-tight">Os impactos das habilidades socioemocionais no mundo</p>
					<div class="border-b-4 mt-2.5 w-full md:w-1/2 border-edu-primary-yellow"></div>
				</div>
				<p class="body text-center md:text-left px-5 pb-5 mt-4">Por meio de programas socioemocionais, alunos do mundo inteiro estão sendo auxiliados a estimular a criatividade e colaboração, resolver problemas complexos, reduzir a indisciplina, desenvolver a empatia, promover o autocuidado e a saúde mental, combater o bullying e construir o pensamento crítico.</p>
				<a class="w-4/5 md:w-auto" href="https://novaescola.org.br/conteudo/19601/o-valor-das-socioemocionais-para-o-desenvolvimento-pleno" target="_blank" rel="noreferrer">
					<edu-button>Saiba mais</edu-button>
				</a>
			</div>

			<div class="shadow-lg flex flex-col items-center mb-5 md:mb-0 w-full md:w-3/10 py-10">
				<div class="w-3/4 mx-auto flex items-start justify-between flex-col py-1">
					<p class="text-center md:text-left text-edu-black subtitle-sm leading-tight w-full">Projeto piloto da Educa impacta mais de 1.000 alunos no Brasil</p>
					<div class="border-b-4 mt-2.5 w-full md:w-1/2 border-edu-primary-yellow"></div>
				</div>
				<p class="body text-center md:text-left px-5 pb-5 mt-4">A primeira etapa do programa socioemocional Educa por Rossandro Klinjey foi um sucesso! Mais de 1.000 alunos experimentaram suas aulas. A pesquisa feita com Líderes escolares e professores sobre o programa teve como resultado mais de 97% de aprovação.</p>
			</div>
			</div>
		</div>




	<div class="flex items-start justify-between md:justify-start items-center w-full max-w-14xl mx-auto px-6 md:px-20">
		<h2 class="section-title w-full max-w-xl">O QUE FALAM SOBRE A EDUCA</h2>
	</div>

	<div class="w-full max-w-13xl mx-auto my-5 md:my-20 testimonials-section">
		<edu-testimonials-carousel></edu-testimonials-carousel>
	</div>


	<div class="w-full flex justify-center px-6 md:px-10 mb-10 md:mb-24 community-video-section">
		<div class="w-full max-w-4xl flex flex-col items-center">
			<p class="title text-edu-black marker yellow text-center mb-1">Vamos juntos criar uma </p>
			<p class="title text-edu-black marker yellow text-center mb-4">comunidade escolar incrível!</p>

			<!-- <p class="body mb-10 text-center">Assista este vídeo e veja como a Educa junto com líderes escolares, professores e famílias pode ajudar a transformar a escola e seus alunos.</p> -->

			<div class="w-full bg-edu-black h-96">
				<img src="./assets/images/kids-bumping-fists.jpg" alt="Crianças dando os punhhos" class="w-full h-full object-cover"/>
			</div>
		</div>
	</div>

	<!-- descomentar quando video estiver  disponível -->
<!--	<div class="w-full max-w-14xl flex flex-row items-center justify-between mx-auto mb-32 px-6 md:px-10">-->
<!--		<div class="flex flex-col items-start">-->
<!--			<h2 class="section-title w-full max-w-md">VÍDEO PARA ALUNOS E PAIS</h2>-->

<!--			<p class="body text-left w-full max-w-2xl">Apresente este vídeo de 60 segundos para os alunos e pais conhecerem mais sobre o programa socioemocional Educa por Rossandro Klinjey.</p>-->
<!--		</div>-->

<!--		<div class="w-1/2 bg-edu-black h-96">-->
<!--		</div>-->
<!--	</div>-->

	<!-- todo descomentar se for implementar carta da Educa21 -->
<!--	<div class="w-full flex items-center justify-center mb-32 presentation-letter-section">-->
<!--		<div class="shadow-lg flex flex-col items-start p-10 w-full max-w-sm bg-edu-white">-->
<!--			<p class="title mb-2.5 leading-tight">Carta de apresentação aos pais</p>-->
<!--			<p class="body text-left mb-2.5">Envolva as famílias no programa socioemocional Educa por Rossandro Klinjey com esta carta de uma página.</p>-->
<!--			<edu-button variant="secondary" class="mx-auto mt-2.5">Ler carta</edu-button>-->
<!--		</div>-->
<!--	</div>-->

	<div class="about-educa-form">
		<div class="w-full max-w-4xl mx-auto mb-10 md:mb-12 px-6 md:px-0" id="contact-form">
			<div class="mb-10 md:mb-20">
				<p class="title marker yellow text-center w-max mx-auto mb-1">Vamos falar sobre a transformação</p>
				<p class="title marker yellow text-center w-max mx-auto mb-1">que a Educa pode gerar</p>
				<p class="title marker yellow text-center w-max mx-auto mb-1">na sua escola ou família.</p>
			</div>
			<edu-marketing-form></edu-marketing-form>
		</div>
	</div>

</main>

