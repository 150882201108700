import { Component } from "@angular/core";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	options = [
		{ label: "Danilo", id: 1 },
		{ label: "Roberta", id: 2 },
		{ label: "Nina", id: 3 },
		{ label: 'Milly', id: 4 },
	];

	optionSelected: any = 1;

	gender = "male";
	facebook = false;

	scrollTop() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	handleSelectChange(value) {
		this.optionSelected = value.id;
	}
}
