import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-educa-products-home",
	templateUrl: "./educa-products-home.component.html",
	styleUrls: ["./educa-products-home.component.scss"],
})
export class EducaProductsHomeComponent implements OnInit {
	public items = [
		{ title: "MANIFESTO", nextLine: "EDUCA", subtitle: "Nosso Manifesto é uma inspiração para todos que acreditam no poder das competências socioemocionais para a construção de um mundo mais humanizado.", link: "https://youtu.be/AXu7AsHXSC4", button: "Assista", otherTab: true },
		{ title: "NOSSO BLOG", subtitle: "Notícias, descobertas e muito conhecimento compartilhado pelos nossos especialistas.", link: "/blog", button: "Explore o Blog" },
		{ title: "TRILHA EDUCA", subtitle: "A nossa trajetória vai além da sala de aula. Conheça as soluções da Educa para transformar a experiência de aprendizado dos Alunos, Famílias, Educadores e Líderes Escolares.", link: "/trilha-educa", button: "Conheça a Trilha" },
		{ title: "COMUNIDADE", nextLine: "EDUCA", subtitle: "Receba no seu WhatsApp as principais notícias sobre educação socioemocional e parentalidade, além de conteúdos práticos para a criação dos filhos, competências do século 21, educação midiática, letramento digital e muito mais.", link: "https://educa21.com.br/blog/16", button: "Faça Parte" },
		{ title: "CENTRAL DE PRIVACIDADE", nextLine: "EDUCA", subtitle: "Construímos relacionamentos mais humanos e fortes uns com os outros. Esta é a nossa missão e ela exige confiança. Por isso, nossa Central de Privacidade possui todas as informações para você entender a Política Educa de forma simples e prática.", link: "/central-de-privacidade", button: "Visite" },
	];

	constructor() {}

	ngOnInit(): void {}
}
