import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {validateEmail} from "../../../_common/validators";
import {Router} from "@angular/router";
import {RepositoryService} from "../../services/repository.service";
import { AuthService, validatePassword } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/entities/user.entity';

@Component({
  selector: 'edu-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LoginFormComponent implements OnInit {
	@ViewChild('passwordRecoverModal', { static: true }) passwordRecoverModalReference: any;
	@ViewChild('updatePasswordModal', { static: true }) updatePasswordModalReference: any;
	@ViewChild('recoverCodeModal', { static: true }) recoverCodeModalReference: any;

	/**
	 * Temporário, enquanto área logada não estiver totalmente implementada
	 */
	loginAttempted: boolean;

	email: string;
	password: string;

	recoverCode: string;
	recoverCodeError: string;
	recoverCodeSubmissionError: string;
	recoverCodeInserted: boolean;

	recoverEmail: string;
	recoverEmailError: string;
	recoverPasswordSubmissionError: string;
	recoverPasswordRequested: boolean;

	newPassword: string;
	newPasswordConfirmation: string;
	newPasswordError: string;
	newPasswordConfirmationError: string;
	updatePasswordSubmissionError: string;
	newPasswordSent: boolean;

	passwordError: string;
	emailError: string;

  constructor(private modalService: NgbModal, private router: Router, private repository: RepositoryService, private authService: AuthService) {
  	this.loginAttempted = false;

  	this.email = '';
  	this.password = '';

  	this.recoverEmail = '';

  	this.recoverEmailError = '';
  	this.recoverPasswordSubmissionError = '';
  	this.recoverPasswordRequested = false;

		this.recoverCode = '';
		this.recoverCodeError = '';
		this.recoverCodeSubmissionError = '';
		this.recoverCodeInserted = false;

		this.newPassword = '';
		this.newPasswordConfirmation = '';
		this.newPasswordError = '';
		this.newPasswordConfirmationError = '';
		this.updatePasswordSubmissionError = '';
		this.newPasswordSent = false;

		this.passwordError = "";
		this.emailError = "";


	}

  ngOnInit(): void {
		if (this.authService.isUserLoggedIn()){
			this.authService.autoLogin();
			this.router.navigate(["/produtos"]);
		}
	}

	resetState() {
		/**
		 * Dentro de um timeout apenas para
		 * resetar estado do formulario
		 * quando o modal ja tiver fechado
		 */
		setTimeout(() => {
			this.recoverPasswordRequested = false;
			this.recoverEmail = '';
			this.recoverPasswordSubmissionError = '';
			this.recoverEmailError = '';
		}, 100)
	}

	handleRecoverPasswordClick(event: Event) {
  	event.preventDefault();
  	this.openPasswordRecoverModal();
	}

	openPasswordRecoverModal() {
		this.modalService.open(
			this.passwordRecoverModalReference || '',
			{ centered: true, size: 'sm', windowClass: 'recover-password-modal' }
		)
	}

	/**
	 * Todo esse modal provavelmente vai abrir a partir de uma url com algum parametro especifico (codigo de recuperaçção)
	 */
	openUpdatePasswordModal() {
		this.modalService.open(
			this.updatePasswordModalReference || '',
			{ centered: true, size: 'sm', windowClass: 'update-password-modal' }
		)
	}

	async handleSubmit(event: Event) {
  	event.preventDefault();

		const user:User = { email: this.email, password: this.password };
		const session = await this.authService.login(user)
			.catch(err => {
				console.log('Error login -> ', err);
			});
		if (session) {
			this.router.navigate(["/produtos"]);
		}
	}

	navigateToProducts() {
		// this.repository.authenticated.next(true);
		this.router.navigate(['/produtos'])
	}

	async handleCodeFormSubmit(event: Event) {
  	event.preventDefault();
  	if (!this.recoverCode) return;
  	this.recoverCodeInserted = true;
	}

	async handlePasswordRecoverFormSubmit(event: Event) {
  	event.preventDefault();
  	if (!this.validatePasswordRecoverForm()) return;
  	this.recoverPasswordRequested = await this.authService.requestPasswordReset(this.recoverEmail);
		this.modalService.dismissAll();
		this.openUpdatePasswordModal();
	}

	async handleUpdatePasswordFormSubmit(event: Event) {
  	event.preventDefault();

		if (!this.newPassword) return;

		this.newPasswordSent = await this.authService.resetPassword(this.recoverCode, this.newPassword);
	}

	isFormValid(): boolean {
  	return !!this.email && !!this.password && !!validateEmail(this.email);
	}

	validateInput(kind) {
		if (kind == "email") {
			if (!this.email || !validateEmail(this.email)) {
				this.emailError = "E-mail inválido";
			} else {
				this.emailError = null;
			}
		} else if (kind == "password") {
			this.passwordError = validatePassword(this.password);
		}
	}

	validatePassword(kind) {
		if (kind == "password") {
		 	if (this.newPasswordConfirmation) {
				this.newPasswordError = validatePassword(this.newPassword, this.newPasswordConfirmation);
			} else {
				this.newPasswordError = validatePassword(this.newPassword);
			}
		} else if (kind == "passwordConfirmation") {
			this.newPasswordConfirmationError = validatePassword(this.newPasswordConfirmation, this.newPassword);
		}

	}

	handleLoginWithGoogleClick() {
		console.log('Logging in with google...');
		this.navigateToProducts();
	}

	handleLoginWithFacebookClick() {
		console.log('Logging in with facebook...');
		this.navigateToProducts();
	}

	handleLoginWithAppleClick() {
		console.log('Logging in with apple...');
		this.navigateToProducts();
	}

	validatePasswordRecoverForm(): boolean {
  	if (!this.recoverEmail) {
  		this.recoverEmailError = 'Campo obrigatório';
  		return false;
		}

  	if (!validateEmail(this.recoverEmail)) {
  		this.recoverEmailError = 'E-mail inválido';
  		return false;
		}

  	return true;
	}
}
