import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { MessageDto } from "../../../entities/message";

@Injectable({
	providedIn: "root",
})
export class NewsletterService {
	constructor(private apiService: ApiService) {}

	async subscribeToNewsletter(message: MessageDto) {
		return this.apiService.post<MessageDto>("app/newsletter", message);
	}
}
