import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { PermissionService } from "../permission/permission.service";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate {

	constructor(private router: Router, private authService: AuthService, private permissionService: PermissionService ) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		const user = JSON.parse(this.authService.getUserInfo());
		const logged:boolean = this.authService.isUserLoggedIn();

		let permission:boolean = true;

		if (route.data.feature) {
			permission = this.permissionService.checkPermission(user, route.data.feature);

			if (!permission) {
				this.router.navigate(["produtos"]);
			}
		}

		if (!logged) {
			this.router.navigate(["login"]);
		}

		return logged;
	}

}
