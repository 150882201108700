export enum Gender {
	MALE = "MALE",
	FEMALE = "FEMALE",
	OTHER = "OTHER",
}

export const genderOptions = [
	{ value: Gender.MALE, label: 'Masculino' },
	{ value: Gender.FEMALE, label: 'Feminino' },
	{ value: Gender.OTHER, label: 'Outro' },
]
