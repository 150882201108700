<label class="flex flex-col justify-start">
  <div class="flex items-center justify-between mb-2" *ngIf="label">
    <span class="text-base text-edu-black font-dinosaur">{{ label }}</span>
    <span class="text-base text-edu-red font-dinosaur">{{ error }}</span>
  </div>
  <ng-select
    class="edu-custom-select"
    [class.has-error]="!!error && !disabled"
    [items]="options"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [(ngModel)]="value"
    [id]="id"
    [name]="name"
    [placeholder]="placeholder"
    [clearAllText]="clearAllText"
    [typeToSearchText]="searchText"
    [loadingText]="loadingText"
    [notFoundText]="notFoundText"
    (change)="handleChange($event)"
    [disabled]="disabled"
  ></ng-select>
</label>
