import { Component, OnDestroy, OnInit } from "@angular/core";
import { BlogPost } from "../../entities/blogPost.entity";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { BlogCategory } from "../../entities/blogCategory.entity";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { RepositoryService } from "../../services/repository.service";
import { BlogService } from "../../services/blog/blog.service";
import { PaginationResponse } from "../../services/api.service";

@Component({
	selector: "app-blog-index",
	templateUrl: "./blog-index.component.html",
	styleUrls: ["./blog-index.component.scss"],
})
export class BlogIndexComponent implements OnInit, OnDestroy {
	categories: BlogCategory[];
	blogPosts: BlogPost[];

	mostRecentPost: BlogPost;

	activeCategoryId: number;

	activatedRouteSubs: Subscription;
	hasMore: boolean;

	nextPage: number;

	currentSearchParams: string;

	constructor(private activatedRoute: ActivatedRoute, private repository: RepositoryService, private blogService: BlogService) {
		this.currentSearchParams = "";
		this.categories = [];
		this.blogPosts = [];
		this.activatedRouteSubs = new Subscription();
	}

	ngOnInit(): void {
		this.fetchCategories();
		this.activatedRouteSubs = this.subscribeToActivatedRoute();
	}

	ngOnDestroy() {
		this.activatedRouteSubs.unsubscribe();
	}

	subscribeToActivatedRoute() {
		return this.activatedRoute.queryParams.subscribe(async queryParams => {
			const { categoria: categoryName } = queryParams;

			if (!this.categories?.length) {
				await this.fetchCategories();
			}

			this.nextPage = 1;
			this.activeCategoryId = this.categories.find(category => category.name === categoryName)?.id;
			this.fetchBlogPosts();
		});
	}

	handleSearch(params: string) {
		// reset results
		if (!params) {
			this.nextPage = 1;
		}

		this.currentSearchParams = params;

		this.fetchBlogPosts();
	}

	async fetchCategories() {
		const categoriesListRes = await this.blogService.getCategories();
		this.categories = categoriesListRes?.length ? categoriesListRes : [];
	}

	async fetchBlogPosts() {
		if (this.currentSearchParams || this.activeCategoryId) {
			this.nextPage = 1;
		}

		const blogListResponse = await this.blogService.getBlogPosts(this.setBlogPostsQueryParams());

		if (blogListResponse) {
			this.handleBlogPostsLoaded(blogListResponse);
		}
	}

	handleBlogPostsLoaded(blogPostsResponse: PaginationResponse<BlogPost>) {
		this.hasMore = blogPostsResponse?.page < blogPostsResponse?.pageCount;
		this.nextPage = blogPostsResponse?.page + 1;

		const loadedPosts = blogPostsResponse?.data?.length ? blogPostsResponse?.data : [];

		this.repository.blogPosts.next([...this.repository.blogPosts.getValue(), ...loadedPosts]);

		if (blogPostsResponse?.page === 1) {
			this.mostRecentPost = { ...loadedPosts[0] };
		}

		const postsListing = blogPostsResponse?.page === 1 ? [...loadedPosts].splice(1, loadedPosts?.length - 1) : [...loadedPosts];

		if (postsListing?.length && !this.currentSearchParams) {
			this.blogPosts = [...this.blogPosts, ...postsListing];
		} else {
			this.blogPosts = [...postsListing];
		}
	}

	setBlogPostsQueryParams(): string {
		const qb = RequestQueryBuilder.create();
		const queryBuilder = qb
			.setJoin({ field: "category" })
			.sortBy({ field: "createdDate", order: "DESC" })
			.setLimit(10)
			.setPage(this.nextPage || 1)
			.resetCache();

		if (this.activeCategoryId) {
			queryBuilder.setFilter({ field: "category.id", operator: "$eq", value: this.activeCategoryId });
		}

		if (this.currentSearchParams) {
			const searchObject = { title: { $cont: this.currentSearchParams } };

			/**
			 * Aplica filtro de categoria novamente
			 * pois o operador "search" ignora o "setFilter"
			 */
			if (this.activeCategoryId) {
				Object.assign(searchObject, { "category.id": { $eq: this.activeCategoryId } });
			}

			queryBuilder.search(searchObject);
		}

		return queryBuilder?.query() || "";
	}

	handleLoadMorePostsClick() {
		this.fetchBlogPosts();
	}
}
