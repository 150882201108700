<main class="w-full flex flex-col min-h-screen">
	<edu-back-button></edu-back-button>
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex items-center w-full max-w-19xl ml-20">
			<h2 class="section-title max-w-max" id="educa-press">LETRAMENTO DIGITAL</h2>
			<div class="border-t-2 border-edu-primary-yellow w-full max-w-1/2 ml-10"></div>
		</div>
	</div>
	<div class="press-section w-full max-w-13xl mx-auto grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5 px-2 sm:px-20 pb-32">
		<div class="flex flex-col justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1" *ngFor="let pdf of pdfs">
			<div class="flex justify-center md:px-5 md:pt-5 md:justify-start">
				<a [href]="pdf.link" target="blank">
					<img [alt]="pdf.alt" [src]="pdf.icon" class="mr-2.5 shadow-xl md:max-w-96 max-h-60" />
					<edu-button colorClass="edu-btn-yellow">Acessar o {{ pdf.alt | lowercase }}</edu-button>
				</a>
			</div>
		</div>
	</div>
</main>
