import {
	AfterContentInit,
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';

export interface Option {
	label: string;
	value: string | number;
	labelColor?: 'dark' | 'red';
}

@Component({
  selector: 'edu-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
	@Input() options: Option[];
	@Input() staticLabel: boolean;
	@Input() label: string;
	@Input() image:string;

	@Output() selected: EventEmitter<Option>;

	selectedOption: Option | undefined;
	optionsVisible: boolean;

  constructor() {
  	this.selected = new EventEmitter<any>();
  	this.options = [];
  	this.staticLabel = false;
  	this.label = 'Selecionar';
  	this.optionsVisible = false;
		this.image = ' ';
	}

  ngOnInit(): void {
  	console.log('DEBUG:: Options ->', this.options)
  }

	handleDropdownToggle() {
  	this.optionsVisible = !this.optionsVisible;
	}

  handleOptionSelected(selectedOption: Option) {
  	if (!selectedOption) return;

  	this.selected.emit({ ...selectedOption });
  	this.optionsVisible = false;

  	if (!this.staticLabel) {
  		this.selectedOption = { ...selectedOption }
		}
	}

}
