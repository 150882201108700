import { Injectable } from '@angular/core';

export interface AddressInformation {
	cep: string;
	logradouro: string;
	complemento: string;
	bairro: string;
	localidade: string;
	uf: string;
	ibge: string;
	gia: string;
	ddd: string;
	siafi: string;
}

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor() { }

  async getAddressInformationFromCep(cep: string) {
  	if (!cep) return null

		const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
		const jsonResponse: AddressInformation  = await response.json()

		return jsonResponse;
	}
}
