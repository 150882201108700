import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-teachers-panel",
	templateUrl: "./teachers-panel.component.html",
	styleUrls: ["./teachers-panel.component.scss"],
})
export class TeachersPanelComponent implements OnInit {
	public items = [
		{ title: "CONSULTORIA PEDAGÓGICA DIGITAL", image: "../../../assets/icons/new_icons/smartphone-yellow.svg", subtitle: "Consultores pedagógicos disponíveis para apoio digital na palma da sua mão. Converse conosco de forma rápida via WhatsApp, tire dúvidas, agende conversas, obtenha suporte e muito mais.", link: "https://wa.me/5511932667774 ", button: "Fale com a Equipe", otherTab: true, disable: false },
		{ title: "FUNDAMENTAÇÃO PEDAGÓGICA", image: "../../../assets/icons/new_icons/video_player-yellow.svg", subtitle: "Conheça os pilares da Educa e tenha acesso a dicas fundamentais para o seu dia a dia na sala de aula.", link: "/fundamentacao", button: "Assista", disable: false },
		{ title: "CADERNO DO PROFESSOR", image: "../../assets/icons/new_icons/ebook-yellow.svg", link: "/caderno-professor", button: "Acesse", disable: false },
		{ title: "PLANEJAMENTO SEMANAL DE AULAS", image: "../../assets/icons/new_icons/check_list-yellow.svg", link: "/planejamento", button: "Acesse", disable: false },
		{ title: "LETRAMENTO DIGITAL", image: "../../assets/icons/new_icons/hybrid_classes-yellow.svg", link: "/letramento", button: "Acesse", disable: false },
	];

	constructor() {}

	ngOnInit(): void {}
}
