import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { UnitService } from 'src/app/services/unit/unit.service';

export interface PDF {
	alt:string,
	icon: string,
	link: string
}

@Component({
  selector: 'app-elementary-unit',
  templateUrl: './elementary-unit.component.html',
  styleUrls: ['./elementary-unit.component.scss']
})
export class ElementaryUnitComponent implements OnInit {
	public educationUnits;
	public educationLevelId;
	public nextPage;
  constructor(private route: ActivatedRoute, private unitService: UnitService) {
		this.subscribe();
		this.getClass();
	}

  ngOnInit(): void {}

	async getClass() {
		this.educationUnits = await (await this.unitService.getUnit(this.setUnitQueryParams())).data;
		console.log(this.educationUnits);

	}

	setUnitQueryParams() {
		const qb = RequestQueryBuilder.create();

		const queryBuilder = qb
			.sortBy({ field: "id", order: "ASC" })
			.setJoin({ field: "educationLevel" })
			.setLimit(100)
			.setPage(this.nextPage || 1)
			.setFilter({ field: "educationLevel.id", operator: "$eq", value: this.educationLevelId })
			.resetCache();

		return queryBuilder?.query() || "";
	}

	subscribe() {
		return this.route?.params?.subscribe(params => {
			this.educationLevelId = params.id;
		});
	}

}
