<div class="w-full h-screen flex items-center justify-center" *ngIf="!blogPosts?.length">
	<div class="flex flex-col items-center">
		<img src="./assets/icons/logo-primary-green.png" alt="Carregando" class="h-23 w-40"/>
		<span class="title text-edu-primary-green">Aguarde..</span>
	</div>

</div>

<main class="w-full flex flex-col md:px-20" *ngIf="blogPosts?.length">
	<div class="flex items-center justify-start w-full max-w-13xl mx-auto mb-24 px-10 2xl:px-0 mt-10">
		<edu-search-bar class="w-full" (search)="handleSearch($event)"></edu-search-bar>
	</div>

	<div class="flex items-center w-full max-w-13xl mx-auto mb-5 px-10 2xl:px-0 overflow-x-auto hide-scrollbar">
		<a
			[routerLink]="['/blog']"
			class="cursor-pointer mr-5 pb-2.5 border-edu-primary-green focus:outline-none border-b-2 hover:border-edu-primary-green text-edu-black hover:text-edu-black"
			[class.border-edu-white]="activeCategoryId"
			[class.text-edu-dark-grey]="activeCategoryId"
		>
			Todos
		</a>
		<a
			*ngFor="let category of categories"
			[routerLink]="['/blog']"
			[queryParams]="{ categoria: category?.name }"
			class="cursor-pointer mr-5 pb-2.5 border-edu-primary-green focus:outline-none border-b-2 hover:border-edu-primary-green text-edu-dark-grey hover:text-edu-black w-max whitespace-nowrap"
			[class.border-edu-white]="activeCategoryId !== category.id"
			[class.text-edu-dark-grey]="activeCategoryId !== category.id"
		>
			{{ category?.name }}
		</a>
	</div>

	<div class="flex flex-col items-start w-full max-w-13xl mx-auto pb-24 px-10 2xl:px-0" [class.last-publication]="mostRecentPost?.id">
		<h2 class="title text-edu-black mb-6">Última publicação</h2>

		<div class="w-full" *ngIf="mostRecentPost?.id">
			<edu-post-card [highlighted]="true" [post]="mostRecentPost"></edu-post-card>
		</div>

		<div  *ngIf="!mostRecentPost?.id">
			<p class="body w-full text-center">Nenhuma publicação recente</p>
		</div>
	</div>

	<div class="flex flex-col items-start w-full max-w-13xl mx-auto mb-24 px-10 2xl:px-0">
		<h2 class="title text-edu-black mb-6">Mais recentes</h2>

		<div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-7 xl:gap-x-14 gap-y-10" *ngIf="blogPosts?.length">
			<edu-post-card *ngFor="let blogPost of blogPosts" [post]="blogPost"></edu-post-card>
		</div>
		<div *ngIf="!blogPosts?.length">
			<p class="body w-full text-center">Nenhuma publicação para exibir.</p>
		</div>
	</div>

	<div class="flex flex-col items-center w-full max-w-13xl mx-auto mb-24 px-10 2xl:px-0" *ngIf="hasMore">
		<edu-button variant="secondary" class="w-3/5 md:w-auto" (click)="handleLoadMorePostsClick()">Mostrar mais artigos</edu-button>
	</div>


	<div class="w-full mt-6 md:mt-8 mb-8 md:mb-10 flex flex-col items-center mx-auto px-8 md:px-0">
		<h2 class="title text-edu-black mb-2">Acompanhe as novidades da Educa</h2>
		<p class="body text-edu-black mb-2 text-center">Mais conteúdos como esses direto no seu e-mail. Assine nossa news!</p>
		<edu-marketing-form [isNewsletterForm]="true" [withMessageBox]="false" class="mx-auto w-full"></edu-marketing-form>
	</div>
</main>
