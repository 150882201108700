<main class="w-full flex flex-col min-h-screen">
	<edu-back-button></edu-back-button>
	<div class="w-full hero-header flex items-start md:items-center pt-5 md:pt-0">
		<div class="flex flex-wrap md:flex-nowrap justify-start md:justify-between md:mt-5 w-full ml-auto px-5 md:px-2">
			<h2 class="section-title w-100 ml-10 md:w-110 lg:ml-20 leading-tight">COMUNIDADE EDUCA</h2>
		</div>
	</div>

	<div class="press-section w-full max-w-13xl mx-auto grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5 px-2 sm:px-20 pb-32 educa-waves">
		<div class="shadow-xl bg-edu-white min-h-16 max-h-full flex justify-between w-3/4 mx-auto xl:mx-0 xl:w-full py-5 col-span-1" *ngFor="let item of items">
			<div class="px-5 pt-5">
				<div class="border-l-2 border-edu-primary-green mb-4 h-full relative">
					<div class="justify-center h-full inline-block">
						<p class="title pl-5 font-bold overflow-ellipsis leading-none w-11/12">{{ item.title }}</p>
						<p class="title pl-5 font-bold overflow-ellipsis leading-none w-11/12" *ngIf="item.nextLine">{{ item.nextLine }}</p>
						<p class="text-sm pl-5 overflow-ellipsis leading-none w-11/12 pt-5" *ngIf="item.subtitle">{{ item.subtitle }}</p>
					</div>
					<div id="spacer" class="w-48 h-6 mr-0"></div>
					<div class="flex pl-5 items-center justify-start absolute bottom-0">
						<a [href]="item.link" target="_blank" *ngIf="item.otherTab">
							<edu-button>{{ item.button }}</edu-button>
						</a>
						<a [href]="item.link" *ngIf="!item.otherTab">
							<edu-button>{{ item.button }}</edu-button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
