import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'edu-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {
  @Output() onUpload: EventEmitter<string> = new EventEmitter<string>();

	DEFAULT_AVATAR = 'assets/images/portrait_placeholder.png';
	previewSrc?: string | ArrayBuffer;
  errorMessage: string;

  constructor(public apiService: ApiService) { }

  ngOnInit() {}

	handleFileInput(files: FileList) {
		this.errorMessage = ''

		if (files.length === 0) {
      return;
    }

		const photo = files[0];
    const mimeType = photo.type;

    if (mimeType.match(/image\/*/) == null) {
			this.errorMessage = 'Apenas imagens no formato JPEG, JPG ou PNG'
      return;
    }

		this.handleFileUpload(photo);
	}

	async handleFileUpload(photo: File) {
		try {
			const filename = (await this.apiService.uploadAvatar(photo)).filename;
			this.onUpload.emit(filename);

			const reader = new FileReader();

			reader.readAsDataURL(photo);
			reader.onload = () => {
				this.previewSrc = reader.result;
			}
		} catch (error) {
			console.log(error);
			this.errorMessage = "Erro ao realizar upload do arquivo"
		}
	}
}
