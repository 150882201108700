<div class="shadow-xl flex flex-wrap justify-center md:flex-nowrap px-5 md:px-0 pb-10" [class.md:pr-10]="founder.photoPosition === 'left'" [class.md:pl-10]="founder.photoPosition === 'right'">
	<div class="flex flex-col items-end w-100 md:w-2/5" [class.md:order-1]="founder.photoPosition === 'left'" [class.md:order-2]="founder.photoPosition === 'right'">
		<figure class="" [class.mr-auto]="founder.photoPosition === 'left'" [class.ml-auto]="founder.photoPosition === 'right'">
			<img [alt]="founder.name" [src]="founder.imageSrc" />
			<figcaption
				class="title marker green relative w-11/12 -top-10"
				[class.pr-5]="founder.photoPosition === 'left'"
				[class.pl-5]="founder.photoPosition === 'right'"
				[class.text-right]="founder.photoPosition === 'left'"
				[class.text-left]="founder.photoPosition === 'right'"
				[class.-right-xsm]="founder.photoPosition === 'right'"
			>
				{{ founder.name }}
			</figcaption>
		</figure>
		<p class="body flex items-center w-full mx-auto md:px-2.5 -mt-3 sm:-mt-4 md:-mt-7" [class.text-left]="founder.photoPosition === 'left'" [class.text-right]="founder.photoPosition === 'right'">{{ founder.about }}</p>
	</div>

	<div class="flex flex-col mt-5 md:mt-10 h-5/6 w-full" [class.md:order-1]="founder.photoPosition === 'right'" [class.md:order-2]="founder.photoPosition === 'left'" [class.md:ml-5]="founder.photoPosition === 'left'" [class.md:mr-5]="founder.photoPosition === 'right'">
		<p class="body text-left">
			{{ founder.description }}
		</p>

		<div class="flex flex-col">
			<div class="flex items-between mt-10">
				<img alt="Citação" src="./assets/icons/quotation-green.svg" class="mr-2.5 h-10" />
				<p class="title text-left leading-tight">{{ founder.quotation }}</p>
			</div>

			<div *ngIf="founder?.socialMedia" class="flex items-center mt-5">
				<a *ngIf="founder.socialMedia?.instagram" [href]="founder.socialMedia.instagram" rel="noreferrer noopener" target="_blank" class="mr-5"><img src="./assets/icons/instagram-sm.svg" /></a>
				<a *ngIf="founder.socialMedia?.linkedin" [href]="founder.socialMedia.linkedin" rel="noreferrer noopener" target="_blank" class="mr-5 "><img src="./assets/icons/linkedin-sm.svg" /></a>
				<a *ngIf="founder.socialMedia?.youtube" [href]="founder.socialMedia.youtube" rel="noreferrer noopener" target="_blank" class="mr-5 w-13"><img src="./assets/icons/youtube-icon.svg" /></a>
			</div>
		</div>
	</div>
</div>
