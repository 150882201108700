import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'edu-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() type: string = "text";
  @Input() placeholder: string = "text";
  @Input() label: string = "text";
  @Input() error: string = "";
  @Input() maxLength: number | string;

  @Input() value = "";
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  updateValue(value){
    this.value = value;
    this.valueChange.emit(value);
  }
}
