import { Component, OnInit } from '@angular/core';

export interface PDF {
	alt:string,
	icon: string,
	link: string
}

@Component({
  selector: 'app-high-school',
  templateUrl: './high-school.component.html',
  styleUrls: ['./high-school.component.scss']
})
export class HighSchoolComponent implements OnInit {

	public pdfs:PDF[] = [
		{ alt:"1ª Série", icon:"../../assets/images/livros/1_serie/1_serie.png", link:"/medio/unidades/10" },
		{ alt:"2ª Série", icon:"../../assets/images/livros/2_serie/2_serie.png", link:"/medio/unidades/11" },
		{ alt:"3ª Série", icon:"../../assets/images/livros/3_serie/3_serie.png", link:"/medio/unidades/12" },
	]

  constructor() { }

  ngOnInit(): void {
  }

}
