<div class="w-full">
  <label class="flex flex-col items-start w-full">
    <div class="flex items-center w-full mb-2 justify-between">
      <span class="text-edu-black font-dinosaur">{{ label }}</span>
      <span *ngIf="error" class="text-edu-red text-sm min-w-min">{{ error }}</span>
    </div>
    <textarea
      [placeholder]="placeholder"
      class="placeholder-gray-300 py-2.5 px-5 border focus:border-edu-primary-green focus:outline-none focus:border-0 w-full font-dinosaur"
      [value]="value"
      (input)="updateValue($event.target.value)"
      [class.border-edu-primary-green]="!!value && !!!error"
      [class.border-edu-red]="!!error"
			[maxLength]="maxLength"
    ></textarea>
  </label>
</div>
