import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { featurePermission, PermissionService } from "src/app/services/permission/permission.service";

@Component({
	selector: "app-educa-products",
	templateUrl: "./educa-products.component.html",
	styleUrls: ["./educa-products.component.scss"],
})
export class EducaProductsComponent implements OnInit {
	public items = [
		{ title: "Comunidade", nextLine: "Educa", link: "/produtos/home", image: "../../assets/icons/new_icons/lightning-green.svg", button: "Acesse", border: "border-edu-primary-green", feature: featurePermission.home },
		{ title: "Painel do Professor(a)", image: "../../assets/icons/new_icons/lamp-yellow.svg", link: "/painel-professor", button: "Acesse", border: "border-edu-primary-yellow", feature: featurePermission.teachersPanel, buttonClass: "edu-btn-yellow" },
		{ title: "Letramento Digital", image: "../../assets/icons/new_icons/hybrid_classes-yellow.svg", link: "/letramento", button: "Acesse", border: "border-edu-primary-yellow", feature: featurePermission.student, buttonClass: "edu-btn-yellow" },
		{ title: "Academia de Educadores", image: "../../assets/icons/new_icons/brain-orange.svg", link: "/academia-de-educadores", button: "Acesse", border: "border-edu-orange", feature: featurePermission.academy, buttonClass: "edu-btn-orange" },
		{ title: "Escola de Educação Parental", image: "../../assets/icons/new_icons/heart-red.svg", link: "/educacao-parental", button: "Acesse", border: "border-edu-red", feature: featurePermission.parental, buttonClass: "edu-btn-red" },
		{ title: "Manual da Escola Parceira", image: "../../assets/icons/new_icons/book-cover-blue.svg", link: "https://online.flippingbook.com/view/419480829/", button: "Confira", border: "border-edu-blue", feature: featurePermission.manual, buttonClass: "edu-btn-blue" },
		{ title: "Enxoval de Marketing", image: "../../assets/icons/new_icons/thumbs_up-purple.svg", link: "https://drive.google.com/drive/folders/1tO1HeltZcfJWLm8iVfoPDw7xvgN8TfqK", button: "Confira", border: "border-edu-purple", feature: featurePermission.marketing, buttonClass: "edu-btn-purple" },
		{ title: "Minha Escola", image: "../../assets/icons/new_icons/flag-green.svg", link: "/em-breve", button: "Em breve", border: "border-edu-primary-green", feature: featurePermission.mySchool },
		{ title: "Felicidade do Cliente", image: "../../assets/icons/new_icons/social-friend-green.svg", link: "https://wa.me/5511932667774", button: "Fale com a Equipe", border: "border-edu-green", buttonClass: "edu-btn-green", feature: featurePermission.teachersPanel },
	];

	public user = null;
	constructor(private authService: AuthService, private permitissionService: PermissionService) {}

	ngOnInit(): void {}

	checkPermission(feature) {
		const isAuthenticated = this.authService.isUserLoggedIn();

		if (isAuthenticated) {
			this.user = JSON.parse(this.authService.getUserInfo());
			return this.permitissionService.checkPermission(this.user, feature);
		}

		return false;
	}

	getOccupation() {
		return this.authService.getTranslatedUserOccupation().toUpperCase();
	}
}
