import { Component, OnInit } from "@angular/core";
import { FounderCardInput } from "../../components/founder-card/founder-card.component";

@Component({
	selector: "app-press",
	templateUrl: "./press.component.html",
	styleUrls: ["./press.component.scss"],
})
export class PressComponent implements OnInit {
	public staffPersons: { name: string; position: string; profilePicture: string; alt: string; socialMedia: { link: string; icon: string; alt: string } }[] = [];
	constructor() {}

	ngOnInit(): void {}

	/**
	 * Founders
	 */
	rossandro: FounderCardInput = {
		name: "Rossandro Klinjey",
		description: 'Rossandro Klinjey é psicólogo, especialista em educação e desenvolvimento humano, e professor visitante da Fundação Dom Cabral. Fenômeno nas redes sociais, seus vídeos já alcançaram a marca de mais de 150 milhões de visualizações. Autor de vários de livros, sendo os mais recentes, "O Tempo do Autoencontro", "As Cinco Faces do Perdão" e "Help: me eduque!". É consultor da Rede Globo em temas relacionados a comportamento, educação e família, no programa Encontro, além de colunista da Rádio CBN. Como palestrante, viaja pelo Brasil e por países como Estados Unidos, Inglaterra, Áustria, Itália, Alemanha e Portugal. Suas apresentações já foram contratadas por grandes corporações, como Bradesco, Petrobrás, Grupo Algar, UNICAMP e FAAP, assim como por instituições de Estado, como Supremo Tribunal Federal, Ministério da Justiça, Tribunal Superior Eleitoral e Tribunal Superior do Trabalho.',
		imageSrc: "./assets/images/rossandro-suited@2x.png",
		quotation: "O que faz a diferença na nossa vida sempre são as pessoas.",
		about: "Psicólogo clínico, especialista em educação e desenvolvimento humano. Co-fundador da Educa.",
		photoPosition: "left",
		socialMedia: {
			linkedin: "https://www.linkedin.com/in/rossandro-klinjey-04b72029/",
			instagram: "https://www.instagram.com/rossandroklinjey/",
			youtube: "https://www.youtube.com/user/rossandroklinjey",
		},
	};

	jaime: FounderCardInput = {
		name: "Jaime Ribeiro",
		description: "Executivo e palestrante, liderou áreas de vendas, inovação e estratégia de multinacionais como Ambev, Danone e Pearson Education. Jaime Ribeiro, formado em Engenharia Química, com pós-graduação em Gestão de Negócios e em Marketing, é também autor dos livros “Empatia: Por que as Pessoas Empáticas Serão os Líderes do Futuro?” e “Empatia Todo Dia”. Especialista no estudo das mudanças nas relações humanas ocasionadas pelo mundo VUCA (volátil, incerto, complexo e ambíguo) e pelas novas tecnologias que vem impactando profundamente o sucesso e a cultura organizacional das empresas. Realiza workshops e palestras para líderes e empreendedores sobre o protagonismo das habilidades humanas em um mundo dominado pela tecnologia.",
		imageSrc: "./assets/images/jaime@2x.png",
		quotation: "A força das boas atitudes tem o poder de atrair novas ações, criando um ciclo do bem. A cada ação boa, novo ciclo de virtudes se espalha pelo mundo.",
		about: "Executivo da área de educação, escritor e palestrante. Co-fundador da Educa.",
		photoPosition: "right",
		socialMedia: {
			linkedin: "https://www.linkedin.com/in/jaimeribeiro/",
			instagram: "https://www.instagram.com/jaimeribeiro/",
		},
	};
}
