import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Router, RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { InputComponent } from "./components/form/input/input.component";
import { TextareaComponent } from "./components/form/textarea/textarea.component";
import { ButtonComponent } from "./components/form/button/button.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectComponent } from "./components/form/select/select.component";

import { NgxMaskModule, IConfig } from "ngx-mask";
import { RadioComponent } from "./components/form/radio/radio.component";
import { CheckboxComponent } from "./components/form/checkbox/checkbox.component";
import { HeaderComponent } from "./components/layouts/header/header.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HomeComponent } from "./pages/home/home.component";
import { NgbCarouselModule, NgbModalModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { MarketingFormComponent } from "./components/marketing-form/marketing-form.component";
import { EducaTwentyOneComponent } from "./pages/educa-twenty-one/educa-twenty-one.component";
import { FounderCardComponent } from "./components/founder-card/founder-card.component";
import { SocioEmocionalComponent } from "./pages/socio-emocional/socio-emocional.component";
import { TestimonialsCarouselComponent } from "./components/testimonials-carousel/testimonials-carousel.component";
import { TestimonialCardComponent } from "./components/testimonial-card/testimonial-card.component";
import { FaleConoscoComponent } from "./pages/fale-conosco/fale-conosco.component";
import { HttpClientModule } from "@angular/common/http";
import { PressComponent } from "./pages/press/press.component";
import { HelmetComponent } from "./components/helmet/helmet.component";
import { BlogIndexComponent } from "./pages/blog-index/blog-index.component";
import { PostCardComponent } from "./components/blog/post-card/post-card.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { ResourcesComponent } from './pages/recursos-gratuitos/recursos-gratuitos.component';
import { BlogPostComponent } from "./pages/blog-post/blog-post.component";
import { PostAuthorComponent } from "./components/blog/post-author/post-author.component";
import { PopUpCardComponent } from "./components/pop-up-card/pop-up-card.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { PostCardSliderComponent } from "./components/blog/post-card-slider/post-card-slider.component";
import { LoginComponent } from './pages/login/login.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignUpFormComponent } from './components/sign-up-form/sign-up-form.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { SocialLoginButtonComponent } from './components/social-login-button/social-login-button.component';
import { EducaProductsComponent } from './pages/educa-products/educa-products.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NotebookCardComponent } from "./components/products/notebook-card/notebook-card.component";
import { TeachersNotebookComponent } from "./pages/educa-products/teachers-notebook/teachers-notebook.component";
import { ElementaryEarlyComponent } from "./pages/educa-products/teachers-notebook/elementary-early/elementary-early.component";
import { ElementaryEndComponent } from "./pages/educa-products/teachers-notebook/elementary-end/elementary-end.component";
import { ComingSoonComponent } from "./pages/coming-soon/coming-soon.component";
import { BackButtonComponent } from "./components/back-button/back-button.component";
import { UploadPhotoComponent } from "./components/form/upload-photo/upload-photo.component";
import { EducatorsAcademyComponent } from "./pages/educa-products/educators-academy/educators-academy.component";
import { WatchClassComponent } from "./pages/educa-products/watch-class/watch-class.component";
import { ImageDropdownComponent } from "./components/image-dropdown/image-dropdown.component";
import { EducaProductsHomeComponent } from "./pages/educa-products/educa-products-home/educa-products-home.component";
import { TeachersPanelComponent } from "./pages/educa-products/teachers-panel/teachers-panel.component";
import { ClassTrailComponent } from "./pages/educa-products/class-trail/class-trail.component";
import { ParentalEducatorsComponent } from "./pages/educa-products/parental-educators/parental-educators.component";
import { ElementaryUnitComponent } from "./pages/educa-products/teachers-notebook/elementary-unit/elementary-unit.component";
import { EducaTrailComponent } from "./pages/educa-products/educa-trail/educa-trail.component";
import { PrivacyCenterComponent } from "./pages/privacy-center/privacy-center.component";
import { DigitalLiteracyComponent } from "./pages/educa-products/digital-literacy/digital-literacy.component";
import { PedagogicalFoundationComponent } from "./pages/educa-products/pedagogical-foundation/pedagogical-foundation.component";
import { WeeklyPlanningComponent } from "./pages/educa-products/weekly-planning/weekly-planning.component";
import { EarlyElementaryPlanComponent } from "./pages/educa-products/weekly-planning/early-elementary-plan/early-elementary-plan.component";
import { EndElementaryPlanComponent } from "./pages/educa-products/weekly-planning/end-elementary-plan/end-elementary-plan.component";
import { HighSchoolComponent } from "./pages/educa-products/teachers-notebook/high-school/high-school.component";
import { HighSchoolUnitComponent } from "./pages/educa-products/teachers-notebook/high-school-unit/high-school-unit.component";
import { HighSchoolPlanComplement } from "./pages/educa-products/weekly-planning/highschool-plan/highschool-plan.component";
import { PreschoolUnitComponent } from "./pages/educa-products/teachers-notebook/preschool-unit/preschool-unit.component";
import { PreschoolPlanComponent } from "./pages/educa-products/weekly-planning/preschool-plan/preschool-plan.component";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
	declarations: [AppComponent, InputComponent, TextareaComponent, UploadPhotoComponent, ButtonComponent, SelectComponent, RadioComponent, CheckboxComponent, HeaderComponent, FooterComponent, HomeComponent, MarketingFormComponent, EducaTwentyOneComponent, FounderCardComponent, SocioEmocionalComponent, TestimonialsCarouselComponent, TestimonialCardComponent, FaleConoscoComponent, PressComponent, HelmetComponent, BlogIndexComponent, PostCardComponent, SearchBarComponent, BlogPostComponent, PostAuthorComponent, PopUpCardComponent, SafeHtmlPipe, ResourcesComponent, PostCardSliderComponent, LoginComponent, SignUpComponent, SignUpFormComponent, LoginFormComponent, SocialLoginButtonComponent, EducaProductsComponent, DropdownComponent, TeachersNotebookComponent, NotebookCardComponent, ElementaryEarlyComponent, ElementaryEndComponent, ComingSoonComponent, BackButtonComponent, EducatorsAcademyComponent, WatchClassComponent, ImageDropdownComponent, EducaProductsHomeComponent, TeachersPanelComponent, ClassTrailComponent, ParentalEducatorsComponent, PreschoolUnitComponent, ElementaryUnitComponent, EducaTrailComponent, PrivacyCenterComponent, DigitalLiteracyComponent, PedagogicalFoundationComponent, WeeklyPlanningComponent, EarlyElementaryPlanComponent, EndElementaryPlanComponent, HighSchoolComponent, HighSchoolUnitComponent, PreschoolPlanComponent, HighSchoolPlanComplement ],
	imports: [RouterModule.forRoot([]), BrowserModule, HttpClientModule, AppRoutingModule, NgSelectModule, FormsModule, ReactiveFormsModule, NgbModalModule, NgxMaskModule.forRoot(options), NgbCarouselModule, NgbPaginationModule, YouTubePlayerModule],
	providers: [
		Location,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
