<a [href]="stage.link">
	<figure class="mb-5 flex flex-col items-center">
		<div class="flex text-center w-48 h-36" ><img alt="" [src]="stage.icon" class="h-24 m-auto" /></div>
		<a [href]="stage.link" class="marker" [class]="markerColor">
			<figcaption class="secondary-title-sm px-2.5 w-60 text-center">
				{{ stage.name }}
				<span *ngIf="stage.soon"><br>(em breve)</span>
			</figcaption>
		</a>
	</figure>
</a>
