import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbCarousel, NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";
import {TestimonialCardContent} from "../testimonial-card/testimonial-card.component";

@Component({
  selector: 'edu-testimonials-carousel',
  templateUrl: './testimonials-carousel.component.html',
  styleUrls: ['./testimonials-carousel.component.scss']
})
export class TestimonialsCarouselComponent implements OnInit {
	@ViewChild('carouselComponent', { static: true }) carouselElement: NgbCarousel;

	testimonials: TestimonialCardContent[] = [];

  ngOnInit(): void {
  	this.carouselElement?.pause()

		this.testimonials = [
			{
				name: 'Kassula',
				description: 'Diretora do Colégio Saint Georges, Rio de Janeiro/RJ',
				content: 'Inserimos o programa socioemocional em nossa escola e as mudanças foram incríveis! As relações entre professores e alunos se tornaram mais positivas e colaborativas.',
			},
			{
				name: 'Fabiana Araújo',
				description: 'Mãe do aluno Lucas Araújo, do 7º ano, Recife/PE',
				content: 'Com as aulas da Educa, meu filho, Lucas, tem sentido mais abertura para falar conosco, além de ter criado projetos sensacionais sobre liderança com seus amigos durante as aulas.',
			},
			{
				name: 'Sabrina Albuquerque',
				description: 'Aluna do 8º ano do Colégio Evolução, São Paulo/SP',
				content: 'Eu nunca imaginei que poderia aprender sobre amor, paciência e perseverança dentro da sala de aula. E nunca imaginei que eu fosse gostar tanto de tudo isso!',
			},
			{
				name: 'Thais Filho',
				description: 'Professora do 5º ano do Colégio Panorama, Campina Grande/PB',
				content: 'Poder compartilhar com a classe conteúdos que formam pessoas de bem é algo inspirador na minha vida. Cada aula do programa socioemocional é uma nova gota de esperança no mundo.',
			},
		]
  }

}
