import { Component, OnInit } from "@angular/core";

export interface PDF {
	alt: string;
	icon: string;
	link: string;
}

@Component({
	selector: "app-preschool-plan",
	templateUrl: "./preschool-plan.component.html",
	styleUrls: ["./preschool-plan.component.scss"],
})
export class PreschoolPlanComponent implements OnInit {
	public pdfs: PDF[] = [
		{ alt: "Nível 3", icon: "../../assets/images/livros/nivel_3/nivel_3.png", link: "https://drive.google.com/file/d/12ljb43c1Fs8xrRYcC5gfWEo7Oii262s4/view" },
		{ alt: "Nível 4", icon: "../../assets/images/livros/nivel_4/nivel_4.png", link: "https://drive.google.com/file/d/16KUBZ2UiA3RiEDcPN7DCOd2n40Pleayj/view" },
		{ alt: "Nível 5", icon: "../../assets/images/livros/nivel_5/nivel_5.png", link: "https://drive.google.com/file/d/16HafmKYVFfkOtYWF74S1I19iTX-MNxXE/view" },
	];

	constructor() {}

	ngOnInit(): void {}
}
