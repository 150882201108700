<div class="w-full h-screen flex items-center justify-center" *ngIf="!videos">
	<div class="flex flex-col items-center">
		<img src="./assets/icons/logo-primary-green.png" alt="Carregando" class="h-23 w-40"/>
		<span class="title text-edu-primary-green">Aguarde..</span>
	</div>
</div>
<main class="w-full flex flex-col lg:min-h-screen" *ngIf="videos">
	<div class="flex justify-start">
		<edu-back-button></edu-back-button>
	</div>

	<div class="flex items-center w-full ml-auto max-w-19xl px-6 md:px-20 mt-10">
		<h2 class="section-title w-full sm:w-2/3 md:text-4xl md:w-full">{{ title }}</h2>
	</div>

	<br />

	<div class="w-full max-w-14xl mx-auto px-6 md:px-20 lg:px-20 grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-10 mb-20">
		<div class="shadow-xl bg-edu-white flex flex-col justify-between w-11/12 mx-auto lg:w-full xl:mx-0 xl:w-full py-5 col-span-1" *ngFor="let video of videos">
			<div class="px-5 pt-5 grid grid-cols-1 lg:grid-cols-6 xl:grid-cols-6 gap-x-3 gap-y-5">
				<div class="lg:col-start-1 lg:col-end-3 flex-col flex flex-wrap md:flex-nowrap items-center justify-center md:justify-around">
					<div class="object-center h-36 w-36 md:h-20 md:w-20 lg:h-24 lg:w-24 bg-center bg-95 inline-block bg-no-repeat" [ngStyle]="{ 'background-image': 'url(' + video.imageUrl + ')'}" style="border-radius: 100%;"></div>
				</div>
				<div class="border-l-2 mb-4 flex-col flex lg:col-start-3 lg:col-end-7 md:gap-y-4" [class]="borderColor">
					<div class="h-28 justify-center">
						<p class="title pl-5 font-bold overflow-ellipsis leading-none w-11/12">{{ video.title }}</p>
						<p class="body mb-7 px-4 mt-1 pb-2 md:pb-0 md:text-sm w-11/12 md:w-full lg:w-full">{{ video.subtitle }}</p>
					</div>
					<div class="flex pl-5 items-center justify-start mt-5">
						<edu-button [colorClass]="buttonColor" (click)="openClass(video.id)">Assista a aula</edu-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>

