export enum Occupation {
	SCHOOL_LEADER = "SCHOOL_LEADER",
	TEACHER = "TEACHER",
	PARENT = "PARENT",
	STUDENT = "STUDENT",
}

export enum SchoolSector {
	PUBLIC = "PUBLIC",
	PRIVATE = "PRIVATE",
}

export class MessageDto {
	occupation: Occupation;
	name?: string;
	email: string;
	schoolName?: string;
	city?: string;
	state?: string;
	content?: string;
	cellphone?: string;
	schoolSector?: SchoolSector | string
}
