import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { LectureService } from 'src/app/services/lecture/lecture.service';
import { EDUCATORS_ITEMS } from '../educators-academy/educators-academy.constants';
import { PARENTAL_ITEMS } from '../parental-educators/parental-educators.constants';

@Component({
  selector: 'app-class-trail',
  templateUrl: './class-trail.component.html',
  styleUrls: ['./class-trail.component.scss']
})
export class ClassTrailComponent implements OnInit {

	public videos;
	public kind;
	public year;
	public title;
	public nextPage;
	public buttonColor;
	public borderColor;
  constructor(private route: ActivatedRoute, private domSanitizer:DomSanitizer, public lectureService: LectureService, private router: Router) {
		this.subscribe();
		this.loadData();
		this.loadClasses();
		this.chooseColor();
	}

  ngOnInit(): void {
  }

	subscribe() {
		return this.route?.params?.subscribe(params => {
			this.kind = params.kind;
			this.year = params.year;
		});
	}

	async loadClasses() {
		this.videos = await (await this.lectureService.getLecture(this.setLectureQueryParams())).data;

	}

	loadData() {
		let item;
		if (this.kind == "EDUCATORS") {
			item = EDUCATORS_ITEMS.find(item => item.year === this.year);
		} else if (this.kind == "PARENTAL") {
			item = PARENTAL_ITEMS.find(item => item.year === this.year);
		}
		this.title = item?.title || '';
	}

	chooseColor() {
		if (this.kind == "EDUCATORS") {
			this.buttonColor = "edu-btn-orange";
			this.borderColor = "border-edu-orange";
		} else if (this.kind == "PARENTAL") {
			this.buttonColor = "edu-btn-red";
			this.borderColor = "border-edu-red";
		} else {
			this.buttonColor = "edu-btn-green";
			this.borderColor = "border-edu-primary-green";
		}
	}

	openClass(id) {
		return this.router.navigate(["/jornada/assistir-aula", this.year, id]);
	}

	setLectureQueryParams() {
		const qb = RequestQueryBuilder.create();
		const queryBuilder = qb
			.sortBy({ field: "createdDate", order: "DESC" })
			.setLimit(100)
			.setPage(this.nextPage || 1)
			.setFilter({ field: "kind", operator: "$eq", value: this.kind })
			.setFilter({ field: "year", operator: "$eq", value: this.year })
			.resetCache();

		return queryBuilder?.query() || "";
	}

}
