<main class="w-full min-h-screen flex items-center justify-center">
	<div class="w-full flex items-center justify-center h-full">
		<div class="shadow-xl w-full max-w-3xl flex flex-col items-stretch p-10 bg-edu-white">
			<edu-login-form></edu-login-form>
			<br/>
			<p class="title text-center w-full">Não possui conta?</p>
			<div class="flex justify-center items-center mt-5">
				<edu-button routerLink="/criar-conta" class="w-full md:w-auto flex justify-center">Cadastre-se</edu-button>
			</div>
		</div>
	</div>
</main>
