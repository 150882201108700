<div class="w-max overflow-visible h-full">
		<ngb-carousel class="edu-carousel w-72 sm:w-110" #carouselComponent [showNavigationIndicators]="false" interval="5000000">
			<ng-template ngbSlide *ngFor="let options of optionsGroups">
				<div class="w-full grid grid-cols-2 sm:grid-cols-4 pl-14 pr-14">
					<div class="sm:w-full flex justify-center" *ngFor="let option of options">
						<button class="h-16 w-16 sm:h-24 sm:w-24 bg-cover hover-dropdown rounded-full m-2 bg-center bg-no-repeat" [value]="option.value" type="button" (click)="handleOptionSelected(option)" [ngClass]="{'border-4': option.value == selectedOption?.value, 'border-edu-primary-green': option.value == selectedOption?.value }" [style.background-image]="'url(' + option.image + ')'"></button>
					</div>
				</div>
			</ng-template>
		</ngb-carousel>
</div>
