<form class="flex flex-col w-full max-w-4xl items-stretch mx-auto sm:px-4 lg:px-0" (submit)="handleSubmit($event)">

	<div class="grid grid-cols-4 my-6 md:px-32" *ngIf="isNewsletterForm">
		<edu-input [(value)]="email" type="email" label="E-mail" placeholder="Digite o seu melhor e-mail" class="col-span-4 lg:col-span-4 lg:col-start-1" [error]="errors.email"></edu-input>
	</div>

	<div class="flex items-center justify-center flex-col my-2">
		<p class="body text-edu-black text-center w-full max-w-2xl">Eu sou:</p>
	</div>

	<div class="flex items-center justify-center flex-col md:flex-row">
		<edu-radio class="m-2" [radioValue]="occupationOptions.SCHOOL_LEADER" [(value)]="personCategory" label="Líder escolar" name="person-category" [checked]="personCategory === occupationOptions.SCHOOL_LEADER"></edu-radio>
		<edu-radio class="m-2" [radioValue]="occupationOptions.TEACHER" [(value)]="personCategory" label="Educador(a)" name="person-category" [checked]="personCategory === occupationOptions.TEACHER"></edu-radio>
		<edu-radio class="m-2" [radioValue]="occupationOptions.PARENT" [(value)]="personCategory" label="Pai/mãe ou responsável" name="person-category" [checked]="personCategory === occupationOptions.PARENT"></edu-radio>
		<edu-radio class="m-2" [radioValue]="occupationOptions.STUDENT" [(value)]="personCategory" label="Aluno(a)" name="person-category" [checked]="personCategory === occupationOptions.STUDENT"></edu-radio>
	</div>

	<p class="body w-full text-center -mt-2.5 text-edu-red transition-opacity duration-500 ease-in-out" [class.opacity-1]="errors.occupation" [class.opacity-0]="!errors.occupation">{{ errors.occupation }}</p>

	<br />

	<div class="grid grid-cols-2 gap-x-10 gap-y-4 mb-10" *ngIf="!isNewsletterForm">
		<edu-input [(value)]="name" id="nome" name="nome" label="Nome" placeholder="Digite seu nome" class="col-span-2 sm:col-span-1" [error]="errors.name"></edu-input>
		<edu-input [(value)]="surname" id="sobrenome" name="sobrenome" label="Sobrenome" placeholder="Digite seu sobrenome" class="col-span-2 sm:col-span-1" [error]="errors.surname"></edu-input>
		<edu-input [(value)]="email" id="email" name="email" type="email" label="E-mail" placeholder="Digite o seu melhor e-mail" class="col-span-2 sm:col-span-1" [error]="errors.email"></edu-input>
		<edu-input [(value)]="cellphone" id="celular" name="celular" type="phone" label="Celular" placeholder="(XX) XXXXXX-XXXX" class="col-span-2 sm:col-span-1" [error]="errors.cellphone" mask="phone"></edu-input>
		<edu-input [(value)]="school" id="escola" name="escola" label="Nome da escola" placeholder="Digite o nome da sua escola" class="col-span-2 sm:col-span-1" [error]="errors.school"></edu-input>
		<edu-input [(value)]="city" id="cidade" name="cidade" label="Cidade" placeholder="Digite o nome da sua cidade" class="col-span-2 sm:col-span-1" [error]="errors.city"></edu-input>
		<edu-select (change)="handleStateChosen($event)" id="estado" name="estado" label="Estado" placeholder="Selecionar" class="col-span-2 sm:col-span-1" [options]="stateOptions" [value]="chosenState" [error]="errors.state"></edu-select>
		<edu-select *ngIf="!isNewsletterForm" (change)="handleSchoolSectorChosen($event)" label="Setor da escola:" placeholder="Selecionar" class="col-span-2 sm:col-span-1" [options]="schoolSectorOptions" [value]="chosenSchoolSector" [error]="errors.schoolSector"></edu-select>
	</div>

	<div *ngIf="withMessageBox">
		<edu-textarea [(value)]="message" label="Mensagem" placeholder="Digite sua mensagem" [maxLength]="messageMaxLength" [error]="errors.message"></edu-textarea>
	</div>

	<p class="body w-full text-center -mt-2.5 text-edu-red transition-opacity duration-500 ease-in-out" [class.opacity-1]="errors.lead" [class.opacity-0]="!errors.lead">{{ errors.lead }}</p>

	<br />

	<p class="body w-full text-center my-2.5 text-edu-red transition-opacity duration-500 ease-in-out" [class.opacity-1]="submissionErrorMessage" [class.opacity-0]="!submissionErrorMessage">{{ submissionErrorMessage }}</p>

	<div class="flex items-center justify-center md:mb-10 mb-2">
		<edu-button class="w-full md:w-auto" [disabled]="isSubmitting" [loading]="isSubmitting" (click)="handleSubmit()">{{ submitted ? "Enviado!" : "Enviar" }}</edu-button>
	</div>
	<ng-template #successModal let-modal class="w-full">
		<div class="flex flex-col items-center w-auto">
			<div class="modal-header w-full flex items-center justify-end">
				<button type="button" class="close" aria-label="Close" (click)="closeSucessModal()">
					<span aria-hidden="true" class="text-base">&times;</span>
				</button>
			</div>
			<div class="modal-body flex flex-col items-center mb-8">
				<p class="title marker yellow text-edu-black text-center w-full mb-8">Seja bem-vindo(a) à comunidade Educa.</p>
				<p class="subtitle-sm text-edu-black text-center w-full max-w-2xl">Mal podemos esperar para conhecê-lo(a) mais.</p>
				<p class="subtitle-sm text-edu-black text-center w-full max-w-2xl">Você receberá seu primeiro e-mail de boas-vindas em breve.</p>
			</div>
		</div>
	</ng-template>
</form>
