import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'edu-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() label;
  @Input() labelPosition: 'left' | 'right';
  @Input() name;

  @Input() radioValue;
  @Input() checked: boolean;

  @Input() value;
  @Output() valueChange: EventEmitter<string>;

  constructor() {
    this.label = ''
    this.labelPosition = 'right'
    this.name = ''

    this.radioValue = ""

    this.value = "";
    this.valueChange = new EventEmitter<string>();
  }

  ngOnInit(): void {
  }

  handleRadioChange(event) {
    this.valueChange.emit(this.radioValue);
  }

}
